<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    v-model="taskBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <!-- <v-data-table
            :headers="headers"
            :items="taskEpisodeList"
            :search="taskBase.search"
            class="my-table"
            :loading="taskBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Description:</span>
                        {{ item.description }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Url Of Text:</span>
                        <a :href="item.text" target="_blank">
                            <b-icon icon="link45deg"></b-icon>
                        </a>
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Url Of Video:</span>
                        <a :href="item.url" target="_blank">
                            <b-icon icon="link45deg"></b-icon>
                        </a>
                    </div>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="taskBase.dialogDeleteModel"
                    :loading="taskBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.tasks`]="{ item }">
                <v-btn @click="displayItem(item)" text icon color="indigo">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-data-table> -->
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useTaskStore } from "../store/TaskStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },
    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Task",
                    value: "title",
                    align: "center"
                },
                {
                    text: "Duration",
                    value: "duration",
                    align: "center"
                },
                {
                    text: "Task Sync",
                    value: "syn",
                    align: "center"
                },
                {
                    text: "Task Script",
                    value: "script",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center"
                },
                {
                    text: "Tasks",
                    value: "tasks",
                    align: "center",
                    sortable: false
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            expanded: [],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useTaskStore, ['taskBase', 'taskEpisodeList', 'taskModel']),
        ...mapWritableState(useTaskStore, ['show'])
    },

    methods: {
        ...mapActions(useTaskStore, ['fetchTaskEpisode', 'deleteTask', 'setTaskModel']),

        deleteItem(item) {
            this.taskBase.editedIndex = this.taskEpisodeList.indexOf(item)
            this.taskBase.delete = item
            this.taskBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.taskModel.episode_id = parseInt(this.$route.params.episode_id)
            const formData = new FormData()
            formData.append('task_id', this.taskBase.delete.id)
            this.taskBase.loading = true
            this.deleteTask(formData)
        },

        editItem(item) {
            this.taskModel.episode_id = parseInt(this.$route.params.episode_id)
            this.taskBase.ifEdit = true
            this.setTaskModel(item)
            this.show = true
        },
    },

    mounted() {
        this.fetchTaskEpisode(this.$route.params.episode_id)
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.btn-status .v-btn {
    padding: 0px !important;
}
.v-data-table ul {
    list-style: disc !important;
}
.bi-link45deg {
    font-size: 20px;
    margin: 0px 8px;
}
</style>