<template>
    <form-dialog
        :title="!holidayBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="750px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="holiday-form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="holidayModel.title" label="Title" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="holidayModel.department_id"
                            :items="departmentList"
                            label="Department"
                            item_text="name"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <date-input
                            :date.sync="holidayModel.start_date"
                            label="Start Date"
                            :allowedDates="allowedDatesStart"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <date-input
                            :date.sync="holidayModel.end_date"
                            label="End Date"
                            :allowedDates="allowedDatesEnd"
                            :disabled="ifEndDisabled"
                        />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="holidayModel.description" label="Description" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="holidayBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useHolidayStore } from "../store/HolidayStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import { useDepartmentStore } from "@/modules/hr/department/store/DepartmentStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        TextareaInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Holiday",
            titleEdit: "Edit Holiday",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useHolidayStore, ["holidayBase", "holidayModel"]),
        ...mapWritableState(useHolidayStore, ["show"]),
        ...mapState(useDepartmentStore, ['departmentList']),
        ifEndDisabled() {
            return this.holidayModel.start_date == ''
        },
    },
    methods: {
        ...mapActions(useHolidayStore, ['addHoliday', 'editHoliday', 'clearHolidayModel']),
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        allowedDatesStart: val => val > new Date().toJSON().slice(0, 10),
        allowedDatesEnd(val) {
            return val >= this.holidayModel.start_date
        },

        submit() {
            if (this.$refs.form.validate()) {
                this.holidayBase.ifEdit
                    ? this.editHoliday(this.holidayModel.id, this.createFormData())
                    : this.addHoliday(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('title', this.holidayModel.title)
            formData.append('company_id', this.holidayModel.department_id)
            formData.append('start_date', this.holidayModel.start_date)
            formData.append('end_date', this.holidayModel.end_date)
            formData.append('description', this.holidayModel.description)
            return formData
        },
        onCancel() {
            this.show = false
            this.holidayBase.ifEdit = false
            this.clearHolidayModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.fetchAllDepartment()
    }
};
</script>

<style>
.holiday-form .col-12 {
    padding: 0px 15px !important;
}
</style>