<template>
    <div>
        <hr-layout :show.sync="show" title="Client" :hideAdd="true">
            <template v-slot:cards v-if="finishLoad()">
                <client-cards :clientDetailsModel="clientDetailsModel" />
            </template>
            <template v-slot:dataTable>
                <div v-if="finishLoad()">
                    <client-details-table />
                </div>
                <div v-if="inLoad()">
                    <loader />
                </div>
                <div v-if="error()">
                    <no-internet @retry="onRetry" :loading="clientBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
    </div>
</template>

<script>
import { useClientStore } from "../store/ClientStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import ClientDetailsTable from "../components/ClientDetailsTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import Loader from "@/components/main/Loader.vue"
import ClientCards from "../components/ClientCards.vue"
export default {
    components: {
        ClientDetailsTable,
        NoInternet,
        HrLayout,
        Loader,
        ClientCards
    },
    data: () => ({
        cardTitle: "Client List",
        btnTitle: "Add Client",
    }),
    computed: {
        ...mapState(useClientStore, ['clientBase', 'clientDetailsModel']),
        ...mapWritableState(useClientStore, ['show'])
    },
    methods: {
        ...mapActions(useClientStore, ['fetchClientDetails']),
        onRetry() {
            const id = this.$route.params.id
            this.fetchClientDetails(id)
        },
        finishLoad() {
            return (!this.clientBase.loadingFetch && this.clientDetailsModel != null) ? true : false
        },
        inLoad() {
            return (this.clientBase.loadingFetch && this.clientDetailsModel == null) ? true : false
        },
        error() {
            return (this.clientBase.errorFetch && !this.clientBase.loadingFetch) ? true : false
        }
    },
    mounted() {
        const id = this.$route.params.id
        this.fetchClientDetails(id)
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
.client-card-title {
    margin-bottom: 0px;
    font-size: 18px;
    color: #1f1f1f;
    font-weight: normal !important;
}
</style>
