<template>
    <div>
        <hr-layout :show.sync="show" title="Employee Profile" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!employeeBase.errorFetch">
                    <employee-profile />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="employeeBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
    </div>
</template>

<script>
import { useEmployeeStore } from "../store/EmployeeStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import EmployeeProfile from "../components/EmployeeProfile.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import EmployeeForm from "../components/EmployeeForm.vue"

export default {
    components: {
        EmployeeProfile,
        NoInternet,
        EmployeeForm,
        HrLayout,
    },
    data: () => ({
        cardTitle: "Employee List",
        btnTitle: "Add Employee",
    }),
    computed: {
        ...mapState(useEmployeeStore, ['employeeBase']),
        ...mapWritableState(useEmployeeStore, ['show'])
    },
    methods: {
        ...mapActions(useEmployeeStore, ['getEmployeeById']),
        onRetry() {
            this.getEmployeeById(thie.$route.params.id)
        },
    }
};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
.btn:focus{
    box-shadow: 0 0 10px #d1d1d1 !important;
}
.btn-active{
    color: var(--v-primary-base) !important;
    border-color: var(--v-primary-base) !important;
}
</style>
