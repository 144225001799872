import Client from "@/clients/AxiosClient";

export default {
    get() {
        return Client.get("/settings");
    },

    edit(formData) {
        return Client.post(`/update_settings`, formData);
    },

};