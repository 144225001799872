<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    v-model="accountBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" style="padding: 0px 16px">
                <month-input type="date" :date.sync="start_date" label="Start Date"/>
            </v-col>
            <v-col cols="12" sm="4" style="padding: 0px 16px">
                <month-input type="date" :date.sync="end_date" label="End Date" @ok="filterByDate"/>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="accountModel.account.expenses"
            :search="accountBase.search"
            class="my-table"
            :loading="accountBase.loadingFetch"
            :item-class="getMyClass"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="accountBase.dialogDeleteModel"
                    :loading="accountBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px" v-if="item.expense_ref == 'expense'">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.employee.username`]="{ item }">
                <div v-if="item.employee != null && item.created_by != null">
                    <div v-if="item.expense_ref == 'salaryadvanced'">
                        {{item.created_by.username}} To {{item.employee.username}}
                    </div>
                    <div v-else>
                        {{item.employee.username}}
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useAccountStore } from "../store/AccountStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import MonthInput from "@/components/inputs/MonthInput.vue"
export default {
    components: { ConfirmDeleteDialog, MonthInput },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Date",
                    value: "date",
                    align: "center"
                },
                {
                    text: "Amount",
                    value: "amount",
                    align: "center"
                },
                {
                    text: "Description",
                    value: "description",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "expense_ref",
                    align: "center"
                },
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            start_date: '',
            end_date: '',
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useAccountStore, ['accountBase', 'accountModel']),
    },

    methods: {
        ...mapActions(useAccountStore, ['getById', 'deleteExpense']),
        deleteItem(item) {
            this.accountBase.editedIndex = this.accountModel.account.expenses.indexOf(item)
            this.accountBase.delete = item
            this.accountBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('id', this.accountBase.delete.id)
            this.accountBase.loading = true
            this.deleteExpense(formData)
        },

        filterByDate(){
            this.getById(this.$route.params.id, this.start_date, this.end_date)
        },

        getMyClass(item){
            if (item.expense_ref === 'income') return "row-green"
            else return "row-red"
        }
    },
    mounted() {
        // const id = this.$route.params.id
        // this.getById(id)
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.row-green{
    background-color:rgb(240, 243, 255);
}
.row-red{
    background: seashell;
}
</style>