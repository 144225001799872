<template>
    <form-dialog
        title="Add Income"
        :persistent="true"
        :show.sync="incomeFormShow"
        @cancel="onCancel"
        width="600px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="fund-form">
                <v-row>
                    <v-col cols="12">
                        <select-input
                            :model.sync="incomeModel.type"
                            :items="typeList"
                            label="Type"
                            item_text="title"
                            item_value="value"
                        />
                    </v-col>
                    <v-col cols="12" v-if="incomeModel.type == 'clients'">
                        <select-input
                            :model.sync="incomeModel.client"
                            :items="clientList"
                            label="Client"
                            item_text="shortcut"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" v-if="incomeModel.type != 'clients'">
                        <number-input :number.sync="incomeModel.income_amount" label="Amount" />
                    </v-col>
                    <v-col cols="12" v-if="incomeModel.type == 'clients'">
                        <number-input :number.sync="amount" label="Amount" />
                    </v-col>
                    <v-col cols="12" v-if="incomeModel.type == 'clients'">
                        <number-input :number.sync="exchangeRate" label="X" />
                    </v-col>
                    <v-col cols="12" v-if="incomeModel.type == 'clients'">
                        <number-input
                            :number.sync="incomeModel.income_amount"
                            label="Final Amount"
                            :readonly="true"
                        />
                    </v-col>
                    <v-col cols="12">
                        <date-input :date.sync="incomeModel.date" label="Date" />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="incomeModel.description" label="Description" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="incomeBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useFundStore } from "../../store/FundStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"
import { useClientStore } from "@/modules/hr/client_/store/ClientStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        TextareaInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            typeList: [
                {
                    title: "Incomes",
                    value: "incomes"
                },
                {
                    title: "Clients",
                    value: "clients"
                }
            ],
            exchangeRate: '',
            amount: ''
        };
    },
    watch: {
        amount: {
            handler(newAmount) {
                this.incomeModel.income_amount = this.exchangeRate * newAmount;
            },
            immediate: true,
        },
        exchangeRate: {
            handler(newExchangeRate) {
                this.incomeModel.income_amount = newExchangeRate * this.amount;
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useFundStore, ["fundBase", "incomeBase", "incomeModel"]),
        ...mapWritableState(useFundStore, ["incomeFormShow"]),
        ...mapState(useClientStore, ['clientList']),
    },
    methods: {
        ...mapActions(useFundStore, ['addIncome', 'clearIncomeModel']),
        ...mapActions(useClientStore, ['fetchAllClient']),
        submit() {
            if (this.$refs.form.validate()) {
                this.addIncome(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('type', this.incomeModel.type)
            if (this.incomeModel.type == 'clients')
                formData.append('client', this.incomeModel.client)
            formData.append('date', this.incomeModel.date)
            formData.append('income_amount', this.incomeModel.income_amount)
            formData.append('description', this.incomeModel.description)
            return formData
        },
        onCancel() {
            this.incomeFormShow = false
            this.exchangeRate = ''
            this.amount = ''
            this.clearIncomeModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.fetchAllClient()
    }
};
</script>

<style>
.fund-form .col-12 {
    padding: 0px 15px !important;
}
</style>