<template>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner" id="style-15">
            <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul class="sidebar-vertical">
                        <li class="menu-title">
                            <!-- <span>Main</span> -->
                        </li>
                        <!-- <li class="submenu">
                            <a href="javascript:;"><i class="la la-dashboard"></i> <span> Dashboard</span> <span
                                    class="menu-arrow"></span></a>
                            <ul style="display: none;">
                                <li><router-link to="/dashboard">Admin Dashboard</router-link></li>
                            </ul>
                        </li> -->
                        <li class="menu-title">
                            <span>Accounting</span>
                        </li>
                        <li>
                            <router-link to="/accounting/account" v-bind:class="{ 'active': currentPath == 'account'  || currentPath == 'account-details' }"><i
                                    class="fas fa-dollar-sign"></i> <span>Account</span></router-link>
                        </li>
                        <li>
                            <router-link to="/accounting/salaries" v-bind:class="{ 'active': currentPath == 'salaries' }"><i
                                    class="fas fa-hand-holding-usd"></i> <span>Salaries</span></router-link>
                        </li>
                        <li>
                            <router-link to="/accounting/paychecks" v-bind:class="{ 'active': currentPath == 'paychecks' }"><i
                                    class="fas fa-money-bill-wave-alt"></i> <span>Paychecks</span></router-link>
                        </li>
                        <li>
                            <router-link to="/accounting/wallets" v-bind:class="{ 'active': currentPath == 'wallets' }"><i
                                    class="fas fa-money-check"></i> <span>Wallets</span></router-link>
                        </li>
                        <li>
                            <router-link to="/accounting/overtime" v-bind:class="{ 'active': currentPath == 'overtime' }"><i
                                    class="fas fa-clock"></i> <span>Overtime</span></router-link>
                        </li>
                    </ul>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <!-- </sidebar> -->
</template>
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
export default {
    components: {
        PerfectScrollbar,
    },
    mounted() {
        $('#sidebar-menu a').on('click', function (e) {
            if ($(this).parent().hasClass('submenu')) {
                e.preventDefault();
            }
            if (!$(this).hasClass('subdrop')) {
                $('ul', $(this).parents('ul:first')).slideUp(350);
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $(this).next('ul').slideDown(350);
                $(this).addClass('subdrop');
            } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').slideUp(350);
            }
        });
        $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

    },
    computed: {
        currentPath() {
            // console.log(this.$route.name)
            return this.$route.name;
        }
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            activeClass: 'active',
        };
        //  isactive : true
    },
    methods: {
        scrollHanle(evt) {

        }
    },
}

</script>


<style>
.sidebar-vertical .active{
    color: var(--v-accent-base) !important
}</style>