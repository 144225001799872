<template>
    <div>
        <hr-layout :show.sync="show" title="Employee">
            <template v-slot:icons>
                <div class="view-icons">
                    <router-link to="/hr/employees-grid" class="grid-view btn" :class="ifActive('employees-grid') ? 'btn-active' : ''">
                        <i class="fa fa-th"></i>
                    </router-link>
                    <router-link to="/hr/employees" class="list-view btn" :class="ifActive('employees') ? 'btn-active' : ''">
                        <i class="fa fa-bars"></i>
                    </router-link>
                </div>
            </template>
            <template v-slot:dataTable>
                <div v-if="!employeeBase.errorFetch">
                    <employee-grid />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="employeeBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
        <employee-form />
    </div>
</template>

<script>
import { useEmployeeStore } from "../store/EmployeeStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import EmployeeGrid from "../components/EmployeeGrid.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import EmployeeForm from "../components/EmployeeForm.vue"

export default {
    components: {
        EmployeeGrid,
        NoInternet,
        EmployeeForm,
        HrLayout,
    },
    data: () => ({
        cardTitle: "Employee List",
        btnTitle: "Add Employee",
    }),
    computed: {
        ...mapState(useEmployeeStore, ['employeeBase']),
        ...mapWritableState(useEmployeeStore, ['show'])
    },
    methods: {
        ...mapActions(useEmployeeStore, ['fetchAllEmployee']),
        onRetry() {
            this.fetchAllEmployee()
        },
        ifActive(btnName){
            return btnName == this.$route.name ? true : false
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
.btn:focus{
    box-shadow: 0 0 10px #d1d1d1 !important;
}
.btn-active{
    color: var(--v-primary-base) !important;
    border-color: var(--v-primary-base) !important;
}
</style>
