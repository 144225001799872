import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/project_create", formData);
    },

    getAll(date) {
        if (date)
            return Client.get(`/projects?date=${date}`);
        else
            return Client.get(`/projects`);
    },

    getById(id) {
        return Client.get(`/project?project_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/project_edit`, formData);
    },

    delete(formData) {
        return Client.post(`/project_delete`, formData);
    },

};