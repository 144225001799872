<template>
    <form-dialog
        title="Add Task"
        :persistent="true"
        :show.sync="showAdd"
        @cancel="onCancel"
        width="1000px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="task-form">
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-select
                            outlined
                            v-model="taskModel.episode"
                            :reverse="getDirection"
                            :items="episodeFiltered"
                            item-text="title"
                            label="Episode Name"
                            :rules="[requiredRule]"
                            :persistent-hint="taskModel.episode.title != ''"
                            :hint="`Episode duration ${taskModel.episode.duration}`"
                            return-object
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <date-input
                            :date.sync="taskModel.end_date"
                            label="Finish date"
                            :allowedDates="allowedDates"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <time-input :time.sync="taskModel.time" label="Time" format="ampm" />
                    </v-col>

                    <!-- Translation -->
                    <v-col cols="12" style="margin-bottom: 30px">
                        <v-btn
                            block
                            color="error"
                            style="width: -webkit-fill-available;cursor: auto;"
                        >
                            <span
                                v-if="taskModel.episode.title != ''"
                            >(Episode Duration {{taskModel.episode.duration}})</span> Translation
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-select
                            outlined
                            v-model="taskModel.translation"
                            :reverse="getDirection"
                            :items="tranlationEmployeeList"
                            item-text="username"
                            item-value="id"
                            label="Assigned Employees"
                            :rules="[requiredRule]"
                            :multiple="true"
                            :disabled="taskModel.episode.title == ''"
                            @change="pushToTranslationEmployee"
                        ></v-select>
                    </v-col>
                    <v-row
                        v-for="(item, index) in taskModel.translation"
                        :key="'translation-' + index"
                        style="margin: 3px 0px;"
                    >
                        <v-col cols="12" sm="4">
                            <select-input
                                :model="taskModel.translation_employees[index].employee_id"
                                :items="tranlationEmployeeList"
                                label="Employee"
                                item_text="username"
                                item_value="id"
                                :ifMultiple="true"
                                :readonly="true"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                outlined
                                :rules="[requiredRule]"
                                v-model="taskModel.translation_employees[index].from"
                                :reverse="getDirection"
                                :items="fromItemsTranslation(index)"
                                label="Minutes From"
                                :disabled="index != 0 && (taskModel.translation_employees[index-1].from == null || taskModel.translation_employees[index-1].to == null)"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                outlined
                                :rules="[requiredRule]"
                                v-model="taskModel.translation_employees[index].to"
                                :reverse="getDirection"
                                :items="toItemsTranslation(index)"
                                label="Minutes To"
                                :disabled="(taskModel.translation_employees[index].from == null)"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- Synchronization -->
                    <v-col cols="12" style="margin-bottom: 30px">
                        <v-btn
                            block
                            color="error"
                            style="width: -webkit-fill-available;cursor: auto;"
                        >
                            <span
                                v-if="taskModel.episode.title != ''"
                            >(Episode Duration {{taskModel.episode.duration}})</span> Synchronization
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-select
                            outlined
                            v-model="taskModel.sync"
                            :reverse="getDirection"
                            :items="syncEmployeeList"
                            item-text="username"
                            item-value="id"
                            label="Assigned Employees"
                            :multiple="true"
                            :disabled="taskModel.episode.title == ''"
                            @change="pushToSyncEmployee"
                        ></v-select>
                    </v-col>
                    <v-row
                        v-for="(item, index) in taskModel.sync"
                        :key="'sync-' + index"
                        style="margin: 3px 0px;"
                    >
                        <v-col cols="12" sm="4">
                            <select-input
                                :model="taskModel.sync_employees[index].employee_id"
                                :items="syncEmployeeList"
                                label="Employee"
                                item_text="username"
                                item_value="id"
                                :ifMultiple="true"
                                :readonly="true"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                outlined
                                :rules="[requiredRule]"
                                v-model="taskModel.sync_employees[index].from"
                                :reverse="getDirection"
                                :items="fromItemsSync(index)"
                                label="Minutes From"
                                :disabled="index != 0 && (taskModel.sync_employees[index-1].from == null || taskModel.sync_employees[index-1].to == null)"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                outlined
                                :rules="[requiredRule]"
                                v-model="taskModel.sync_employees[index].to"
                                :reverse="getDirection"
                                :items="toItemsSync(index)"
                                label="Minutes To"
                                :disabled="(taskModel.sync_employees[index].from == null)"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- Audit -->
                    <v-col cols="12" style="margin-bottom: 30px">
                        <v-btn
                            block
                            color="error"
                            style="width: -webkit-fill-available;cursor: auto;"
                        >
                            <span
                                v-if="taskModel.episode.title != ''"
                            >(Episode Duration {{taskModel.episode.duration}})</span> Audit
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-select
                            outlined
                            v-model="taskModel.audit"
                            :reverse="getDirection"
                            :items="auditEmployeeList"
                            item-text="username"
                            item-value="id"
                            label="Assigned Employees"
                            :multiple="true"
                            :disabled="taskModel.episode.title == ''"
                            @change="pushToAuditEmployee"
                        ></v-select>
                    </v-col>
                    <v-row
                        v-for="(item, index) in taskModel.audit"
                        :key="'audit-' + index"
                        style="margin: 3px 0px;"
                    >
                        <v-col cols="12" sm="4">
                            <select-input
                                :model="taskModel.audit_employees[index].employee_id"
                                :items="auditEmployeeList"
                                label="Employee"
                                item_text="username"
                                item_value="id"
                                :ifMultiple="true"
                                :readonly="true"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                outlined
                                :rules="[requiredRule]"
                                v-model="taskModel.audit_employees[index].from"
                                :reverse="getDirection"
                                :items="fromItemsAudit(index)"
                                label="Minutes From"
                                :disabled="index != 0 && (taskModel.audit_employees[index-1].from == null || taskModel.sync_employees[index-1].to == null)"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                outlined
                                :rules="[requiredRule]"
                                v-model="taskModel.audit_employees[index].to"
                                :reverse="getDirection"
                                :items="toItemsAudit(index)"
                                label="Minutes To"
                                :disabled="(taskModel.audit_employees[index].from == null)"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- Poly -->
                    <v-col cols="12" style="margin-bottom: 30px">
                        <v-btn
                            block
                            color="error"
                            style="width: -webkit-fill-available;cursor: auto;"
                        >Poly</v-btn>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-select
                            outlined
                            v-model="taskModel.poly"
                            :reverse="getDirection"
                            :items="poliEmployeeList"
                            item-text="username"
                            item-value="id"
                            label="Assigned Employees"
                            :multiple="true"
                            :disabled="taskModel.episode.title == ''"
                            @change="pushToPolyEmployee"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="taskModel.description" label="Notes" :ifReq="false"/>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="taskBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useTaskStore } from "../store/TaskStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import { useEpisodeStore } from "@/modules/translation/episode/store/EpisodeStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
import NumberInput from "@/components/inputs/NumberInput.vue"
import { useEmployeeStore } from '@/modules/hr/employee/store/EmployeeStore'
import DateInput from "@/components/inputs/DateInput.vue"
import TimeInput from "@/components/inputs/TimeInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        TextareaInput,
        NumberInput,
        DateInput,
        TimeInput
    },
    props: {
        projectDetails: {
            required: true
        }
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Task",
            titleEdit: "Edit Task",
            episode_id: '',
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useTaskStore, ["taskBase", "taskModel", 'selectList']),
        ...mapWritableState(useTaskStore, ["showAdd"]),
        ...mapState(useEpisodeStore, ['episodeList']),
        ...mapState(useEmployeeStore, ['tranlationEmployeeList', 'syncEmployeeList', 'auditEmployeeList', 'poliEmployeeList']),
        episodeFiltered() {
            return this.projectDetails.episodes.filter((episode) =>
                !this.projectDetails.tasks.some((task) => task.title === episode.title)
            );
        },
    },
    methods: {
        ...mapActions(useTaskStore, ['addTask', 'editTask', 'clearTaskModel']),
        ...mapActions(useEpisodeStore, ['fetchAllEpisode']),
        ...mapActions(useEmployeeStore, ['fetchTranlationEmployee', 'fetchSyncEmployee', 'fetchAuditEmployee', 'fetchPoliEmployee']),
        submit() {
            if (this.$refs.form.validate()) {
                this.taskBase.ifEdit
                    ? this.editTask(this.taskModel.id, this.createParams())
                    : this.addTask(this.createParams())
            }
        },
        createParams() {
            this.taskModel.project_id = parseInt(this.$route.params.project_id)
            const params = {
                title: this.taskModel.episode.title,
                end_date: this.taskModel.end_date,
                time: this.taskModel.time, 
                project_id: this.taskModel.project_id,
                translation_employees: this.taskModel.translation_employees,
                sync_employees: this.taskModel.sync_employees,
                audit_employees: this.taskModel.audit_employees,
                poly_employees: this.taskModel.poly_employees,
                description: this.taskModel.description
            };
            return params
        },
        onCancel() {
            this.showAdd = false
            this.taskBase.ifEdit = false
            this.clearTaskModel()
            this.$refs.form.resetValidation()
        },
        allowedDates(val) {
            return val >= new Date().toJSON().slice(0, 10) && val <= this.projectDetails.end_date.slice(0, 10)
        },
        // translation
        fromItemsTranslation(index) {
            const fromItemsTranslation = [];
            if (index == 0) {
                fromItemsTranslation.push(0);
                this.taskModel.translation_employees[0].from = 0
            }
            else {
                if (this.taskModel.translation_employees[index - 1].to != this.taskModel.episode.duration){
                    fromItemsTranslation.push(this.taskModel.translation_employees[index - 1].to)
                    this.taskModel.translation_employees[index].from = this.taskModel.translation_employees[index - 1].to
                }
            }
            return fromItemsTranslation;
        },
        toItemsTranslation(index) {
            const toItemsTranslation = [];
            for (let i = this.taskModel.translation_employees[index].from + 1; i <= this.taskModel.episode.duration; i++) {
                toItemsTranslation.push(i);
            }
            return toItemsTranslation;
        },
        pushToTranslationEmployee() {
            const selectedEmployees = this.taskModel.translation;
            this.taskModel.translation_employees = this.taskModel.translation_employees.filter(emp => selectedEmployees.includes(emp.employee_id));
            selectedEmployees.forEach(employeeId => {
                const existingEmployee = this.taskModel.translation_employees.find(emp => emp.employee_id === employeeId);
                if (!existingEmployee) {
                    this.taskModel.translation_employees.push({
                        employee_id: employeeId,
                        from: null,
                        to: null
                    });
                }
            });
        },
        // sync
        fromItemsSync(index) {
            const fromItemsSync = [];
            if (index == 0) {
                fromItemsSync.push(0);
                this.taskModel.sync_employees[0].from = 0
            }
            else {
                if (this.taskModel.sync_employees[index - 1].to != this.taskModel.episode.duration){
                    fromItemsSync.push(this.taskModel.sync_employees[index - 1].to)
                    this.taskModel.sync_employees[index].from = this.taskModel.sync_employees[index - 1].to
                }
            }
            return fromItemsSync;
        },
        toItemsSync(index) {
            const toItemsSync = [];
            for (let i = this.taskModel.sync_employees[index].from + 1; i <= this.taskModel.episode.duration; i++) {
                toItemsSync.push(i);
            }
            return toItemsSync;
        },
        pushToSyncEmployee() {
            const selectedEmployees = this.taskModel.sync;
            this.taskModel.sync_employees = this.taskModel.sync_employees.filter(emp => selectedEmployees.includes(emp.employee_id));
            selectedEmployees.forEach(employeeId => {
                const existingEmployee = this.taskModel.sync_employees.find(emp => emp.employee_id === employeeId);
                if (!existingEmployee) {
                    this.taskModel.sync_employees.push({
                        employee_id: employeeId,
                        from: null,
                        to: null
                    });
                }
            });
        },
        // audit
        fromItemsAudit(index) {
            const fromItemsAudit = [];
            if (index == 0) {
                fromItemsAudit.push(0);
                this.taskModel.audit_employees[0].from = 0
            }
            else {
                if (this.taskModel.audit_employees[index - 1].to != this.taskModel.episode.duration){
                    fromItemsAudit.push(this.taskModel.audit_employees[index - 1].to)
                    this.taskModel.audit_employees[index].from = this.taskModel.audit_employees[index - 1].to
                }
            }
            return fromItemsAudit;
        },
        toItemsAudit(index) {
            const toItemsAudit = [];
            for (let i = this.taskModel.audit_employees[index].from + 1; i <= this.taskModel.episode.duration; i++) {
                toItemsAudit.push(i);
            }
            return toItemsAudit;
        },
        pushToAuditEmployee() {
            const selectedEmployees = this.taskModel.audit;
            this.taskModel.audit_employees = this.taskModel.audit_employees.filter(emp => selectedEmployees.includes(emp.employee_id));
            selectedEmployees.forEach(employeeId => {
                const existingEmployee = this.taskModel.audit_employees.find(emp => emp.employee_id === employeeId);
                if (!existingEmployee) {
                    this.taskModel.audit_employees.push({
                        employee_id: employeeId,
                        from: null,
                        to: null
                    });
                }
            });
        },
        // poly
        pushToPolyEmployee() {
            const selectedEmployees = this.taskModel.poly;
            this.taskModel.poly_employees = this.taskModel.poly_employees.filter(emp => selectedEmployees.includes(emp.employee_id));
            selectedEmployees.forEach(employeeId => {
                const existingEmployee = this.taskModel.poly_employees.find(emp => emp.employee_id === employeeId);
                if (!existingEmployee) {
                    this.taskModel.poly_employees.push({
                        employee_id: employeeId,
                    });
                }
            });
        },
    },
    mounted() {
        this.fetchTranlationEmployee()
        this.fetchSyncEmployee()
        this.fetchAuditEmployee()
        this.fetchPoliEmployee()
        this.taskModel.project_id = parseInt(this.$route.params.project_id)
    }
};
</script>

<style>
.task-form .col-12 {
    padding: 0px 15px !important;
}
</style>