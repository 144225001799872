import Client from "@/modules/hr/client_/pages/ClientListView.vue"
import ClientDetails from "@/modules/hr/client_/pages/ClientDetails.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue";
export default {
    path: '/hr',
    component: BaseFormPageWrapper,
    children:[
        {
            path: "/hr/clients",
            name: "clients",
            component: Client,
            meta: { title: "Client", permission: "client_view" }
        },
        {
            path: "/hr/clients/:id",
            name: "clients-details",
            component: ClientDetails,
            meta: { title: "Client", permission: "client_view" }
        },
        
    ]
    
};
