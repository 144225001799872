<template>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner" id="style-15">
            <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul class="sidebar-vertical">
                        <li class="menu-title">
                            <span>Unicorn</span>
                        </li>
                        <li v-role="'employee'">
                            <router-link to="/employee-dashboard" v-bind:class="{ 'active': currentPath == 'employee-dashboard' }"><i
                                    class="fa fa-dashboard"></i> <span>Dashboard</span></router-link>
                        </li>
                        <li v-role="'employee'">
                            <router-link to="/employee-task" v-bind:class="{ 'active': currentPath == 'employee-task' || currentPath == 'employee-task-details'}"><i
                                    class="fas fa-tasks"></i> <span>Tasks</span></router-link>
                        </li>
                        <li v-role="'manager'">
                            <router-link to="/manager-dashboard" v-bind:class="{ 'active': currentPath == 'manager-dashboard' }"><i
                                    class="fa fa-dashboard"></i> <span>Dashboard</span></router-link>
                        </li>
                        <li v-can="'company_view'">
                            <router-link to="/hr/departments" v-bind:class="{ 'active': currentPath == 'departments' }"><i
                                    class="fas fa-sitemap"></i> <span>Departments</span></router-link>
                        </li>
                        <li v-can="'designation_view'">
                            <router-link to="/hr/designations" v-bind:class="{ 'active': currentPath == 'designations' }"><i
                                    class="fas fa-table"></i> <span>Designations</span></router-link>
                        </li>
                        <li v-can="'employee_view'">
                            <router-link to="/hr/employees" v-bind:class="{ 'active': currentPath == 'employees'  || currentPath == 'employees-grid' || currentPath == 'employees-profile'}"><i
                                    class="fas fa-user-tie"></i> <span>Employees</span></router-link>
                        </li>
                        <li v-can="'attendance_view'">
                            <router-link to="/hr/attendance" v-bind:class="{ 'active': currentPath == 'attendance'}"><i
                                    class="fas fa-calendar-check"></i> <span>Attendance</span></router-link>
                        </li>
                        <li v-can="'attendance_view'">
                            <router-link to="/hr/monthly-attendance" v-bind:class="{ 'active': currentPath == 'monthly-attendance'}"><i
                                    class="fas fa-calendar-check"></i> <span>Monthly Attendance</span></router-link>
                        </li>
                        <li v-can="'leave_view'">
                            <router-link to="/hr/leaves" v-bind:class="{ 'active': currentPath == 'leaves' }"><i
                                    class="far fa-calendar-times"></i> <span>Request Leave</span></router-link>
                        </li>
                        <li v-can="'holiday_view'">
                            <router-link to="/hr/holidays" v-bind:class="{ 'active': currentPath == 'holidays' }"><i
                                    class="fas fa-umbrella-beach"></i> <span>Holidays</span></router-link>
                        </li>
                        <li v-can="'client_view'">
                            <router-link to="/hr/clients" v-bind:class="{ 'active': currentPath == 'clients'  || currentPath == 'clients-details'}"><i
                                    class="fas fa-users"></i> <span>Clients</span></router-link>
                        </li>
                        <li v-can="'fund'">
                            <router-link to="/hr/fund" v-bind:class="{ 'active': currentPath == 'fund' }"><i
                                    class="fas fa-money-bill"></i> <span>Fund</span></router-link>
                        </li>
                        <li v-can="'project_view'">
                            <router-link to="/translation/projects" v-bind:class="{ 'active': currentPath == 'projects' || currentPath == 'project-details' || currentPath == 'episodes-project' || currentPath == 'episodes' || currentPath == 'task-details' || currentPath == 'tasks-episode' || currentPath == 'tasks' }"><i
                                    class="fas fa-cubes"></i> <span>Projects</span></router-link>
                        </li>
                        <li v-can="'account_view'">
                            <router-link to="/accounting/account" v-bind:class="{ 'active': currentPath == 'account' || currentPath == 'account-details'}"><i
                                    class="fas fa-dollar-sign"></i> <span>Account</span></router-link>
                        </li>
                        <li v-can="'salary_view'">
                            <router-link to="/accounting/salaries" v-bind:class="{ 'active': currentPath == 'salaries' }"><i
                                    class="fas fa-hand-holding-usd"></i> <span>Salaries</span></router-link>
                        </li>
                        <li v-can="'paychecks_view'">
                            <router-link to="/accounting/paychecks" v-bind:class="{ 'active': currentPath == 'paychecks' }"><i
                                    class="fas fa-money-bill-wave-alt"></i> <span>Paychecks</span></router-link>
                        </li>
                        <li v-can="'wallets'">
                            <router-link to="/accounting/wallets" v-bind:class="{ 'active': currentPath == 'wallets' }"><i
                                    class="fas fa-money-check"></i> <span>Wallets</span></router-link>
                        </li>
                        <li v-can="'overtime'">
                            <router-link to="/accounting/overtime" v-bind:class="{ 'active': currentPath == 'overtime' }"><i
                                    class="fas fa-clock"></i> <span>Overtime</span></router-link>
                        </li>
                    </ul>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <!-- </sidebar> -->
</template>
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
export default {
    components: {
        PerfectScrollbar,
    },
    mounted() {
        $('#sidebar-menu a').on('click', function (e) {
            if ($(this).parent().hasClass('submenu')) {
                e.preventDefault();
            }
            if (!$(this).hasClass('subdrop')) {
                $('ul', $(this).parents('ul:first')).slideUp(350);
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $(this).next('ul').slideDown(350);
                $(this).addClass('subdrop');
            } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').slideUp(350);
            }
        });
        $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

    },
    computed: {
        currentPath() {
            return this.$route.name;
        }
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            activeClass: 'active',
        };
        //  isactive : true
    },
    methods: {
        scrollHanle(evt) {

        }
    },
}

</script>
<style>
.sidebar{
    overflow-y: auto;
}
.sidebar svg{
    font-size: 22px;
}
</style>

