import Client from "@/clients/AxiosClient";

export default {
    get() {
        return Client.get("/employee_dashboard");
    },

    checkOut(formData) {
        return Client.post("/check_out", formData);
    }

};