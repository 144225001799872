import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import EmployeeService from "../services/EmployeeService";

export const useEmployeeStore = defineStore("employeeStore", {
    state: () => ({
        employeeBase: new BaseState(),
        employeeModel: {
            id: '',
            firstname: '',
            lastname: '',
            gender: '',
            phone: '',
            department_id: '',
            manager_id: '',
            salary: 0,
            email: '',
            password: '',
            password_confirmation: '',
            joining_date: '',
            role_users_id: '',
            salary_amount: 0,
            salary_amount_trans: 0,
            with_script: 0,
            salary_amount_syn: 0,
            salary_amount_audit: 0,
            salary_amount_poly: 0,
            des_id: [],
            file: null,
            icon: null,
            over_price: 0,
            birth_date: '',
        },
        employeeDetails: null,
        employeeList: [],
        tranlationEmployeeList: [],
        syncEmployeeList: [],
        auditEmployeeList: [],
        poliEmployeeList: [],
        genderList: ["female", "male"],
        salaryTypeList: ['monthly', 'minutes'],
        managerList: [],
        show: false,
        checkIfWithScript: false,
    }),
    getters: {
    },
    actions: {
        clearEmployeeModel() {
            this.employeeModel.id = ''
            this.employeeModel.firstname = ''
            this.employeeModel.lastname = ''
            this.employeeModel.gender = ''
            this.employeeModel.phone = ''
            this.employeeModel.department_id = ''
            this.employeeModel.manager_id = ''
            this.employeeModel.salary = 0
            this.employeeModel.email = ''
            this.employeeModel.password = ''
            this.employeeModel.password_confirmation = ''
            this.employeeModel.joining_date = ''
            this.employeeModel.role_users_id = ''
            this.employeeModel.salary_amount = 0
            this.employeeModel.salary_amount_trans = 0
            this.employeeModel.with_script = 0
            this.employeeModel.salary_amount_syn = 0
            this.employeeModel.salary_amount_audit = 0
            this.employeeModel.salary_amount_poly = 0
            this.employeeModel.des_id = []
            this.employeeModel.file = null
            this.employeeModel.icon = null
            this.employeeModel.over_price = 0
            this.employeeModel.birth_date = ''
        },
        setEmployeeModel(item) {
            this.employeeModel.id = item.id
            this.employeeModel.firstname = item.firstname
            this.employeeModel.lastname = item.lastname
            this.employeeModel.gender = item.gender
            this.employeeModel.phone = item.phone
            this.employeeModel.department_id = item.company.id
            this.employeeModel.salary = item.salary
            this.employeeModel.email = item.email
            this.employeeModel.joining_date = item.joining_date
            this.employeeModel.role_users_id = item.role.id
            this.employeeModel.salary_amount = item.month_salary
            this.employeeModel.salary_amount_trans = item.salary_amount_trans
            this.employeeModel.with_script = item.with_script
            if (item.with_script != 0)
                this.checkIfWithScript = true
            this.employeeModel.salary_amount_syn = item.salary_amount_syn
            this.employeeModel.salary_amount_audit = item.salary_amount_audit
            this.employeeModel.salary_amount_poly = item.salary_amount_poly
            item.designations.forEach(item => {
                this.employeeModel.des_id.push(item.id)
            })
            this.employeeModel.file = item.file
            this.employeeModel.icon = item.avatar
            this.employeeModel.over_price = item.over_price
            this.employeeModel.birth_date = item.birth_date
            this.employeeModel.manager_id = item.manager.id
        },
        addEmployee(formData) {
            this.employeeBase.loading = true;
            EmployeeService.add(formData).then((res) => {
                this.employeeBase.loading = false
                this.show = false
                this.fetchAllEmployee()
                this.clearEmployeeModel()
            })
                .catch((err) => {
                    this.employeeBase.loading = false
                    this.employeeBase.error = true
                });
        },
        fetchAllEmployee() {
            this.employeeBase.loadingFetch = true
            EmployeeService.getAll().then((res) => {
                this.employeeList = res.data.list
                this.employeeBase.loadingFetch = false
                this.employeeBase.errorFetch = false
            })
                .catch((err) => {
                    this.employeeBase.loadingFetch = false
                    this.employeeBase.errorFetch = true
                });
        },
        deleteEmployee(formData) {
            EmployeeService.delete(formData).then((res) => {
                this.employeeBase.loading = false
                this.employeeList.splice(this.employeeBase.editedIndex, 1)
                this.employeeBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.employeeBase.loading = false
                    this.employeeBase.error = true
                })
        },
        getEmployeeById(id) {
            this.employeeBase.loading = true
            EmployeeService.getById(id).then((res) => {
                this.employeeBase.loading = false
                this.employeeDetails = res.data.list
            })
                .catch((err) => {
                    this.employeeBase.loading = false
                    this.employeeBase.error = true
                })
        },
        editEmployee(id, formData) {
            formData.append('employee_id', id)
            this.employeeBase.loading = true
            EmployeeService.edit(formData).then((res) => {
                this.employeeBase.loading = false
                this.employeeBase.ifEdit = false
                this.show = false
                this.fetchAllEmployee()
                this.clearEmployeeModel()
            })
                .catch((err) => {
                    this.employeeBase.loading = false
                    this.employeeBase.error = true
                });
        },
        fetchAllManager(company_id) {
            EmployeeService.getManager(company_id).then((res) => {
                this.managerList = res.data.list.reverse()
            })
        },
        fetchTranlationEmployee(){
            EmployeeService.getTranslation().then((res) => {
                this.tranlationEmployeeList = res.data.list
            })
        },
        fetchSyncEmployee(){
            EmployeeService.getSync().then((res) => {
                this.syncEmployeeList = res.data.list
            })
        },
        fetchAuditEmployee(){
            EmployeeService.getAudit().then((res) => {
                this.auditEmployeeList = res.data.list
            })
        },
        fetchPoliEmployee(){
            EmployeeService.getPoli().then((res) => {
                this.poliEmployeeList = res.data.list
            })
        }
    },
});
