<template>
    <div class="departments">
        <div class="main-wrapper">
            <translation-sidebar  v-role="'admin'"/>
            <all-sidebar v-role="'employee'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">{{title}}</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/translation">Translation</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" v-if="!ifArray">{{title}}</li>
                                    <li class="breadcrumb-item active" v-else v-for="(item, index) in arrayOfTitle" :key="index">{{item}}</li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto" v-if="!hideAdd" v-can="permission">
                                <a class="btn add-btn" @click="showUpdated = true">
                                    <i class="fa fa-plus"></i> Add {{title}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <slot name="cards"></slot>
                    </div>
                    <div>
                        <slot name="dataTable"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TranslationSidebar from '@/modules/translation/core/components/TranslationSidebar.vue'
import AllSidebar from "@/components/main/AllSidebar.vue"

export default {
    name: "TranslationLayout",
    props: {
        title:{
        },
        ifArray: {
            default: false
        },
        arrayOfTitle: {
        },
        hideAdd: {
            default: false
        },
        permission:{

        }
    },
    components: {
        TranslationSidebar,
        AllSidebar
    },
    computed: {
        showUpdated: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        }
    },
};

</script>

<style lang="scss">
@import url(@/assets/css/main.css);

</style>
