import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"1000px"},model:{value:(_vm.filesTaskShow),callback:function ($$v) {_vm.filesTaskShow=$$v},expression:"filesTaskShow"}},[_c(VCard,{staticStyle:{"padding":"10px 0px 20px"}},[_c(VCardTitle,{staticStyle:{"justify-content":"center","margin-bottom":"20px"}},[_c('span',{staticClass:"text-h5 color-primary"},[_vm._v("Files Task")]),_c(VBtn,{staticClass:"btn-close",on:{"click":function($event){_vm.filesTaskShow = false}}},[_c(VIcon,{staticStyle:{"font-size":"18px"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"my-table",staticStyle:{"margin":"20px 0px"},attrs:{"headers":_vm.headers,"items":_vm.filesList,"loading":_vm.taskBase.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:`item.attachment1`,fn:function({ item }){return [_c('div',[_c('a',{attrs:{"href":item.attachment1,"target":"_blank","download":item.attachment1}},[_vm._v(_vm._s(_vm.getFileName(item.attachment1)))])])]}},{key:`item.task_type`,fn:function({ item }){return [(item.task_type == 'trans')?_c('div',[_vm._v(" Translation ")]):_vm._e(),(item.task_type == 'syn')?_c('div',[_vm._v(" Synchronizer ")]):_vm._e(),(item.task_type == 'audit')?_c('div',[_vm._v(" Audit ")]):_vm._e(),(item.task_type == 'poly')?_c('div',[_vm._v(" Poly ")]):_vm._e()]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }