<template>
    <div class="main-wrapper">
        <all-sidebar v-role="'employee'" />
        <div class="page-wrapper">
            <div class="content container-fluid">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">Tasks</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="!taskBase.errorFetch">
                        <employee-task-table />
                    </div>
                    <div v-else>
                        <no-internet @retry="onRetry" :loading="taskBase.loadingFetch"></no-internet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useTaskStore } from "@/modules/translation/task/store/TaskStore"
import { mapActions, mapState } from "pinia"
import EmployeeTaskTable from "../components/EmployeeTaskTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import AllSidebar from "@/components/main/AllSidebar.vue"
export default {
    components: {
        EmployeeTaskTable,
        NoInternet,
        AllSidebar
    },
    data: () => ({
    }),
    computed: {
        ...mapState(useTaskStore, ['taskBase', 'employeeTaskList']),
    },
    methods: {
        ...mapActions(useTaskStore, ['getEmployeeTask']),
        onRetry() {
            this.getEmployeeTask()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
