<template>
    <div class="row">
        <div class="col-md-12">
            <div class="activity">
                <div class="activity-box">
                    <ul class="activity-list" v-for="item in notificationList" :key="item.id">
                        <li>
                            <div class="activity-user avatar">
                                <img src="@/assets/img/user-image.png" alt />
                            </div>
                            <div class="activity-content">
                                <div class="timeline-content">
                                    <span class="noti-title" style="color: #212529">{{ item.title }}</span>
                                    <span class="text-muted" style="margin-left: 10px">{{ item.message }}</span>
                                    <span class="time">
                                        <span v-if="item.days != 0">{{item.days}} days ago</span>
                                        <span
                                            v-else-if="item.days == 0 && item.hours != 0"
                                        >{{item.hours}} hours ago</span>
                                        <span
                                            v-else-if="item.days == 0 && item.hours == 0 && item.min != 0"
                                        >{{item.min}} minutes ago</span>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useNotificationStore } from "../store/NotificationStore"
import { mapState } from "pinia"
export default {
    computed: {
        ...mapState(useNotificationStore, ['notificationBase', 'notificationList']),
    },

}
</script>

<style>
</style>