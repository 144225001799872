import Client from "@/clients/AxiosClient";

export default {
  login(formData) {
    return Client.post("/login", formData);
  },
  getCom(){
    return Client.get("employees")
  },
  logout() {
    return Client.post("/logout");
  },

};