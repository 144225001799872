<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    v-model="fundBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" style="padding: 0px 16px">
                <month-input type="date" :date.sync="start_date" label="Start Date" />
            </v-col>
            <v-col cols="12" sm="4" style="padding: 0px 16px">
                <month-input type="date" :date.sync="end_date" label="End Date" @ok="filterByDate" />
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="fundList"
            :search="fundBase.search"
            class="my-table"
            :loading="fundBase.loadingFetch"
            :item-class="getMyClass"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="fundBase.dialogDeleteModel"
                    :loading="fundBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useFundStore } from "../store/FundStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import MonthInput from "@/components/inputs/MonthInput.vue"
export default {
    components: { ConfirmDeleteDialog, MonthInput },

    data() {
        return {
            headers: [
                {
                    text: "Amount",
                    value: "amount",
                    align: "center"
                },
                {
                    text: "Date",
                    value: "date",
                    align: "center"
                },
                {
                    text: "Description",
                    value: "description",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "type",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            start_date: '',
            end_date: '',
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useFundStore, ['fundBase', 'fundModel', 'fundList']),
    },

    methods: {
        ...mapActions(useFundStore, ['deleteExpense', 'deleteIncome', 'fetchFund']),
        deleteItem(item) {
            this.fundBase.delete = item
            this.fundBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('id', this.fundBase.delete.id)
            this.fundBase.loading = true
            if (this.ifItemIncome(this.fundBase.delete.type))
                this.deleteIncome(formData)
            else
                this.deleteExpense(formData)
        },

        filterByDate() {
            this.fetchFund(this.start_date, this.end_date)
        },

        getMyClass(item) {
            return (this.ifItemIncome(item.type)) ? "row-green" : "row-red"
        },
        ifItemIncome(type){
            return (type == "clients income" || type == "outer income") ? true : false
        }
    },
    mounted() {
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.row-green {
    background-color: rgb(240, 243, 255);
}
.row-red {
    background: seashell;
}
</style>