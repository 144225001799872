<template>
    <v-dialog v-model="dialogDeleteModel" max-width="450px" persistent>
        <v-card>
            <v-spacer></v-spacer>
            <div
                style="padding-top: 30px"
                class="d-flex justify-content-center align-aitems-center"
            >
                <v-icon color="error">mdi-window-close</v-icon>
            </div>
            <v-card-title class="justify-content-center" style="padding: 20px 0px">Are you sure?</v-card-title>
            <v-card-text class="justify-content-center">
                <p
                    class="text-muted"
                    style="text-align: center"
                >Do you really want to delete these record? This process cannot be undone.</p>
            </v-card-text>
            <v-card-actions style="padding-bottom: 30px">
                <v-spacer></v-spacer>
                <v-btn rounded :loading="loading" color="error" @click="deleteItemConfirm">Delete</v-btn>
                <v-btn color="grey" outlined @click="close" rounded>Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'ConfirmDeleteDialog',
    props: {
        dialogDelete: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
        };
    },
    computed: {
        dialogDeleteModel: {
            get() {
                return this.dialogDelete
            },
            set(value) {
                this.$emit('update:dialogDelete', value)
            }
        }
    },

    methods: {
        deleteItemConfirm() {
            this.$emit('confirmed')
        },
        close() {
            this.dialogDeleteModel = false
        }
    }
};
</script>
<style>
.mdi-window-close {
    font-size: 50px !important;
    border-radius: 50%;
    border: var(--v-error-base) 1px solid;
    padding: 15px;
}
</style>