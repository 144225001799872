import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/modules/Dashboard.vue"
import NotFound from "@/modules/NotFound.vue"

Vue.use(VueRouter)

/**
 * @desc this function is pull all routes from modules , the .routes extension is required required in route files
 **/
const routesFiles = require.context("../", true, /\.routes.js$/);
const routesModules = routesFiles.keys().reduce((routesModules, routePath) => {
  const value = routesFiles(routePath);
  routesModules.push(value.default);
  return routesModules;
}, []);


const routes = [
  //modules routes
  ...routesModules,
  //fallback route
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: { title: "Page not found" },
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { title: "Home", permission: "admin_dashboard" },
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
})


//change the title of the browser tab according to route's meta title
const DEFAULT_TITLE = process.env.VUE_APP_PROJECT_NAME;
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    if (to.meta.title) document.title = to.meta.title;
    else document.title = DEFAULT_TITLE;
  });
});


// router.beforeEach((to, from, next) => {
//   const user = localStorage.getItem('user')
//   if (user !== null) {
//     if (to.path == "/login") {
//       next("/");
//     } else {
//       next();
//     }
//   } else if (to.path !== "/login") {
//     next("/login");
//   } else {
//     next();
//   }
// });


router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user !== null) {
    const requiredPermission = JSON.parse(localStorage.getItem('permissions'))
    if (to.path != "/login") {
      if (to.meta.permission != undefined) {
        if (requiredPermission.includes(to.meta.permission))
          next();
        else
          next("/not-found");
      }
      else next();
    }
    else {
      if (user.role == 1)
        next("/");
      else if (user.role == 2)
        next('/employee-dashboard')
      else if (user.role == 5)
        next('/manager-dashboard')
    }
  } else if (to.path !== "/login") {
    next("/login");
  } else {
    next();
  }
});



router.afterEach(() => {
  window.scrollTo(0, 0);
});


export default router
