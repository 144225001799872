<template>
    <div>
        <hr-layout :show.sync="show" title="Leave">
            <template v-slot:dataTable>
                <div v-if="!leaveBase.errorFetch">
                    <leave-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="leaveBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
        <leave-form />
    </div>
</template>

<script>
import { useLeaveStore } from "../store/LeaveStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import LeaveTable from "../components/LeaveTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import LeaveForm from "../components/LeaveForm.vue"

export default {
    components: {
        LeaveTable,
        NoInternet,
        LeaveForm,
        HrLayout,
    },
    data: () => ({
        cardTitle: "Leave List",
        btnTitle: "Add Leave",
    }),
    computed: {
        ...mapState(useLeaveStore, ['leaveBase']),
        ...mapWritableState(useLeaveStore, ['show'])
    },
    methods: {
        ...mapActions(useLeaveStore, ['fetchAllLeave']),
        onRetry() {
            this.fetchAllLeave()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
