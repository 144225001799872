<template>
    <div>
        <translation-layout :show.sync="show" title="Episode">
            <template v-slot:dataTable>
                <div v-if="!episodeBase.errorFetch">
                    <episode-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="episodeBase.loadingFetch"></no-internet>
                </div>
            </template>
        </translation-layout>
        <episode-form />
    </div>
</template>

<script>
import { useEpisodeStore } from "../store/EpisodeStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import TranslationLayout from "@/modules/translation/core/layouts/TranslationLayout.vue"
import EpisodeTable from "../components/EpisodeTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import EpisodeForm from "../components/EpisodeForm.vue"

export default {
    components: {
        EpisodeTable,
        NoInternet,
        EpisodeForm,
        TranslationLayout,
    },
    data: () => ({
        cardTitle: "Episode List",
        btnTitle: "Add Episode",
    }),
    computed: {
        ...mapState(useEpisodeStore, ['episodeBase']),
        ...mapWritableState(useEpisodeStore, ['show'])
    },
    methods: {
        ...mapActions(useEpisodeStore, ['fetchAllEpisode']),
        onRetry() {
            this.fetchAllEpisode()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
