<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="clientBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="clientList"
            :search="clientBase.search"
            class="my-table"
            :loading="clientBase.loadingFetch"
            loading-text="Loading... Please wait"
            
        >
            
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="clientBase.dialogDeleteModel"
                    :loading="clientBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.details`]="{ item }">
                <v-btn @click="displayItem(item)" text icon color="indigo">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useClientStore } from "../store/ClientStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "First Name",
                    value: "firstname",
                    align: "center"
                },
                {
                    text: "Last Name",
                    value: "lastname",
                    align: "center"
                },
                {
                    text: "Shortcut",
                    value: "shortcut",
                    align: "center"
                },
                {
                    text: "Minute Price",
                    value: "price for minute",
                    align: "center"
                },
                {
                    text: "Details",
                    value: "details",
                    align: "center",
                    sortable: false
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            expanded: [],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useClientStore, ['clientBase', 'clientList']),
        ...mapWritableState(useClientStore, ['show'])
    },

    methods: {
        ...mapActions(useClientStore, ['fetchAllClient', 'deleteClient', 'setClientModel']),

        deleteItem(item) {
            this.clientBase.editedIndex = this.clientList.indexOf(item)
            this.clientBase.delete = item
            this.clientBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('client_id', this.clientBase.delete.id)
            this.clientBase.loading = true
            this.deleteClient(formData)
        },

        editItem(item) {
            this.clientBase.ifEdit = true
            this.setClientModel(item)
            this.show = true
        },

        displayItem(item){
            this.$router.push(`/hr/clients/${item.id}`)
        }
    },
    mounted() {
        this.fetchAllClient()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>