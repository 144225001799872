<template>
    <v-app id="app">
        <navbar v-if="!['login'].includes($route.name)" />
        <router-view />
    </v-app>
</template>


<script>
import Navbar from "@/components/Navbar/Navbar.vue"
import CustomToast from '@/components/main/CustomToast.vue'

export default {
    components: {
        Navbar,
        CustomToast
    },
    mounted() {
        this.$root.CustomToast = this.$refs.CustomToast
    },
}
</script>
<style lang="scss">
#app {
    text-align: justify;
    font-family: "CircularStd", sans-serif;
    font-size: 15px;
    color: #1f1f1f;
    background: #f2f2f29a !important;
}
a:hover {
    text-decoration: none !important;
    outline: none !important;
}
</style>
