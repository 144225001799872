import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import OvertimeService from "../services/OvertimeService";

export const useOvertimeStore = defineStore("overtimeStore", {
    state: () => ({
        overtimeBase: new BaseState(),
        overtimeModel: {
            id: '',
            hour: '',
            price: '',
        },
        overtimeList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        setOvertimeModel(item) {
            this.overtimeModel.id = item.id
            this.overtimeModel.hour = item.hour
            this.overtimeModel.price = item.price
        },
        fetchOvertime() {
            this.overtimeBase.loadingFetch = true
            OvertimeService.get().then((res) => {
                this.overtimeList = [res.data.list]
                this.overtimeBase.loadingFetch = false
                this.overtimeBase.errorFetch = false
            })
                .catch((err) => {
                    this.overtimeBase.loadingFetch = false
                    this.overtimeBase.errorFetch = true
                });
        },
        editOvertime(formData) {
            this.overtimeBase.loading = true
            OvertimeService.edit(formData).then((res) => {
                this.overtimeBase.loading = false
                this.overtimeBase.ifEdit = false
                this.show = false
                this.fetchOvertime()
            })
                .catch((err) => {
                    this.overtimeBase.loading = false
                    this.overtimeBase.error = true
                });
        },
    },
});
