<template>
    <div v-if="!employeeBase.loading && employeeDetails">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="profile-view">
                            <div class="profile-img-wrap">
                                <div class="profile-img">
                                    <img src="@/assets/img/user-image.png" />
                                </div>
                            </div>
                            <div class="profile-basic" >
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="profile-info-left">
                                            <h3
                                                class="user-name m-t-0 mb-0"
                                            >{{ employeeDetails.username }}</h3>
                                            <small
                                                class="text-muted"
                                                v-if="employeeDetails.company"
                                            >{{ employeeDetails.company.name }}</small>
                                            <div class="staff-id">Employee ID: {{employeeDetails.id}}</div>
                                            <div
                                                class="small doj text-muted"
                                            >Date of Join : {{employeeDetails.joining_date}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <ul class="personal-info">
                                            <li>
                                                <div class="profile-title">Phone:</div>
                                                <div class="text">
                                                    <a
                                                        :href="`tel:${employeeDetails.phone}`"
                                                    >{{employeeDetails.phone}}</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="profile-title">Email:</div>
                                                <div class="text">
                                                    <a
                                                        :href="`mailto:${employeeDetails.email}`"
                                                    >{{employeeDetails.email}}</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="profile-title">Birthday:</div>
                                                <div class="text">{{employeeDetails.birth_date}}</div>
                                            </li>
                                            <li>
                                                <div class="profile-title">Gender:</div>
                                                <div class="text">{{employeeDetails.gender}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-row>
            <v-col cols="12" sm="6">
                <div class="tab-content">
                    <div id="emp_profile" class="pro-overview tab-pane fade show active">
                        <div class="card profile-box flex-fill">
                            <div class="card-body">
                                <h3 class="card-title">Work Hours</h3>
                                <ul
                                    class="personal-info"
                                    v-if="employeeDetails.statistics"
                                    style="padding: 0px;"
                                >
                                    <li>
                                        <div class="profile-title">Total Minutes</div>
                                        <div
                                            class="text"
                                        >{{ employeeDetails.statistics.total_minutes }}</div>
                                    </li>
                                    <li>
                                        <div class="profile-title">Total Price</div>
                                        <div class="text">{{ employeeDetails.statistics.price }}</div>
                                    </li>
                                    <li>
                                        <div class="profile-title">Monthly Total Minutes</div>
                                        <div
                                            class="text"
                                        >{{ employeeDetails.statistics.monthly_total_minutes }}</div>
                                    </li>
                                    <li>
                                        <div class="profile-title">Monthly Price</div>
                                        <div
                                            class="text"
                                        >{{ employeeDetails.statistics.monthly_price }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <div class="tab-content">
                    <div id="emp_profile" class="pro-overview tab-pane fade show active">
                        <div class="card profile-box flex-fill">
                            <div class="card-body">
                                <h3 class="card-title">Accounting</h3>
                                <ul
                                    class="personal-info"
                                    v-if="employeeDetails.statistics"
                                    style="padding: 0px;"
                                >
                                    <li>
                                        <div class="profile-title">Salary Type</div>
                                        <div class="text">{{ employeeDetails.salary }}</div>
                                    </li>
                                    <li v-if="employeeDetails.salary == 'monthly'">
                                        <div class="profile-title">Month Salary</div>
                                        <div class="text">{{ employeeDetails.month_salary }}</div>
                                    </li>
                                    <li
                                        v-if="employeeDetails.salary == 'minutes' && employeeDetails.salary_amount_audit != 0"
                                    >
                                        <div class="profile-title">Salary Amount Audit</div>
                                        <div class="text">{{ employeeDetails.salary_amount_audit }}</div>
                                    </li>
                                    <li
                                        v-if="employeeDetails.salary == 'minutes' && employeeDetails.salary_amount_poly != 0"
                                    >
                                        <div class="profile-title">Salary Amount Poly</div>
                                        <div class="text">{{ employeeDetails.salary_amount_poly }}</div>
                                    </li>
                                    <li
                                        v-if="employeeDetails.salary == 'minutes' && employeeDetails.salary_amount_syn != 0"
                                    >
                                        <div class="profile-title">Salary Amount Syn</div>
                                        <div class="text">{{ employeeDetails.salary_amount_syn }}</div>
                                    </li>
                                    <li
                                        v-if="employeeDetails.salary == 'minutes' && employeeDetails.salary_amount_trans != 0"
                                    >
                                        <div class="profile-title">Salary Amount Translation</div>
                                        <div class="text">{{ employeeDetails.salary_amount_trans }}</div>
                                    </li>
                                    <li
                                        v-if="employeeDetails.salary == 'minutes' && employeeDetails.with_script != 0"
                                    >
                                        <div class="profile-title">Salary Amount & Script</div>
                                        <div class="text">{{ employeeDetails.with_script }}</div>
                                    </li>

                                    <li>
                                        <div class="profile-title">Over Price</div>
                                        <div class="text">{{ employeeDetails.over_price }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    <loader v-else />
</template>

<script>
import { useEmployeeStore } from "../store/EmployeeStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import Loader from "@/components/main/Loader.vue"

export default {
    components: {
        Loader
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useEmployeeStore, ['employeeBase', 'employeeDetails']),
    },

    methods: {
        ...mapActions(useEmployeeStore, ['getEmployeeById']),
    },

    mounted() {
        this.getEmployeeById(this.$route.params.id)

    },
}
</script>

<style>
.profile-title {
    color: #333333;
    float: left;
    font-weight: 500;
    width: 40%;
}
</style>