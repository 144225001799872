<template>
    <div>
        <accounting-layout :show.sync="show" title="Wallet" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!walletBase.errorFetch">
                    <wallet-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="walletBase.loadingFetch"></no-internet>
                </div>
            </template>
        </accounting-layout>
    </div>
</template>

<script>
import { useWalletStore } from "../store/WalletStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import AccountingLayout from "@/modules/accounting/core/layouts/AccountingLayout.vue"
import WalletTable from "../components/WalletTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"

export default {
    components: {
        WalletTable,
        NoInternet,
        AccountingLayout,
    },
    data: () => ({
        cardTitle: "Wallet List",
        btnTitle: "Add Wallet",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                accountingef: '/',
            },
            {
                text: 'Wallet',
                disabled: false,
                accountingef: '/wallet',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                accountingef: '/',
            },
            {
                text: ' تواصل معنا',
                disabled: false,
                accountingef: '/wallet',
            }
        ],
    }),
    computed: {
        ...mapState(useWalletStore, ['walletBase']),
        ...mapWritableState(useWalletStore, ['show'])
    },
    methods: {
        ...mapActions(useWalletStore, ['fetchWallet']),
        onRetry() {
            this.fetchWallet()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
