<template>
    <v-btn
        @click="$emit('click')"
        color="primary"
        large
        light
        :loading="loading"
        :block="ifBlock"
        rounded
    >
        Submit
    </v-btn>
</template>

<script>
export default {
    name: "Submit",
    props: ['loading', 'name', 'ifBlock'],
};
</script>
<style>
.v-btn__content{
    padding: 0 20px;
}</style>
