<template>
    <div class="departments">
        <div class="main-wrapper">
            <hr-sidebar v-role="'admin'" />
            <all-sidebar v-role="'employee'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">{{title}}</h3>
                                <ul class="breadcrumb">
                                    <!-- <li class="breadcrumb-item">
                                        <router-link to="/hr">HR</router-link>
                                    </li> -->
                                    <li class="breadcrumb-item active">HR</li>
                                    <li class="breadcrumb-item active">{{title}}</li>
                                </ul>
                            </div>
                            <slot name="icons"></slot>
                            <div class="col-auto float-end ms-auto" v-if="!hideAdd && title!='Employee'">
                                <a class="btn add-btn" @click="showUpdated = true">
                                    <i class="fa fa-plus"></i> Add {{title}}
                                </a>
                            </div>
                            <div class="col-auto float-end ms-auto" v-if="title=='Employee'" v-can="'employee_add'">
                                <a class="btn add-btn" @click="showUpdated = true">
                                    <i class="fa fa-plus"></i> Add {{title}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <slot name="cards"></slot>
                    </div>
                    <div>
                        <slot name="dataTable"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
import AllSidebar from "@/components/main/AllSidebar.vue"

export default {
    name: "HrLayout",
    props: {
        title:{
            required: true
        },
        hideAdd: {
            default: false
        }
    },
    components: {
        HrSidebar,
        AllSidebar
    },
    computed: {
        showUpdated: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        }
    },
};

</script>

<style lang="scss">
@import url(@/assets/css/main.css);

</style>
