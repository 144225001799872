import Client from "@/clients/AxiosClient";

export default {
    get() {
        return Client.get("/completed_tasks")
    },
    editPercent(formData){
        return Client.post("/approve_task", formData)
    },
    addFile(formData){
        return Client.post("/add_file", formData)
    }
};