<template>
    <form-dialog
        :title="!employeeBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        width="850px"
        @cancel="onCancel"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="employee-form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="employeeModel.firstname" label="First Name" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="employeeModel.lastname" label="Last Name" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="employeeModel.gender"
                            :items="genderList"
                            label="Gender"
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <date-input
                            :date.sync="employeeModel.birth_date"
                            label="Birth Date"
                            :allowedDates="allowedDates"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="employeeModel.phone" label="Phone" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <email-input :email.sync="employeeModel.email" label="Email" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <password-input :password.sync="employeeModel.password" label="Password" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <repeat-password-input
                            :repeatPassword.sync="employeeModel.password_confirmation"
                            label="Repeat Password"
                            :password="employeeModel.password"
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <date-input :date.sync="employeeModel.joining_date" label="Joining Date" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="employeeModel.over_price" label="Over Price" />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <image-input
                            :image.sync="employeeModel.icon"
                            label="Image"
                            :ifEdit="employeeBase.ifEdit"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-file-input
                            outlined
                            chips
                            :reverse="getDirection"
                            v-model="employeeModel.file"
                            label="CV"
                            append-icon="mdi-paperclip"
                            prepend-icon
                            show-size
                            :rules="[requiredRule]"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="employeeModel.department_id"
                            :items="departmentList"
                            label="Department"
                            item_text="name"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="employeeModel.role_users_id"
                            :items="myRole"
                            label="Role"
                            item_text="name"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="employeeModel.salary"
                            :items="salaryTypeList"
                            label="Salary Type"
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="employeeModel.des_id"
                            :items="designationList"
                            label="Designation"
                            :ifMultiple="true"
                            item_text="designation"
                            item_value="id"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="(employeeModel.role_users_id == 2 && employeeModel.department_id == 1)"
                    >
                        <select-input
                            :model.sync="employeeModel.manager_id"
                            :items="managerList"
                            label="Manager"
                            item_text="username"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="employeeModel.salary == 'monthly'">
                        <number-input
                            :number.sync="employeeModel.salary_amount"
                            label="Salary Amount"
                        />
                    </v-col>

                    <v-col cols="12" sm="6" v-if="ifPoli && employeeModel.salary == 'minutes'">
                        <number-input
                            :number.sync="employeeModel.salary_amount_poly"
                            label="Salary Amount Poli"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="ifTranslator && employeeModel.salary == 'minutes'"
                    >
                    <v-row style="margin: 0px">
                        <number-input
                            :number.sync="employeeModel.salary_amount_trans"
                            label="Salary Amount Trasnlator"
                        />
                        <v-checkbox v-model="checkIfWithScript" :persistent-hint="true" hint="With Script"></v-checkbox>
                    </v-row>
                        
                    </v-col>
                    <v-col cols="12" sm="6" v-if="checkIfWithScript && employeeModel.salary == 'minutes' && ifTranslator">
                        <number-input
                            :number.sync="employeeModel.with_script"
                            label="Salary Amount & Script "
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="ifSync && employeeModel.salary == 'minutes'">
                        <number-input
                            :number.sync="employeeModel.salary_amount_syn"
                            label="Salary Amount Synchronizer"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="ifChecker && employeeModel.salary == 'minutes'">
                        <number-input
                            :number.sync="employeeModel.salary_amount_audit"
                            label="Salary Amount Checker"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <!-- <cancel-btn @click="cancel" /> -->
            <submit-btn @click="submit" :loading="employeeBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useEmployeeStore } from "../store/EmployeeStore"
import { useLangStore } from "@/store/LangStore"
import { useDepartmentStore } from "@/modules/hr/department/store/DepartmentStore"
import { useDesignationStore } from "@/modules/hr/designation/store/DesignationStore"
import { useRoleStore } from "@/modules/general/role/store/RoleStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import EmailInput from "@/components/inputs/EmailInput.vue"
import PasswordInput from "@/components/inputs/PasswordInput.vue"
import RepeatPasswordInput from "@/components/inputs/RepeatPasswordInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"
import ImageInput from "@/components/inputs/ImageInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        EmailInput,
        PasswordInput,
        RepeatPasswordInput,
        NumberInput,
        ImageInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Employee",
            titleEdit: "Edit Employee",
            showPassword: false,
            showRepeatPassword: false,
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useEmployeeStore, ["employeeBase", "employeeModel", 'genderList', 'salaryTypeList', 'managerList']),
        ...mapWritableState(useEmployeeStore, ["show", 'checkIfWithScript']),
        ...mapState(useDepartmentStore, ['departmentList']),
        ...mapState(useDesignationStore, ['designationList']),
        ...mapState(useRoleStore, ['roleList']),
        myRole() {
            return this.roleList.filter(item => (item.id == 2 || item.id == 5))
        },
        ifPoli() {
            return this.employeeModel.des_id.includes(9) ? true : false
        },
        ifTranslator() {
            return this.employeeModel.des_id.includes(4) ? true : false
        },
        ifSync() {
            return this.employeeModel.des_id.includes(2) ? true : false
        },
        ifChecker() {
            return this.employeeModel.des_id.includes(1) ? true : false
        },
    },
    methods: {
        ...mapActions(useEmployeeStore, ['addEmployee', 'editEmployee', 'clearEmployeeModel', 'fetchAllManager']),
        ...mapActions(useRoleStore, ['fetchAllRole']),
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        ...mapActions(useDesignationStore, ['fetchAllDesignation']),
        allowedDates: val => val < new Date().toJSON().slice(0, 10),
        submit() {
            if (this.$refs.form.validate()) {
                this.employeeBase.ifEdit
                    ? this.editEmployee(this.employeeModel.id, this.createFormData())
                    : this.addEmployee(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('firstname', this.employeeModel.firstname)
            formData.append('lastname', this.employeeModel.lastname)
            formData.append('gender', this.employeeModel.gender)
            formData.append('company_id', this.employeeModel.department_id)
            formData.append('manager_id', this.employeeModel.manager_id)
            formData.append('salary', this.employeeModel.salary)
            formData.append('email', this.employeeModel.email)
            formData.append('phone', this.employeeModel.phone)
            formData.append('password', this.employeeModel.password)
            formData.append('password_confirmation', this.employeeModel.password_confirmation)
            formData.append('joining_date', this.employeeModel.joining_date)
            formData.append('role_users_id', this.employeeModel.role_users_id)
            formData.append('salary_amount', this.employeeModel.salary_amount)
            formData.append('salary_amount_trans', this.employeeModel.salary_amount_trans)
            formData.append('with_script', this.employeeModel.with_script)
            formData.append('salary_amount_syn', this.employeeModel.salary_amount_syn)
            formData.append('salary_amount_audit', this.employeeModel.salary_amount_audit)
            formData.append('salary_amount_poly', this.employeeModel.salary_amount_poly)
            formData.append('file', this.employeeModel.file)
            formData.append('icon', this.employeeModel.icon)
            formData.append('over_price', this.employeeModel.over_price)
            formData.append('birth_date', this.employeeModel.birth_date)
            this.employeeModel.des_id.forEach((item, index) => {
                formData.append(`des_id[${index}]`, item)
            })
            return formData
        },
        onCancel() {
            this.show = false
            this.employeeBase.ifEdit = false
            this.clearEmployeeModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.fetchAllDepartment()
        this.fetchAllRole()
        this.fetchAllDesignation()
        this.fetchAllManager(1)
    }
};
</script>
<style>
.employee-form .col-12 {
    padding: 0px 15px !important;
}
.employee-form .v-input--checkbox{
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 10px;
    top: 10px
}
.employee-form .v-input--checkbox .v-label{
    margin: 0px;
}
.employee-form .v-input--checkbox .v-icon.v-icon{
    font-size: 30px !important;
}
.employee-form .v-input--checkbox .v-input__slot{
    justify-content: center;
}
</style>