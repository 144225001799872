import Client from "@/clients/AxiosClient";

export default {
    get(date) {
        if (date)
            return Client.get(`/wallets?date=${date}`);
        else
            return Client.get("/wallets");
    },

};