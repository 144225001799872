import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_holiday", formData);
    },

    getAll() {
        return Client.get("/holidays");
    },

    getById(id) {
        return Client.get(`/holiday?holiday_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_holiday`, formData);
    },

    delete(formData) {
        return Client.post(`/delete_holiday`, formData);
    },
};