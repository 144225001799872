<template>
    <div v-if="!employeeBase.loadingFetch">
        <div class="row staff-grid-row" v-if="employeeList.length > 0">
            <div
                class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                v-for="item in employeeList"
                :key="item.id"
            >
                <div class="profile-widget">
                    <div class="profile-img" @click="displayItem(item)">
                        <div class="avatar">
                            <img src="@/assets/img/user-image.png" />
                        </div>
                    </div>
                    <div class="dropdown profile-action">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <i class="material-icons">more_vert</i>
                                </v-btn>
                            </template>
                            <v-list>
                                <a class="dropdown-item" @click="editItem(item)" v-can="'employee_edit'">
                                    <i class="fa fa-pencil m-r-5"></i> Edit
                                </a>
                                <a class="dropdown-item" @click="deleteItem(item)" v-can="'employee_delete'">
                                    <i class="fa fa-trash m-r-5"></i> Delete
                                </a>
                            </v-list>
                        </v-menu>
                    </div>
                    <h5 class="user-name m-t-10 mb-1 text-ellipsis" @click="displayItem(item)">{{ item.username }}</h5>
                    <div class="small text-muted">{{ item.company.name }}</div>
                </div>
            </div>
        </div>
        <confirm-delete-dialog
            :dialog-delete.sync="employeeBase.dialogDeleteModel"
            :loading="employeeBase.loading"
            @confirmed="onConfirmed"
        ></confirm-delete-dialog>
    </div>
    <loader v-else />
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useEmployeeStore } from "../store/EmployeeStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import Loader from "@/components/main/Loader.vue"
export default {
    components: { ConfirmDeleteDialog, Loader },

    data() {
        return {
            permissions: []
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useEmployeeStore, ['employeeBase', 'employeeList']),
        ...mapWritableState(useEmployeeStore, ['show'])
    },

    methods: {
        ...mapActions(useEmployeeStore, ['fetchAllEmployee', 'deleteEmployee', 'setEmployeeModel']),
        deleteItem(item) {
            this.employeeBase.editedIndex = this.employeeList.indexOf(item)
            this.employeeBase.delete = item
            this.employeeBase.dialogDeleteModel = true
        },
        onConfirmed() {
            const formData = new FormData()
            formData.append('employee_id', this.employeeBase.delete.id)
            this.employeeBase.loading = true
            this.deleteEmployee(formData)
        },
        editItem(item) {
            console.log(item)
            this.employeeBase.ifEdit = true
            this.setEmployeeModel(item)
            this.show = true
        },

        displayItem(item) {
            if(this.permissions.includes('employee_details'))
                this.$router.push(`/hr/employees/${item.id}`)
        }
    },
    mounted() {
        this.fetchAllEmployee()
        this.permissions = JSON.parse(localStorage.getItem('permissions'))
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.profile-widget .user-name:hover{
    cursor: pointer;
}
</style>