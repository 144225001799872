<template>
    <div class="main-wrapper">
        <accounting-sidebar></accounting-sidebar>
        <div class="page-wrapper">
            <!-- header -->
            <div class="content container-fluid">
                <div class="page-header"></div>
            </div>
        </div>
    </div>
</template>
<script>

import AccountingSidebar from '@/modules/accounting/core/components/AccountingSidebar.vue'

export default {
    components: {
        AccountingSidebar
    },
    mounted() {
    },
    methods: {
    },
}
</script>