import Employee from "@/modules/hr/employee/pages/EmployeeListView.vue"
import EmployeeGrid from "@/modules/hr/employee/pages/EmployeeGridView.vue"
import EmployeeProfile from "@/modules/hr/employee/pages/EmployeeProfileView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/hr",
    component: BaseFormPageWrapper,
    children:[
        {
            path: "/hr/employees",
            name: "employees",
            component: Employee,
            meta: { title: "Employee", permission: "employee_view" }
        },
        {
            path: "/hr/employees-grid",
            name: "employees-grid",
            component: EmployeeGrid,
            meta: { title: "Employee", permission: "employee_view" }
        },
        {
            path: "/hr/employees/:id",
            name: "employees-profile",
            component: EmployeeProfile,
            meta: { title: "Employee", permission: "employee_details" }
        }
    ]
    
};
