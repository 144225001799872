<template>
    <v-file-input
        outlined
        :reverse="getDirection"
        v-model="newText"
        :rules="[ifReq ? requiredRule : noRule, videoRule]"
        :label="label"
        append-icon="mdi-camera"
        prepend-icon
    ></v-file-input>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "TextInput",
    props: {
        video: {
            required: true
        },
        label: {
            type: String,
            required: true
        },
        ifNotReq: {
            type: Boolean
        },
        ifReq:{
            type: Boolean,
            default: true,
        }
    },
    mixins: [FormValidationRulesMixin],
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newText: {
            get() {
                return this.video
            },
            set(value) {
                this.$emit('update:video', value)
            }
        },
    },
}
</script>

<style>
</style>