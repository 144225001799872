<template>
    <form-dialog
        :title="!paycheckBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="600px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="paycheck-form">
                <v-row>
                    <v-col cols="12" v-if="role != 2">
                        <select-input :model.sync="paycheckModel.employee_id" :items="employeeList" item_text="username" item_value="id" label="Employee" />
                    </v-col>
                    <v-col cols="12">
                        <select-input :model.sync="paycheckModel.company_id" :items="departmentList" item_text="name" item_value="id" label="Department" />
                    </v-col>
                    <v-col cols="12">
                        <date-input :date.sync="paycheckModel.date" label="Date" />
                    </v-col>
                    <v-col cols="12">
                        <number-input :number.sync="paycheckModel.amount" label="Amount" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="paycheckBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { usePaycheckStore } from "../store/PaycheckStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import { useEmployeeStore } from "@/modules/hr/employee/store/EmployeeStore"
import { useDepartmentStore } from "@/modules/hr/department/store/DepartmentStore" 
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Paycheck",
            titleEdit: "Edit Paycheck",
            role: '',
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(usePaycheckStore, ["paycheckBase", "paycheckModel"]),
        ...mapWritableState(usePaycheckStore, ["show"]),
        ...mapState(useEmployeeStore, ['employeeList']),
        ...mapState(useDepartmentStore, ['departmentList'])
    },
    methods: {
        ...mapActions(usePaycheckStore, ['addPaycheck', 'editPaycheck', 'clearPaycheckModel']),
        ...mapActions(useEmployeeStore, ['fetchAllEmployee']),
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        submit() {
            if (this.$refs.form.validate()) {
                this.paycheckBase.ifEdit
                    ? this.editPaycheck(this.paycheckModel.id, this.createFormData())
                    : this.addPaycheck(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('employee_id', this.paycheckModel.employee_id)
            formData.append('company_id', this.paycheckModel.company_id)
            formData.append('date', this.paycheckModel.date)
            formData.append('amount', this.paycheckModel.amount)
            return formData
        },
        onCancel() {
            this.show = false
            this.paycheckBase.ifEdit = false
            this.clearPaycheckModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted(){
        this.role = JSON.parse(localStorage.getItem('user')).role
        if(this.role != 2)
            this.fetchAllEmployee()
        else 
            this.paycheckModel.employee_id = JSON.parse(localStorage.getItem('user')).id
        this.fetchAllDepartment()
    }
};
</script>
<style>
.paycheck-form .col-12{
    padding: 0 15px;
}
</style>