var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row dashboard"},[_c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget",on:{"click":function($event){return _vm.pushRoute('/hr/departments')}}},[_vm._m(0)])]),_c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget",on:{"click":function($event){return _vm.pushRoute('/translation/projects')}}},[_vm._m(1)])]),_c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget",on:{"click":function($event){return _vm.pushRoute('/accounting/account')}}},[_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fas fa-user-alt"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("HR")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fa fa-language"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Translation")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"far fa-money-bill-alt"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Accounting")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fas fa-microphone-alt"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Dubbing")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fas fa-film"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Montage")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fas fa-video"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Filming")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fas fa-file-audio"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Script")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-6 col-lg-6 col-xl-3"},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"fas fa-user-tie"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',{staticStyle:{"margin-bottom":"0px","font-size":"22px","color":"#1f1f1f"}},[_vm._v("Customers")])])])])])
}]

export { render, staticRenderFns }