<template>
    <div>
        <accounting-layout
            :show.sync="show"
            title="Translation"
            :arrayOfTitle="arrayOfTitle"
            :ifArray="true"
            addTitle="Expense"
        >
            <template v-slot:cards v-if="finishLoad()">
                <v-row>
                    <v-col cols="12" sm="4">
                        <div class="stats-info">
                            <h6>Expense Balance</h6>
                            <h4>{{parseInt(accountModel.total_expenses_amount)}}
                                </h4>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div class="stats-info">
                            <h6>Total Balance</h6>
                            <h4>{{parseInt(accountModel.current_balance)}}</h4>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div class="stats-info">
                            <h6>Income Balance</h6>
                            <h4>{{parseInt(accountModel.income_amount)}}</h4>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:dataTable>
                <div v-if="finishLoad()">
                    <account-expense-table />
                </div>
                <div v-if="inLoad()">
                    <loader />
                </div>
                <div v-if="error()">
                    <no-internet @retry="onRetry" :loading="accountBase.loadingFetch"></no-internet>
                </div>
            </template>
        </accounting-layout>
        <expense-form />
    </div>
</template>

<script>
import { useAccountStore } from "../store/AccountStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import AccountingLayout from "@/modules/accounting/core/layouts/AccountingLayout.vue"
import AccountExpenseTable from "../components/AccountExpenseTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import ExpenseForm from "../components/ExpenseForm.vue"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        AccountExpenseTable,
        NoInternet,
        AccountingLayout,
        ExpenseForm,
        Loader
    },
    data: () => ({
        arrayOfTitle: ["Account", "Translation"],
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                accountingef: '/',
            },
            {
                text: 'Account',
                disabled: false,
                accountingef: '/account',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                accountingef: '/',
            },
            {
                text: ' تواصل معنا',
                disabled: false,
                accountingef: '/account',
            }
        ],
    }),
    computed: {
        ...mapState(useAccountStore, ['accountBase', 'accountModel']),
        ...mapWritableState(useAccountStore, ['show'])
    },
    methods: {
        ...mapActions(useAccountStore, ['getById']),
        onRetry() {
            this.getById(this.$route.params.id)
        },
        finishLoad() {
            return (!this.accountBase.loadingFetch && this.accountModel != null) ? true : false
        },
        inLoad() {
            return (this.accountBase.loadingFetch) ? true : false
        },
        error() {
            return (this.accountBase.errorFetch && !this.accountBase.loadingFetch) ? true : false
        }
    },
    mounted(){
        this.getById(this.$route.params.id)
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
