import Episode from "@/modules/translation/episode/pages/EpisodeListView.vue"
import EpisodeProject from "@/modules/translation/episode/pages/EpisodeProjectListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/translation",
    component: BaseFormPageWrapper,
    children: [
        {
            path: '/translation/episodes',
            name: "episodes",
            component: Episode,
            meta: { title: "Episode" }
        },
        {
            path: '/translation/project/:project_id/episodes',
            name: "episodes-project",
            component: EpisodeProject,
            meta: { title: "Episode Project" }
        },
    ]
    
};
