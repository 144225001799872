<template>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner" id="style-15">
            <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul class="sidebar-vertical">
                        <li class="menu-title">
                            <span>HR</span>
                        </li>
                        <li>
                            <router-link to="/hr/departments" v-bind:class="{ 'active': currentPath == 'departments' }"><i
                                    class="fas fa-sitemap"></i> <span>Departments</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/designations" v-bind:class="{ 'active': currentPath == 'designations' }"><i
                                    class="fas fa-table"></i> <span>Designations</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/employees" v-bind:class="{ 'active': currentPath == 'employees'  || currentPath == 'employees-grid' || currentPath == 'employees-profile'}"><i
                                    class="fas fa-user-tie"></i> <span>Employees</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/attendance" v-bind:class="{ 'active': currentPath == 'attendance'}"><i
                                    class="fas fa-calendar-check"></i> <span>Attendance</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/monthly-attendance" v-bind:class="{ 'active': currentPath == 'monthly-attendance'}"><i
                                    class="fas fa-calendar-check"></i> <span>Monthly Attendance</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/leaves" v-bind:class="{ 'active': currentPath == 'leaves' }"><i
                                    class="far fa-calendar-times"></i> <span>Request Leave</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/holidays" v-bind:class="{ 'active': currentPath == 'holidays' }"><i
                                    class="fas fa-umbrella-beach"></i> <span>Holidays</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/clients" v-bind:class="{ 'active': currentPath == 'clients' || currentPath == 'clients-details'}"><i
                                    class="fas fa-users"></i> <span>Clients</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/fund" v-bind:class="{ 'active': currentPath == 'fund' }"><i
                                    class="fas fa-money-bill"></i> <span>Fund</span></router-link>
                        </li>
                    </ul>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <!-- </sidebar> -->
</template>
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
export default {
    components: {
        PerfectScrollbar,
    },
    mounted() {
        $('#sidebar-menu a').on('click', function (e) {
            if ($(this).parent().hasClass('submenu')) {
                e.preventDefault();
            }
            if (!$(this).hasClass('subdrop')) {
                $('ul', $(this).parents('ul:first')).slideUp(350);
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $(this).next('ul').slideDown(350);
                $(this).addClass('subdrop');
            } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').slideUp(350);
            }
        });
        $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

    },
    computed: {
        currentPath() {
            return this.$route.name;
        }
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            activeClass: 'active',
        };
        //  isactive : true
    },
    methods: {
        scrollHanle(evt) {

        }
    },
}

</script>

