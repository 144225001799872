<template>
    <v-dialog v-model="showUpdated" :max-width="width" :persistent="persistent">
        <v-card style="padding:10px 0px 20px">
            <v-card-title style=" justify-content: center;margin-bottom: 20px">
                <span class="text-h5 color-primary">{{ title }}</span>
                <v-btn class="btn-close" @click="cancel" ><v-icon style="font-size: 18px;">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-container>
                    <slot name="inputs"></slot>
                </v-container>
            </v-card-text>
            <v-divider />
            <v-card-actions style="column-gap: 10px;justify-content: center;">
                <!-- <v-spacer></v-spacer> -->
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import { useDialogStore } from "@/store/DialogStore"
// import { mapWritableState } from "pinia"
export default {
    name: "FormDialog",
    props: {
        title: {
            type: String,
            required: true
        },
        persistent: {
            type: Boolean, 
            default: false
        },
        show: {
            type: Boolean,
        },
        width:{
            type: String,
            default: "600px"
        }
    },
    computed: {
        // ...mapWritableState(useDialogStore, ["show"]),
        showUpdated: {
            get(){
                return this.show
            },
            set(value){
                this.$emit('update:show', value)
            }
        }
    },
    methods:{
        cancel() {
            this.$emit('cancel')
        },
    }
};
</script>
<style>
.v-dialog{
    border-radius: 20px;
    overflow-x: hidden;
}
.btn-close{
    z-index: 99;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background-color: var(--v-primary-base) !important;
    height: 32px !important;
    width: 30px !important;
    border: 1px solid var(--v-primary-base);
    color: #fff !important;
    transition: all .2s ease;
}
.btn-close:hover {
    background-color: #fff !important;
    color:  var(--v-primary-base) !important;
    transition: all .2s ease;
}
.v-btn:not(.v-btn--round).v-size--default{
    min-width: unset !important;
}
</style>
