<template>
    <v-dialog v-model="show" max-width="1000px">
        <v-card style="padding:10px 0px 20px" v-if="walletModel != null">
            <v-card-title style=" justify-content: center;margin-bottom: 20px">
                <span class="text-h5 color-primary">{{ walletModel[0].employee.username }}</span>
                <v-btn class="btn-close" @click="onCancel">
                    <v-icon style="font-size: 18px;">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4" style="padding: 0px 16px">
                        <month-input type="date" :date.sync="date" label="Date"  @ok="filterByDate" />
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="walletModel"
                    class="my-table"
                    style="margin-bottom: 50px"
                    :loading="walletBase.loadingFetch"
                    loading-text="Loading... Please wait"
                ></v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { useWalletStore } from "../store/WalletStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapWritableState, mapActions } from "pinia"
import MonthInput from "@/components/inputs/MonthInput.vue"
export default {
    components: {
        MonthInput
    },
    data() {
        return {
            headers: [
                {
                    text: "Salary",
                    value: "salary",
                    align: "center"
                },
                {
                    text: "Overprice",
                    value: "overPrice",
                    align: "center"
                },
                {
                    text: "Extra Hour",
                    value: "extraHour",
                    align: "center"
                },
                {
                    text: "Total Salary",
                    value: "total",
                    align: "center"
                },
                {
                    text: "Paychecks",
                    value: "paychecks",
                    align: "center"
                },
                {
                    text: "Approved Salary",
                    value: "approved_salary",
                    align: "center"
                },
                {
                    text: "Remaining",
                    value: "remaining",
                    align: "center"
                },
            ],
            date: ''
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useWalletStore, ["walletBase"]),
        ...mapWritableState(useWalletStore, ["show", "walletModel"]),
    },
    methods: {
        ...mapActions(useWalletStore, ['fetchWallet']),
        onCancel() {
            this.show = false
            this.walletModel = null
        },
        filterByDate(){
            // this.walletModel = null
            const parts = this.date.split("-")
            this.date = `${parts[2]}-${parts[1]}-${parts[0]}`
            this.fetchWallet(this.date)
        }
    },
    mounted() {
    }
};
</script>
