<template>
    <div>
        <v-row>
            <v-col cols="4">
                <v-text-field
                    v-model="roleBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="roleList"
            :search="roleBase.search"
            class="my-table"
            :loading="roleBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="roleBase.dialogDeleteModel"
                    :loading="roleBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="editItem(item)" text icon color="green" v-if="item.id != 1">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" text icon color="error" v-if="notConstant(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { useRoleStore } from "../store/RoleStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Role Name",
                    value: "name",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ]
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useRoleStore, ['roleBase', 'roleList']),
    },

    methods: {
        ...mapActions(useRoleStore, ['fetchAllRole', 'setRoleModel', 'deleteRole']),

        deleteItem(item) {
            this.roleBase.editedIndex = this.roleList.indexOf(item)
            this.roleBase.delete = item
            this.roleBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('role_id', this.roleBase.delete.id)
            this.roleBase.loading = true
            this.deleteRole(formData)
        },

        editItem(item) {
            this.roleBase.ifEdit = true
            this.$router.push(`/roles/edit/${item.id}`)
        },
        notConstant(item){
            return (item.id != 1 && item.id != 2 && item.id != 3 && item.id != 5) ? true : false
        }
    },
    mounted() {
        this.fetchAllRole()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>