<template>
    <!-- Logo -->
    <div class="header-left" v-if="route">
        <router-link :to="route" class="logo">
            <img src="@/assets/img/logo-unicorn.png" width="100" height="100" alt />
        </router-link>
        <router-link :to="route" class="logo2">
            <img src="@/assets/img/logo-unicorn.png" width="100" height="100" alt />
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {
            role: ''
        }
    },
    computed: {
        route() {
            if (this.role == 1)
                return '/'
            else if (this.role == 2)
                return '/employee-dashboard'
            else if (this.role == 5)
                return '/manager-dashboard'
        }
    },
    mounted() {
        this.role = JSON.parse(localStorage.getItem('user')).role
        // console.log(this.role)
    }

}
</script>

<style>
</style>