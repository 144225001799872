<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="attendanceBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
                <month-input :date.sync="date" label="Filter by date" @ok="filterByDate" />
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="monthly"
            :search="attendanceBase.search"
            class="my-table"
            :loading="attendanceBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:[`item.dates`]="{ item, header }">
                <div v-for="(date, index) in item.dates" :key="index">
                    <div v-if="getDay(date) == header.text"><v-icon color="green">mdi-check</v-icon></div>
                </div>
                <div v-if="!isAnyMatch(item.dates, header.text)"><v-icon color="red">mdi-close</v-icon></div>
            </template>
            <!-- <template v-slot:[`item.employee.username`]="{ item }">
                {{item.employee.username}}
            </template> -->
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useAttendanceStore } from "../store/AttendanceStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import MonthInput from "@/components/inputs/MonthInput.vue"

export default {
    components: { ConfirmDeleteDialog, MonthInput },

    data() {
        return {
            headers: [
                {
                    text: "Employee",
                    value: "employee_name",
                    align: "center",
                    width: "150",
                },
                {
                    text: "1",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "2",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "3",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "4",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "5",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "6",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "7",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "8",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "9",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "10",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "11",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "12",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "13",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "14",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "15",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "16",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "17",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "18",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "19",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "20",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "21",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "22",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "23",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "24",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "25",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "26",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "27",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "28",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "29",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "30",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
                {
                    text: "31",
                    value: "dates",
                    align: "center",
                    sortable: false
                },
            ],
            date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useAttendanceStore, ['attendanceBase', 'monthly']),
    },

    methods: {
        ...mapActions(useAttendanceStore, ['fetchMonthlyAttendance']),
        getDay(item) {
            const date = new Date(item);
            const day = date.getDate();
            return day
        },
        isAnyMatch(dates, columnHeader) {
            return dates.some(date => this.getDay(date) == columnHeader);
        },
        filterByDate(){
            this.fetchMonthlyAttendance(this.date.split("-").reverse().join("-"))
        }
    },
    mounted() {
        const date = `${new Date().getMonth() + 1}-${new Date().getFullYear()}`
        this.fetchMonthlyAttendance(date)
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>