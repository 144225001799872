import Client from "@/clients/AxiosClient";

export default {
    add(params) {
        return Client.post("/task_create", params);
    },

    getAll() {
        return Client.get("/tasks");
    },

    getTaskEpisode(id){
        return Client.get(`/episode?episode_id=${id}`);
    },

    getById(id) {
        return Client.get(`/task?task_id=${id}`);
    },

    edit(params) {
        return Client.post(`/task_edit`, params);
    },

    delete(formData) {
        return Client.post(`/task_delete`, formData);
    },
    
    addFile(formData){
        return Client.post(`/add_task_file`, formData);
    },

    filesTask(id){
        return Client.get(`/task_files?task_id=${id}`);
    },

    getEmployeeTask(){
        return Client.get('employee_tasks')
    },

    getEmployeeTaskDetails(id){
        return Client.get(`/employee_task_details?id=${id}`);
    }

};