<template>
    <div class="row dashboard">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget" @click="pushRoute('/hr/departments')">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-user-alt"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">HR</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget" @click="pushRoute('/translation/projects')">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fa fa-language"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Translation</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget" @click="pushRoute('/accounting/account')">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="far fa-money-bill-alt"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Accounting</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-microphone-alt"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Dubbing</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-film"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Montage</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-video"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Filming</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-file-audio"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Script</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-user-tie"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Customers</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
export default {
    data() {
        return {
        };
    },
    components: {},
    methods: {
        pushRoute(name) {
            this.$router.push(`${name}`)
        }
    }
};
</script>
<style>
.dashboard .dash-widget:hover {
    cursor: pointer;
    box-shadow: 0 0 10px #80808059;
    transition: all 0.3s;
}

.dash-widget {
    transition: all 0.3s;
}

/* a{
  color: #1f1f1f !important
} */
</style>
