import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/episode_create", formData);
    },

    getAll() {
        return Client.get("/episodes");
    },

    getEpisodeProject(id){
        return Client.get(`/project?project_id=${id}`);
    },

    getById(id) {
        return Client.get(`/episode?episode_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/episode_edit`, formData);
    },

    delete(formData) {
        return Client.post(`/episode_delete`, formData);
    },

};