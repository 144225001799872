import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('all-sidebar',{directives:[{name:"role",rawName:"v-role",value:('employee'),expression:"'employee'"}]}),_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"content container-fluid"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"row align-items-center",staticStyle:{"justify-content":"space-between"}},[_vm._m(0),_c('div',{staticClass:"col-lg-3",staticStyle:{"text-align":"end"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.employeeDashboardBase.loading},on:{"click":_vm.submitCheckOut}},[_c(VIcon,{staticStyle:{"margin-left":"10px"}},[_vm._v("mdi-door")]),_vm._v(" Check Out ")],1)],1)])]),_c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"far fa-money-bill-alt"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',[_vm._v(_vm._s(_vm.total))]),_c('h6',{staticClass:"client-card-title"},[_vm._v("Price")])])])])]),_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"card dash-widget"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"dash-widget-icon"},[_c('i',{staticClass:"far fa-clock"})]),_c('div',{staticClass:"dash-widget-info",staticStyle:{"justify-content":"center"}},[_c('h4',[_vm._v(_vm._s(_vm.clock_out))]),_c('h6',{staticClass:"client-card-title"},[_vm._v("Clock")])])])])])],1)],1),_c('div',[(!_vm.employeeDashboardBase.errorFetch)?_c('div',[_c('employeeDashboard-table')],1):_c('div',[_c('no-internet',{attrs:{"loading":_vm.employeeDashboardBase.loadingFetch},on:{"retry":_vm.onRetry}})],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('h3',{staticClass:"page-title"},[_vm._v("Dashboard")])])
}]

export { render, staticRenderFns }