<template>
    <form-dialog
        :title="!leaveBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="750px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="leave-form">
                <v-row>
                    <v-col cols="12" sm="6" v-if="role != 2">
                        <select-input
                            :model.sync="leaveModel.employee_id"
                            :items="employeeList"
                            label="Employee Name"
                            item_text="username"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="leaveModel.department_id"
                            :items="departmentList"
                            label="Department"
                            item_text="name"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <date-input
                            :date.sync="leaveModel.start_date"
                            label="Start Date"
                            :allowedDates="allowedDatesStart"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <date-input
                            :date.sync="leaveModel.end_date"
                            label="End Date"
                            :allowedDates="allowedDatesEnd"
                            :disabled="ifEndDisabled"
                        />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="leaveModel.reason" label="Reason" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="leaveBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useLeaveStore } from "../store/LeaveStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import { useDepartmentStore } from "@/modules/hr/department/store/DepartmentStore"
import { useEmployeeStore } from "@/modules/hr/employee/store/EmployeeStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        TextareaInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Leave",
            titleEdit: "Edit Leave",
            role: '',
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useLeaveStore, ["leaveBase", "leaveModel"]),
        ...mapWritableState(useLeaveStore, ["show"]),
        ...mapState(useDepartmentStore, ['departmentList']),
        ...mapState(useEmployeeStore, ['employeeList']),
        ifEndDisabled() {
            return this.leaveModel.start_date == ''
        },
    },
    methods: {
        ...mapActions(useLeaveStore, ['addLeave', 'editLeave', 'clearLeaveModel']),
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        ...mapActions(useEmployeeStore, ['fetchAllEmployee']),
        allowedDatesStart: val => val > new Date().toJSON().slice(0, 10),
        allowedDatesEnd(val) {
            return val >= this.leaveModel.start_date
        },

        submit() {
            if (this.$refs.form.validate()) {
                this.leaveBase.ifEdit
                    ? this.editLeave(this.leaveModel.id, this.createFormData())
                    : this.addLeave(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('employee_id', this.leaveModel.employee_id)
            formData.append('company_id', this.leaveModel.department_id)
            formData.append('start_date', this.leaveModel.start_date)
            formData.append('end_date', this.leaveModel.end_date)
            formData.append('reason', this.leaveModel.reason)
            return formData
        },
        onCancel() {
            this.show = false
            this.leaveBase.ifEdit = false
            this.clearLeaveModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.fetchAllDepartment()
        this.role = JSON.parse(localStorage.getItem('user')).role
        if(this.role != 2)
            this.fetchAllEmployee()
        else 
            this.leaveModel.employee_id = JSON.parse(localStorage.getItem('user')).id

    }
};
</script>

<style>
.leave-form .col-12 {
    padding: 0px 15px !important;
}
</style>