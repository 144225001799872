<template>
    <div>
        <translation-layout :show.sync="show" title="Project" permission='project_add'>
            <template v-slot:cards v-if="finishLoad()">
                <v-row>
                    <v-col cols="12" sm="4">
                        <div class="card dash-widget">
                            <div class="card-body">
                                <span class="dash-widget-icon">
                                    <i class="fa fa-spinner"></i>
                                </span>
                                <div class="dash-widget-info" style="justify-content: center">
                                    <h2>{{in_progress}}</h2>
                                    <h4
                                        style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f"
                                    >In Progress</h4>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div class="card dash-widget" >
                            <div class="card-body">
                                <span class="dash-widget-icon">
                                    <i class="fa fa-check"></i>
                                </span>
                                <div class="dash-widget-info" style="justify-content: center">
                                    <h2>{{completed}}</h2>
                                    <h4
                                        style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f"
                                    >Completed</h4>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:dataTable>
                <div v-if="finishLoad()">
                    <project-table />
                </div>
                <div v-if="projectBase.loadingFetch">
                    <loader />
                </div>
                <div v-if="error()">
                    <no-internet @retry="onRetry" :loading="projectBase.loadingFetch"></no-internet>
                </div>
            </template>
        </translation-layout>
        <project-form />
    </div>
</template>

<script>
import { useProjectStore } from "../store/ProjectStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import TranslationLayout from "@/modules/translation/core/layouts/TranslationLayout.vue"
import ProjectTable from "../components/ProjectTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import ProjectForm from "../components/ProjectForm.vue"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        ProjectTable,
        NoInternet,
        ProjectForm,
        TranslationLayout,
        Loader
    },
    data: () => ({
        cardTitle: "Project List",
        btnTitle: "Add Project",
    }),
    computed: {
        ...mapState(useProjectStore, ['projectBase', 'in_progress', 'completed']),
        ...mapWritableState(useProjectStore, ['show'])
    },
    methods: {
        ...mapActions(useProjectStore, ['fetchAllProject']),
        onRetry() {
            this.fetchAllProject()
        },
        finishLoad() {
            return (!this.projectBase.loadingFetch && !this.projectBase.errorFetch) ? true : false
        },
        error() {
            return (this.projectBase.errorFetch && !this.projectBase.loadingFetch) ? true : false
        }
    },
    mounted(){
        this.fetchAllProject()
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
