import { User } from "@/modules/auth/models/UserModel";
import { defineStore } from "pinia";
import authRepository from "../services/AuthRepository";
import router from "@/router"

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    user:
      localStorage.getItem("user") === null ? null : JSON.parse(localStorage.getItem("user")),
    loading: false,
    error: false,
    message: '',
    permissions: localStorage.getItem("permissions") === null ? [] : JSON.parse(localStorage.getItem("permissions")),
  }),
  getters: {
    isAuthenticated() {
      return this.user !== null;
    },
    getUser() {
      return this.user !== null ? this.user : null;
    },
    getId() {
      return this.user !== null ? this.user.id : null;
    },
    getToken() {
      return this.user !== null ? this.user.token : null;
    },
  },
  actions: {
    getCom_() {
      authRepository.getCom().then((res) => {
      })
    },
    loginLocally(data) {
      // console.log(data.role.permissions)
      this.user = new User(data.user.id, data.user.email, data.user.username, data.user.role_users_id, data.token)
      localStorage.setItem("user", JSON.stringify(this.user))
      data.role.permissions.forEach(item => {
        this.permissions.push(item.name)
      })
      if (data.user.role_users_id == 1)
        this.permissions.push('admin_dashboard')
      else if (data.user.role_users_id == 2)
        this.permissions.push('employee_dashboard')
      else if (data.user.role_users_id == 5)
        this.permissions.push('manager_dashboard')
      localStorage.setItem("permissions", JSON.stringify(this.permissions))
    },
    login(formData) {
      this.loading = true
      authRepository.login(formData)
        .then((res) => {
          this.loading = false
          this.loginLocally(res.data.list[0]);
          if (res.data.list[0].user.role_users_id == 1)
            router.push("/")
          else if (res.data.list[0].user.role_users_id == 2)
            router.push("/employee-dashboard")
          else if (res.data.list[0].user.role_users_id == 5)
            router.push("/manager-dashboard")
        })
    },
    logoutLocally() {
      this.user = null
      this.permissions = []
      localStorage.clear()
      router.push("/login")
    },
    logout() {
      authRepository
        .logout()
        .then((res) => {
          this.logoutLocally()
        })
    }
  },
});
