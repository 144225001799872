import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.changeStatusModal),callback:function ($$v) {_vm.changeStatusModal=$$v},expression:"changeStatusModal"}},[_c(VCard,[_c(VCardTitle,{staticStyle:{"justify-content":"center","margin-bottom":"20px"}},[_c('span',{staticClass:"text-h5 color-primary"},[_vm._v("Change Status")]),_c(VBtn,{staticClass:"btn-close",on:{"click":_vm.cancel}},[_c(VIcon,{staticStyle:{"font-size":"18px"}},[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,{staticStyle:{"padding-top":"20px !important"}},[_c('select-input',{attrs:{"items":_vm.statusList,"item_text":"title","item_value":"value","model":_vm.status,"label":"Status"},on:{"update:model":function($event){_vm.status=$event}}})],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-content-center",staticStyle:{"padding-bottom":"20px"}},[_c('submit-btn',{attrs:{"loading":_vm.leaveBase.loading},on:{"click":_vm.change}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }