<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="accountBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="accountList"
            :search="accountBase.search"
            class="my-table"
            :loading="accountBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            
            <template v-slot:[`item.company.name`]="{ item }">
                <a @click="showDetails(item)" class="account-name" v-if="item.company.name == 'Translation'">
                    {{ item.company.name }}
                </a>
                <div v-else>
                    {{ item.company.name }}
                </div>
            </template>
            
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useAccountStore } from "../store/AccountStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Account",
                    value: "company.name",
                    align: "center"
                },
                {
                    text: "Balance",
                    value: "initial_balance",
                    align: "center"
                },
            ],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useAccountStore, ['accountBase', 'accountList']),
        ...mapWritableState(useAccountStore, ['show'])
    },

    methods: {
        ...mapActions(useAccountStore, ['fetchAllAccount',]),
        showDetails(item){
            this.$router.push(`/accounting/account/${item.id}`)
        }

    },
    mounted() {
        this.fetchAllAccount()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.account-name{
    cursor: pointer;
    color: #000 !important;
    transition: all .2s;
}
.account-name:hover{
    cursor: pointer;
    color: var(--v-primary-base) !important;
    text-decoration: underline !important;
    transition: all .2s;
}
</style>