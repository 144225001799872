<template>
    <div>
        <accounting-layout :show.sync="show" title="Account" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!accountBase.errorFetch">
                    <account-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="accountBase.loadingFetch"></no-internet>
                </div>
            </template>
        </accounting-layout>
    </div>
</template>

<script>
import { useAccountStore } from "../store/AccountStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import AccountingLayout from "@/modules/accounting/core/layouts/AccountingLayout.vue"
import AccountTable from "../components/AccountTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"

export default {
    components: {
        AccountTable,
        NoInternet,
        AccountingLayout,
    },
    data: () => ({
        cardTitle: "Account List",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                accountingef: '/',
            },
            {
                text: 'Account',
                disabled: false,
                accountingef: '/account',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                accountingef: '/',
            },
            {
                text: ' تواصل معنا',
                disabled: false,
                accountingef: '/account',
            }
        ],
    }),
    computed: {
        ...mapState(useAccountStore, ['accountBase']),
        ...mapWritableState(useAccountStore, ['show'])
    },
    methods: {
        ...mapActions(useAccountStore, ['fetchAllAccount']),
        onRetry() {
            this.fetchAllAccount()
        }
    },
    mounted(){
        this.fetchAllAccount()
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
