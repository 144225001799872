<template>
    <div id="no-permission" class="no-permission">
        <div class="d-flex align-items-center justify-content-center" style="margin: 50px 0px;flex-direction: column">
            <v-img src="@/assets/img/not-found2.svg" style="min-width:40%"></v-img>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>