<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="taskBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="employeeTaskList"
            :search="taskBase.search"
            class="my-table"
            :loading="taskBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <employee-task-files-dialog />
            </template>
            <template v-slot:[`item.projectdocument.episode_file`]="{ item }">
                    <a v-if="item.projectdocument.episode_file != null"
                        :href="item.projectdocument.episode_file"
                        target="_blank"
                        :download="item.projectdocument.episode_file"
                    >{{ getFileName(item.projectdocument.episode_file) }}</a>
            </template>
            <template v-slot:[`item.projectdocument.url`]="{ item }">
                    <a v-if="item.projectdocument.url != null"
                        :href="item.projectdocument.url"
                        target="_blank"
                        :download="item.projectdocument.url"
                    >{{ getFileName(item.projectdocument.url) }}</a>
            </template>
            <template v-slot:[`item.projectdocument.syn`]="{ item }">
                <div v-if="item.projectdocument.syn == 0">
                    No
                </div>
                <div v-else>
                    Yes
                </div>
            </template>
            <template v-slot:[`item.projectdocument.script`]="{ item }">
                <div v-if="item.projectdocument.script == 0">
                    No
                </div>
                <div v-else>
                    Yes
                </div>
            </template>
            <template v-slot:[`item.time`]="{ item }">
                <div>{{item.time.slice(0, 5)}}</div>
            </template>
            <template v-slot:[`item.management`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="displayItem(item)" text icon color="indigo" v-can="'task_details'">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn @click="displayFile(item)" text icon color="deep-purple lighten-1">
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { useTaskStore } from "@/modules/translation/task/store/TaskStore"
import { mapState, mapActions, mapWritableState  } from "pinia"
import { useLangStore } from "@/store/LangStore"
import EmployeeTaskFilesDialog from './EmployeeTaskFilesDialog.vue'

export default {
    components:{ EmployeeTaskFilesDialog },
    data() {
        return {
            headers: [
                {
                    text: "Episode Name",
                    value: "title",
                    align: "center",
                    width: "170",
                },
                {
                    text: "Project",
                    value: "project.title",
                    align: "center"
                },
                {
                    text: "Channel",
                    value: "project.channel",
                    align: "center"
                },
                {
                    text: "Episode Duration",
                    value: "projectdocument.duration",
                    align: "center"
                },
                {
                    text: "Episode File",
                    value: "projectdocument.episode_file",
                    align: "center"
                },
                {
                    text: "Episode Video",
                    value: "projectdocument.url",
                    align: "center"
                },
                {
                    text: "Episode Sync",
                    value: "projectdocument.syn",
                    align: "center"
                },
                {
                    text: "Episode Script",
                    value: "projectdocument.script",
                    align: "center"
                },
                {
                    text: "Finish date",
                    value: "deadline",
                    align: "center",
                    width: "130",
                },
                {
                    text: "Time",
                    value: "time",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "management",
                    align: "center",
                    width: "120",
                    sortable: false,
                },
            ],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useTaskStore, ['taskBase', 'employeeTaskList']),
        ...mapWritableState(useTaskStore, ['filesTaskShow', 'filesList'])
    },

    methods: {
        ...mapActions(useTaskStore, ['getEmployeeTask']),
        getFileName(url) {
            const parts = url.split("/");
            return parts[parts.length - 1];
        },
        displayItem(item){
            this.$router.push(`/employee-task/task/${item.id}`)
        },
        displayFile(item){
            this.filesList = item.files
            this.filesTaskShow = true
        }
    },
    mounted() {
        this.getEmployeeTask()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>