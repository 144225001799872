<template>
    <v-dialog v-model="changeStatusModal" max-width="500px" >
        <v-card>
            <v-card-title style=" justify-content: center;margin-bottom: 20px">
                <span class="text-h5 color-primary">Change Status</span>
                <v-btn class="btn-close" @click="cancel" ><v-icon style="font-size: 18px;">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text style="padding-top: 20px !important;">
                <select-input :items="statusList" item_text="title" item_value="value" :model.sync="status" label="Status" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-content-center" style="padding-bottom: 20px;">
                <submit-btn @click="change" :loading="paycheckBase.loading" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectInput from '@/components/inputs/SelectInput.vue'
import { usePaycheckStore } from "../store/PaycheckStore"
import { mapState, mapActions } from "pinia"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'

export default {
    name: "DetailsDialog",
    components: {
        SelectInput,
        SubmitBtn
    },
    data() {
        return {
            newStatus: '',
            statusList: [
                {
                    title: "Approved",
                    value: "approved",
                },
                {
                    title: "Pending",
                    value: "pending",
                },
                {
                    title: "Rejected",
                    value: "rejected",
                },
            ]
        };
    },
    props: {
        changeStatus: {
            type: Boolean,
            required: true
        },
        item: {
            required: true,
            type: Object
        }
    },

    computed: {
        ...mapState(usePaycheckStore, ["paycheckBase"]),
        changeStatusModal: {
            get() {
                return this.changeStatus
            },
            set(value) {
                this.$emit('update:changeStatus', value)
            }
        },
        status:{
            get() {
                return this.item.status
            },
            set(value) {
                this.newStatus = value
            }
        }
    },
    methods: {
        ...mapActions(usePaycheckStore, ['changeStatusPaycheck']),
        change() {
            const formData = new FormData()
            formData.append("id", this.item.id)
            formData.append("status", this.newStatus)
            this.changeStatusPaycheck(formData)
            this.changeStatusModal = false
        },
        cancel(){
            this.changeStatusModal = false
        }
    },
    mounted(){
        
    }
}
</script>

<style>
</style>