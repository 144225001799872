import Role from "@/modules/general/role/pages/RoleListView.vue"
import AddRole from "@/modules/general/role/pages/RoleFormView.vue"
import EditRole from "@/modules/general/role/pages/RoleFormView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
export default {
    component: BaseFormPageWrapper,
    path: "/roles",
    children:[
        {
            path: "/roles",
            name: "roles",
            component: Role,
            meta: { title: "Role", permission: "group_permission" }
        },
        {
            path: "/roles/add",
            name: "roles-add",
            component: AddRole,
            meta: { title: "Role", permission: "group_permission" }
        },
        {
            path: "/roles/edit/:id",
            name: "roles-edit",
            component: EditRole,
            meta: { title: "Role", permission: "group_permission" }
        },
    ]
};
