<template>
    <form-dialog title="Setting" :persistent="true" :show.sync="show" @cancel="cancel">
        <template v-slot:inputs>
            <v-form ref="form" class="setting-form">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            type="email"
                            prepend-inner-icon="mdi-email"
                            outlined
                            v-model="settingModel.email"
                            :rules="[requiredRule, emailRule]"
                            label="Email"
                            validate-on-blur
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <number-input :number.sync="settingModel.phone" label="Phone" />
                    </v-col>
                    <v-col cols="12">
                        <text-input :text.sync="settingModel.address" label="Address" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <cancel-btn @click="cancel" />
            <submit-btn @click="submit" :loading="settingBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useSettingStore } from "../store/SettingStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import NumberInput from "@/components/inputs/NumberInput.vue"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useSettingStore, ["settingBase", "settingModel"]),
        ...mapWritableState(useSettingStore, ["show"]),
    },
    methods: {
        ...mapActions(useSettingStore, ['fetchSetting', 'editSetting']),
        submit() {
            if (this.$refs.form.validate()) {
                this.editSetting(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('email', this.settingModel.email)
            formData.append('phone', this.settingModel.phone)
            formData.append('address', this.settingModel.address)
            return formData
        },
        cancel() {
            this.show = false
            this.$refs.form.resetValidation()
        }
    },
    mounted(){
        this.fetchSetting()
    }
};
</script>
<style>
.setting-form .col-12{
    padding: 0px 15px;
}
</style>
