import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import TaskService from "../services/TaskService";
import { useProjectStore } from "../../project/store/ProjectStore";
import router from "@/router";
export const useTaskStore = defineStore("taskStore", {
    state: () => ({
        taskBase: new BaseState(),
        taskModel: {
            id: '',
            project_id: '',
            episode: {
                id: '',
                title: '',
                duration: '',
                duration_trans: '',
                duration_syn: '',
                duration_audit: '',
            },
            end_date: '',
            time: '',
            translation: [],
            sync: [],
            audit: [],
            poly: [],
            translation_employees: [],
            sync_employees: [],
            audit_employees: [],
            poly_employees: [],
            description: '',
        },
        taskList: [],
        taskEpisodeList: [],
        showAdd: false,
        showEdit: false,
        addNewTask: '',
        taskDetails: {},
        fileModel: {
            attachment: null,
            description: '',
            id: '',
        },
        taskDetailsShow: false,
        show: false,

        // used in edit task
        finishTranEmp: [],
        selectedItemTran: [],

        finishSyncEmp: [],
        selectedItemSync: [],

        finishAuditEmp: [],
        selectedItemAudit: [],
        // 

        filesList: [],
        filesTaskShow: false,

        employeeTaskList: [],
        employeeTaskDetails: [],
    }),
    getters: {
    },
    actions: {
        clearTaskModel() {
            this.taskModel.id = ''
            this.taskModel.project_id = ''
            this.taskModel.episode = {
                id: '',
                title: '',
                duration: '',
                duration_trans: '',
                duration_syn: '',
                duration_audit: '',
            }
            this.taskModel.end_date = ''
            this.taskModel.time = ''
            this.taskModel.translation = []
            this.taskModel.sync = []
            this.taskModel.audit = []
            this.taskModel.poly = []
            this.taskModel.translation_employees = []
            this.taskModel.sync_employees = []
            this.taskModel.audit_employees = []
            this.taskModel.poly_employees = []
            this.taskModel.description = ''
        },
        clearTaskFileModel() {
            this.fileModel.attachment = null
            this.fileModel.description = ''
            this.fileModel.id = ''
        },
        setTaskModel(item) {
            this.taskModel.id = item.id
            this.taskModel.episode.title = this.taskDetails.title
            this.taskModel.episode.duration = 10
            this.taskModel.time = this.taskDetails.start_date
            this.taskModel.project_id = item.project_id
            this.taskModel.end_date = item.end_date
            this.taskModel.time = item.time
            this.taskModel.description = this.taskDetails.description

            // translation
            this.taskModel.translation_employees = this.taskDetails.employee_task
                .filter(item => item.task_type === "trans")
                .map(item => ({
                    employee_id: item.employee_id,
                    from: item.minutes_from,
                    to: item.minutes_to,
                    status: item.status
                }));
            this.taskModel.translation = this.taskDetails.employee_task
                .filter(item => item.task_type === "trans")
                .map(item => item.employee_id);
            this.selectedItemTran = this.taskDetails.employee_task
                .filter(item => item.task_type === "trans")
                .map(item => item.employee_id);
            this.finishTranEmp = this.taskDetails.employee_task
                .filter(item => item.task_type === "trans" && item.status === 1)
                .map(item => item.employee_id);


            // sync
            this.taskModel.sync_employees = this.taskDetails.employee_task
                .filter(item => item.task_type === "syn")
                .map(item => ({
                    employee_id: item.employee_id,
                    from: item.minutes_from,
                    to: item.minutes_to,
                    status: item.status
                }));
            this.taskModel.sync = this.taskDetails.employee_task
                .filter(item => item.task_type === "syn")
                .map(item => item.employee_id);
            this.selectedItemSync = this.taskDetails.employee_task
                .filter(item => item.task_type === "syn")
                .map(item => item.employee_id);
            this.finishSyncEmp = this.taskDetails.employee_task
                .filter(item => item.task_type === "syn" && item.status === 1)
                .map(item => item.employee_id);


            // audit
            this.taskModel.audit_employees = this.taskDetails.employee_task
                .filter(item => item.task_type === "audit")
                .map(item => ({
                    employee_id: item.employee_id,
                    from: item.minutes_from,
                    to: item.minutes_to,
                    status: item.status
                }));
            this.taskModel.audit = this.taskDetails.employee_task
                .filter(item => item.task_type === "audit")
                .map(item => item.employee_id);
            this.selectedItemAudit = this.taskDetails.employee_task
                .filter(item => item.task_type === "audit")
                .map(item => item.employee_id);
            this.finishAuditEmp = this.taskDetails.employee_task
                .filter(item => item.task_type === "audit" && item.status === 1)
                .map(item => item.employee_id);

            // poly
            this.taskModel.poly_employees = this.taskDetails.employee_task
                .filter(item => item.task_type === "poly")
                .map(item => ({
                    employee_id: item.employee_id,
                }));
            this.taskModel.poly = this.taskDetails.employee_task
                .filter(item => item.task_type === "poly")
                .map(item => item.employee_id);

        },
        addTask(params) {
            this.taskBase.loading = true;
            TaskService.add(params).then((res) => {
                this.taskBase.loading = false
                this.showAdd = false
                const projectStore = useProjectStore();
                projectStore.getProjectById(this.taskModel.project_id);
                this.clearTaskModel()
            })
                .catch((err) => {
                    this.taskBase.loading = false
                    this.taskBase.error = true
                });
        },
        addFile(id, formData) {
            this.taskBase.loading = true;
            TaskService.addFile(formData).then((res) => {
                this.taskBase.loading = false
                this.show = false
                this.fileModel.id = ''
                this.fileModel.attachment = null
                this.fileModel.description = ''
                const routeName = router.history.current.name
                if (routeName == 'employee-task-details')
                    this.getEmployeeTaskDetails(id)
                else
                    this.getTaskById(id)
            })
                .catch((err) => {
                    this.taskBase.loading = false
                    this.taskBase.error = true
                });
        },
        fetchAllTask() {
            this.taskBase.loadingFetch = true
            TaskService.getAll().then((res) => {
                this.taskList = res.data.list
                this.taskBase.loadingFetch = false
                this.taskBase.errorFetch = false
            })
                .catch((err) => {
                    this.taskBase.loadingFetch = false
                    this.taskBase.errorFetch = true
                });
        },
        fetchTaskEpisode(episode_id) {
            this.taskBase.loadingFetch = true
            TaskService.getTaskEpisode(episode_id).then((res) => {
                this.taskEpisodeList = res.data.list.task
                // this.addNewTask = res.data.list.add_new_task
                this.taskModel.title = res.data.list.episode.title

                this.taskBase.loadingFetch = false
                this.taskBase.errorFetch = false
            })
                .catch((err) => {
                    this.taskBase.loadingFetch = false
                    this.taskBase.errorFetch = true
                });
        },
        deleteTask(formData) {
            TaskService.delete(formData).then((res) => {
                this.taskBase.loading = false
                this.taskEpisodeList.splice(this.taskBase.editedIndex, 1)
                this.taskBase.dialogDeleteModel = false
                const projectStore = useProjectStore();
                projectStore.getProjectById(this.taskModel.project_id);
            })
                .catch((err) => {
                    this.taskBase.loading = false
                    this.taskBase.error = true
                })
        },
        getTaskById(id) {
            this.taskBase.loadingFetch = true
            TaskService.getById(id).then((res) => {
                this.taskBase.loadingFetch = false
                this.taskDetails = res.data.list
            })
                .catch((err) => {
                    this.taskBase.loadingFetch = false
                    this.taskBase.error = true
                })
        },
        editTask(params) {
            this.taskBase.loading = true
            TaskService.edit(params).then((res) => {
                this.taskBase.loading = false
                this.taskBase.ifEdit = false
                this.showEdit = false
                const projectStore = useProjectStore();
                projectStore.getProjectById(this.taskModel.project_id);
                this.clearTaskModel()
            })
                .catch((err) => {
                    this.taskBase.loading = false
                    this.taskBase.error = true
                });
        },
        filesTask(id) {
            this.taskBase.loading = true
            TaskService.filesTask(id).then((res) => {
                this.taskBase.loading = false
                this.filesList = res.data.list.filter(item => item.status == 1)
            })
                .catch((err) => {
                    this.taskBase.loading = false
                    this.taskBase.error = true
                })
        },
        getEmployeeTask() {
            this.taskBase.loadingFetch = true
            TaskService.getEmployeeTask().then((res) => {
                this.employeeTaskList = res.data.list
                this.taskBase.loadingFetch = false
                this.taskBase.errorFetch = false
            })
                .catch((err) => {
                    this.taskBase.loadingFetch = false
                    this.taskBase.errorFetch = true
                });
        },
        getEmployeeTaskDetails(id) {
            this.taskBase.loadingFetch = true
            TaskService.getEmployeeTaskDetails(id).then((res) => {
                this.taskBase.loadingFetch = false
                this.employeeTaskDetails = res.data.list
            })
                .catch((err) => {
                    this.taskBase.loadingFetch = false
                    this.taskBase.error = true
                })
        }
    },
});
