<template>
    <div class="departments">
        <div class="main-wrapper">
            <translation-sidebar v-role="'admin'" />
            <all-sidebar v-role="'employee'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Project Details</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/translation">Translation</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/translation/projects">Projects</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" v-if="finishLoad()">{{projectDetails.title}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="finishLoad()">
                            <project-details :projectDetails="projectDetails" />
                        </div>
                        <div v-if="inLoad()">
                            <loader/>
                        </div>
                        <div v-if="error()">
                            <no-internet @retry="onRetry" :loading="projectBase.loadingFetch"></no-internet>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TranslationSidebar from '@/modules/translation/core/components/TranslationSidebar.vue'
import { useProjectStore } from '../store/ProjectStore';
import { mapActions, mapState } from "pinia"
import NoInternet from "@/components/main/NoInternet.vue"
import ProjectDetails from '../components/ProjectDetails.vue'
import Loader from "@/components/main/Loader.vue"
import AllSidebar from "@/components/main/AllSidebar.vue"
export default {
    components: {
        TranslationSidebar,
        NoInternet,
        ProjectDetails,
        Loader,
        AllSidebar
    },

    computed: {
        ...mapState(useProjectStore, ['projectBase', 'projectDetails']),
    },
    methods: {
        ...mapActions(useProjectStore, ['getProjectById']),
        onRetry() {
            this.getProjectById(this.$route.params.project_id)
        },
        finishLoad() {
            return (!this.projectBase.loadingFetch && this.projectDetails != null) ? true : false
        },
        inLoad() {
            return (this.projectBase.loadingFetch) ? true : false
        },
        error() {
            return (this.projectBase.errorFetch && !this.projectBase.loadingFetch) ? true : false
        }
    },
    mounted() {
        this.projectBase.loadingFetch = true
        this.getProjectById(this.$route.params.project_id)
    }

};

</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
