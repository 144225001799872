<template>
    <div>
        <translation-layout :show.sync="show" title="Task">
            <template v-slot:dataTable>
                <div v-if="!taskBase.errorFetch">
                    <task-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="taskBase.loadingFetch"></no-internet>
                </div>
            </template>
        </translation-layout>
        <task-form />
    </div>
</template>

<script>
import { useTaskStore } from "../store/TaskStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import TranslationLayout from "@/modules/translation/core/layouts/TranslationLayout.vue"
import TaskTable from "../components/TaskTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import TaskForm from "../components/TaskForm.vue"

export default {
    components: {
        TaskTable,
        NoInternet,
        TaskForm,
        TranslationLayout,
    },
    data: () => ({
        cardTitle: "Task List",
        btnTitle: "Add Task",
    }),
    computed: {
        ...mapState(useTaskStore, ['taskBase']),
        ...mapWritableState(useTaskStore, ['show'])
    },
    methods: {
        ...mapActions(useTaskStore, ['fetchAllTask']),
        onRetry() {
            this.fetchAllTask()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
