<template>
    <div>
        <translation-layout :show.sync="show" title="Task" :arrayOfTitle="arrayOfTitle" :ifArray="true" >
            <template v-slot:dataTable>
                <div v-if="!taskBase.errorFetch">
                    <task-episode-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="taskBase.loadingFetch"></no-internet>
                </div>
            </template>
        </translation-layout>
        <task-form />
    </div>
</template>

<script>
import { useTaskStore } from "../store/TaskStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import TranslationLayout from "@/modules/translation/core/layouts/TranslationLayout.vue"
import TaskEpisodeTable from "../components/TaskEpisodeTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import TaskForm from "../components/TaskForm.vue"

export default {
    components: {
        TaskEpisodeTable,
        NoInternet,
        TaskForm,
        TranslationLayout,
    },
    data: () => ({
        cardTitle: "Task List",
        btnTitle: "Add Task",

        arrayOfTitle: ['Projects','Episodes', 'Tasks']
    }),
    computed: {
        ...mapState(useTaskStore, ['taskBase', 'addNewTask']),
        ...mapWritableState(useTaskStore, ['show'])
    },
    methods: {
        ...mapActions(useTaskStore, ['fetchTaskEpisode']),
        onRetry() {
            this.fetchTaskEpisode(this.$route.params.episode_id)
        }
    },

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
