<template>
    <div class="departments">
        <div class="main-wrapper">
            <hr-sidebar v-role="'admin'" />
            <all-sidebar v-role="'manager'" />
            <all-sidebar v-role="'employee'" />
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Notifications</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">Dashboard</li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/notifications">Notifications</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="finishLoad()">
                            <notification />
                        </div>
                        <div v-if="notificationBase.loadingFetch">
                            <loader />
                        </div>
                        <div v-if="error()">
                            <no-internet @retry="onRetry" :loading="notificationBase.loadingFetch"></no-internet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useNotificationStore } from "../store/NotificationStore"
import { mapActions, mapState } from "pinia"
import NoInternet from "@/components/main/NoInternet.vue"
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
import AllSidebar from '@/components/main/AllSidebar.vue'
import Notification from "../components/Notification.vue"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        NoInternet,
        HrSidebar,
        AllSidebar,
        Notification,
        Loader
    },
    data: () => ({

    }),
    computed: {
        ...mapState(useNotificationStore, ['notificationBase', 'notificationList']),
    },
    methods: {
        ...mapActions(useNotificationStore, ['fetchNotification']),
        onRetry() {
            this.fetchNotification()
        },
        finishLoad() {
            return (!this.notificationBase.loadingFetch && !this.notificationBase.errorFetch) ? true : false
        },
        error() {
            return (this.notificationBase.errorFetch && !this.notificationBase.loadingFetch) ? true : false
        }
    }
};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
.activity-box .activity-list::before {
    top: -14px !important;
    left: 47px !important;
}
.activity-box .activity-list li .activity-user {
    height: 35px !important;
    width: 35px !important;
    top: 9px !important;
    left: 6px !important;
}
</style>
