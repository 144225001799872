<template>
    <v-select
        outlined
        v-model="newModel"
        :reverse="getDirection"
        :items="items"
        :item-text="item_text"
        :item-value="item_value"
        :label="label"
        :rules="[requiredRule]"
        :multiple="ifMultiple"
        :readonly="readonly"
        :disabled="disabled"
    ></v-select>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "SelectInput",
    props:{
        model: {
            required: true
        },
        items: {
            type: Array, 
            required: true
        },
        item_text: {
            type: String
        },
        item_value: {
            type: String
        },
        label: {
            type: String, 
            required: true
        },
        ifMultiple: {
            type: Boolean,
        },
        readonly: {
            default: false,
            type: Boolean,
        },
        disabled: {
            default: false,
            type: Boolean,
        }
    },
    mixins: [FormValidationRulesMixin],
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newModel: {
            get() {
                return this.model
            },
            set(value) {
                this.$emit('update:model', value)
            }
        },
    },
}
</script>

<style>
</style>