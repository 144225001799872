<template>
    <div class="login account-page" style="height:100vh !important;">
        <div class="main-wrapper">
            <div class="account-content">
                <div class="container">
                    <div class="account-logo">
                        <img src="@/assets/img/logo-unicorn.png" alt="Unicorn" />
                    </div>
                    <div class="account-box">
                        <div class="account-wrapper">
                            <h3 class="account-title">Login</h3>
                            <p class="account-subtitle">Access to our dashboard</p>
                            <v-form style="margin-top: 20px" ref="form">
                                <v-text-field
                                    type="email"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    v-model="email"
                                    :rules="[requiredRule, emailRule]"
                                    label="Email"
                                    @keyup.enter="submit()"
                                    validate-on-blur
                                ></v-text-field>
                                <v-text-field
                                    style="margin: 20px 0px"
                                    outlined
                                    prepend-inner-icon="mdi-key"
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="[requiredRule, passwordRule]"
                                    label="Password"
                                    @keyup.enter="submit()"
                                    validate-on-blur
                                ></v-text-field>
                                <submit-btn
                                    @click="submit"
                                    :loading="authStore.loading"
                                    name="Login"
                                    :ifBlock="true"
                                    style="margin-bottom: 30px"
                                />
                                <div class="account-footer">
                                    <p>
                                        Don't have an account yet?
                                        <router-link to="/register">Register</router-link>
                                    </p>
                                </div>
                            </v-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import { useAuthStore } from "../store/AuthStore"
import SubmitBtn from "@/components/buttons/SubmitBtn"
export default {
    components: {
        SubmitBtn
    },
    data: () => ({
        email: '',
        password: '',
        response: false,
        isSubmit: false,
    }),
    setup() {
        const authStore = useAuthStore()
        return { authStore }
    },
    mixins: [FormValidationRulesMixin],
    data: () => ({
        email: null,
        password: null,
        showPassword: false,
        latitude: '',
        longitude: ''
    }),
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('email', this.email)
            formData.append('password', this.password)
            formData.append('latitude', this.latitude)
            formData.append('longitude', this.longitude)
            this.authStore.login(formData)
        },
        requestLocationPermission() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    this.getLocationSuccess,
                    this.getLocationError
                );
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        },
        getLocationSuccess(position) {
            // Handle location retrieval success here
            this.latitude = position.coords.latitude
            this.longitude = position.coords.longitude
            console.log("Latitude:", position.coords.latitude);
            console.log("Longitude:", position.coords.longitude);
        },
        getLocationError(error) {
            // Handle location retrieval error here
            console.log("Error code:", error.code);
            console.log("Error message:", error.message);
        },
    },
    mounted() {
        this.requestLocationPermission();
    }
}
</script>
<style>
@import url(@/assets/css/main.css);
</style>