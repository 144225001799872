<template>
    <v-dialog v-model="totalTableShow" max-width="1000px">
        <v-card style="padding:10px 0px 20px">
            <v-card-title style=" justify-content: center;margin-bottom: 20px">
                <span
                    class="text-h5 color-primary"
                >Total: {{fundModel.incomes_amount - fundModel.departments_expenses}}</span>
                <v-btn class="btn-close" @click="totalTableShow = false">
                    <v-icon style="font-size: 18px;">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4">
                        <div class="stats-info">
                            <h6>Expense Balance</h6>
                            <h4>{{fundModel.departments_expenses}}</h4>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div class="stats-info">
                            <h6>Total Balance</h6>
                            <h4>{{fundModel.incomes_amount - fundModel.departments_expenses}}</h4>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <div class="stats-info">
                            <h6>Income Balance</h6>
                            <h4>{{fundModel.incomes_amount}}</h4>
                        </div>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :search="search"
                    :items="fundModel.accounts_details"
                    class="my-table"
                    style="margin-bottom: 50px"
                >
                    <template
                        v-slot:[`item.total`]="{ item }"
                    >{{item.incomes - item.expenses}}</template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { useFundStore } from "../store/FundStore"
import { useLangStore } from "@/store/LangStore"
import { mapActions, mapState, mapWritableState } from "pinia"
export default {
    components: {
    },
    data() {
        return {
            search: '',
            headers: [
                {
                    text: "Department",
                    value: "account_name",
                    align: "center"
                },
                {
                    text: "Incomes",
                    value: "incomes",
                    align: "center"
                },
                {
                    text: "Expenses",
                    value: "expenses",
                    align: "center"
                },
                {
                    text: "Total",
                    value: "total",
                    align: "center"
                },
            ],
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useFundStore, ["fundBase", 'fundModel']),
        ...mapWritableState(useFundStore, ['totalTableShow'])

    },
    methods: {

    },
    mounted() {
    }
};
</script>
