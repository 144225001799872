import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTabs,{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_c(VTab,{directives:[{name:"can",rawName:"v-can",value:('project_details'),expression:"'project_details'"}]},[_vm._v("Episodes")]),_c(VTab,{directives:[{name:"can",rawName:"v-can",value:('task_view'),expression:"'task_view'"}]},[_vm._v("Tasks")])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{directives:[{name:"can",rawName:"v-can",value:('project_details'),expression:"'project_details'"}]},[_c('episode-table',{attrs:{"projectDetails":_vm.projectDetails}}),_c('episode-form')],1),_c(VTabItem,{directives:[{name:"can",rawName:"v-can",value:('task_view'),expression:"'task_view'"}]},[_c('task-table',{attrs:{"projectDetails":_vm.projectDetails}}),_c('task-form',{attrs:{"projectDetails":_vm.projectDetails}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }