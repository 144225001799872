<template>
    <form-dialog
        title="Add Expense"
        :persistent="true"
        :show.sync="expenseFormShow"
        @cancel="onCancel"
        width="600px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="fund-form">
                <v-row>
                    <v-col cols="12" >
                        <select-input
                            :model.sync="expenseModel.type"
                            :items="typeList"
                            label="Type"
                            item_text="title"
                            item_value="value"
                        />
                    </v-col>
                    <v-col cols="12"  v-if="expenseModel.type=='department'">
                        <select-input
                            :model.sync="expenseModel.department"
                            :items="departmentList"
                            label="Department"
                            item_text="name"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" >
                        <number-input :number.sync="expenseModel.amount" label="Amount" />
                    </v-col>
                    <v-col cols="12" >
                        <date-input
                            :date.sync="expenseModel.date"
                            label="Date"
                        />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="expenseModel.description" label="Description" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="expenseBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useFundStore } from "../../store/FundStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"
import { useDepartmentStore } from "@/modules/hr/department/store/DepartmentStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        TextareaInput,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            typeList: [
                {
                    title: "Expenses",
                    value: "expense"
                },
                {
                    title: "Department",
                    value: "department"
                }
            ]
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useFundStore, ["fundBase", "expenseBase", "expenseModel"]),
        ...mapWritableState(useFundStore, ["expenseFormShow"]),
        ...mapState(useDepartmentStore, ['departmentList']),
    },
    methods: {
        ...mapActions(useFundStore, ['addExpense', 'clearExpenseModel']),
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        submit() {
            if (this.$refs.form.validate()) {
                this.addExpense(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('type', this.expenseModel.type)
            if (this.expenseModel.type == 'department')
                formData.append('department', this.expenseModel.department)
            formData.append('date', this.expenseModel.date)
            formData.append('amount', this.expenseModel.amount)
            formData.append('description', this.expenseModel.description)
            return formData
        },
        onCancel() {
            this.expenseFormShow = false
            this.clearExpenseModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.fetchAllDepartment()
    }
};
</script>

<style>
.fund-form .col-12 {
    padding: 0px 15px !important;
}
</style>