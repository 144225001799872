import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"1000px"},model:{value:(_vm.totalTableShow),callback:function ($$v) {_vm.totalTableShow=$$v},expression:"totalTableShow"}},[_c(VCard,{staticStyle:{"padding":"10px 0px 20px"}},[_c(VCardTitle,{staticStyle:{"justify-content":"center","margin-bottom":"20px"}},[_c('span',{staticClass:"text-h5 color-primary"},[_vm._v("Total: "+_vm._s(_vm.fundModel.incomes_amount - _vm.fundModel.departments_expenses))]),_c(VBtn,{staticClass:"btn-close",on:{"click":function($event){_vm.totalTableShow = false}}},[_c(VIcon,{staticStyle:{"font-size":"18px"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"stats-info"},[_c('h6',[_vm._v("Expense Balance")]),_c('h4',[_vm._v(_vm._s(_vm.fundModel.departments_expenses))])])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"stats-info"},[_c('h6',[_vm._v("Total Balance")]),_c('h4',[_vm._v(_vm._s(_vm.fundModel.incomes_amount - _vm.fundModel.departments_expenses))])])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"stats-info"},[_c('h6',[_vm._v("Income Balance")]),_c('h4',[_vm._v(_vm._s(_vm.fundModel.incomes_amount))])])])],1),_c(VDataTable,{staticClass:"my-table",staticStyle:{"margin-bottom":"50px"},attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.fundModel.accounts_details},scopedSlots:_vm._u([{key:`item.total`,fn:function({ item }){return [_vm._v(_vm._s(item.incomes - item.expenses))]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }