<template>
    <div class="no-internet-image">
        <v-img src="@/assets/img/no-internet.svg" width="400px"></v-img>
        <v-btn color="secondary" @click="retry" :loading="loading">Retry</v-btn>
    </div>
</template>

<script>
export default {
    name:"NoInternet",
    props: {
        loading:{
            type: Boolean,
            required: true
        }
    },
    methods: {
        retry() {
            this.$emit('retry')
        },
    },
}
</script>

<style>
/* @import url(@/assets/css/main.css); */
</style>