import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import ManagerDashboardService from "../services/ManagerDashboardService";

export const useManagerDashboardStore = defineStore("managerDashboardStore", {
    state: () => ({
        managerDashboardBase: new BaseState(),
        managerDashboardList: [],
        total: '',
        show: false,
        showUploadFile: false
    }),
    getters: {
    },
    actions: {
        fetchManagerDashboard() {
            this.managerDashboardBase.loadingFetch = true
            ManagerDashboardService.get().then((res) => {
                this.managerDashboardList = res.data.list
                this.managerDashboardBase.loadingFetch = false
                this.managerDashboardBase.errorFetch = false
            })
                .catch((err) => {
                    this.managerDashboardBase.loadingFetch = false
                    this.managerDashboardBase.errorFetch = true
                });
        },
        editPercent(formData) {
            this.managerDashboardBase.loading = true
            ManagerDashboardService.editPercent(formData).then((res) => {
                this.managerDashboardBase.loading = false
                this.managerDashboardBase.error = false
                this.show = false
                this.fetchManagerDashboard()
            })
                .catch((err) => {
                    this.managerDashboardBase.loading = false
                    this.managerDashboardBase.error = true
                });
        },
        addFile(formData){
            this.managerDashboardBase.loading = true
            ManagerDashboardService.addFile(formData).then((res) => {
                this.managerDashboardBase.loading = false
                this.managerDashboardBase.error = false
                this.showUploadFile = false
                this.fetchManagerDashboard()
            })
                .catch((err) => {
                    this.managerDashboardBase.loading = false
                    this.managerDashboardBase.error = true
                });
        }
    },
});
