import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import PaycheckService from "../services/PaycheckService";

export const usePaycheckStore = defineStore("paycheckStore", {
    state: () => ({
        paycheckBase: new BaseState(),
        paycheckModel: {
            id: '',
            amount: '',
            date: '',
            company_id: '',
            employee_id: '',
        },
        paycheckList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        clearPaycheckModel() {
            this.paycheckModel.id = ''
            this.paycheckModel.amount = ''
            this.paycheckModel.date = ''
            this.paycheckModel.company_id = ''
            this.paycheckModel.employee_id = ''
        },
        setPaycheckModel(item) {
            this.paycheckModel.id = item.id
            this.paycheckModel.amount = item.amount
            this.paycheckModel.date = item.date
            this.paycheckModel.company_id = item.company_id
            this.paycheckModel.employee_id = item.employee_id
        },
        addPaycheck(formData) {
            this.paycheckBase.loading = true;
            PaycheckService.add(formData).then((res) => {
                this.paycheckBase.loading = false
                this.show = false
                this.fetchAllPaycheck()
                this.clearPaycheckModel()
            })
                .catch((err) => {
                    this.paycheckBase.loading = false
                    this.paycheckBase.error = true
                });
        },
        fetchAllPaycheck() {
            this.paycheckBase.loadingFetch = true
            PaycheckService.getAll().then((res) => {
                this.paycheckList = res.data.list.reverse()
                this.paycheckBase.loadingFetch = false
                this.paycheckBase.errorFetch = false
            })
                .catch((err) => {
                    this.paycheckBase.loadingFetch = false
                    this.paycheckBase.errorFetch = true
                });
        },
        deletePaycheck(formData) {
            PaycheckService.delete(formData).then((res) => {
                this.paycheckBase.loading = false
                this.paycheckList.splice(this.paycheckBase.editedIndex, 1)
                this.paycheckBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.paycheckBase.loading = false
                    this.paycheckBase.error = true
                })
        },
        getPaycheckById(id) {
            this.paycheckBase.loading = true
            PaycheckService.getById(id).then((res) => {
                this.paycheckBase.loading = false
                this.paycheckModel.id = res.data.list.id
                this.paycheckModel.name = res.data.list.name
            })
                .catch((err) => {
                    this.paycheckBase.loading = false
                    this.paycheckBase.error = true
                })
        },
        editPaycheck(id, formData) {
            formData.append('id', id)
            this.paycheckBase.loading = true
            PaycheckService.edit(formData).then((res) => {
                this.paycheckBase.loading = false
                this.paycheckBase.ifEdit = false
                this.show = false
                this.fetchAllPaycheck()
                this.clearPaycheckModel()
            })
                .catch((err) => {
                    this.paycheckBase.loading = false
                    this.paycheckBase.error = true
                });
        },
        changeStatusPaycheck(formData){
            this.paycheckBase.loadingFetch = true
            PaycheckService.changeStatus(formData).then((res) => {
                this.fetchAllPaycheck()
            })
        }
    },
});
