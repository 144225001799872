import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import AccountService from "../services/AccountService";

export const useAccountStore = defineStore("accountStore", {
    state: () => ({
        accountBase: new BaseState(),
        expenseBase: new BaseState(),
        accountModel: null,
        accountList: [],
        expenseModel: {
            account_id: '',
            amount: '',
            expense_ref: '',
            description: '',
            date: '',
        },
        expenseList: [],
        show: false,
    }),
    actions: {
        clearExpenseModel() {
            this.expenseModel.account_id = ''
            this.expenseModel.amount = ''
            this.expenseModel.expense_ref = ''
            this.expenseModel.description = ''
            this.expenseModel.date = ''
        },
        addExpense(id, formData) {
            this.expenseBase.loading = true;
            AccountService.addExpense(formData).then((res) => {
                this.expenseBase.loading = false
                this.show = false
                this.getById(id)
                this.clearExpenseModel()
            })
                .catch((err) => {
                    this.expenseBase.loading = false
                    this.expenseBase.error = true
                });
        },
        fetchAllAccount() {
            this.accountBase.loadingFetch = true
            AccountService.getAll().then((res) => {
                this.accountList = res.data.list
                this.accountBase.loadingFetch = false
                this.accountBase.errorFetch = false
            })
                .catch((err) => {
                    this.accountBase.loadingFetch = false
                    this.accountBase.errorFetch = true
                });
        },
        getById(id, start_date, end_date) {
            this.accountBase.loadingFetch = true
            AccountService.getById(id, start_date, end_date).then((res) => {
                this.accountModel = res.data.list
                this.accountModel.account.incomes.forEach(item => {
                    this.accountModel.account.expenses.push(item)
                })
                this.accountModel.account.expenses = this.accountModel.account.expenses.sort(
                    (objA, objB) => (objB.id) - (objA.id),
                );
                this.accountBase.loadingFetch = false
                this.accountBase.errorFetch = false
            })
                .catch((err) => {
                    this.accountBase.loadingFetch = false
                    this.accountBase.errorFetch = true
                });
        },
        deleteExpense(formData){
            AccountService.deleteExpense(formData).then((res) => {
                this.accountBase.loading = false
                this.accountModel.account.expenses.splice(this.accountBase.editedIndex, 1)
                this.accountBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.accountBase.loading = false
                    this.accountBase.error = true
                })
        }
    },
});
