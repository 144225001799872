<template>
    <div>
        <hr-layout :show.sync="show" title="Department">
            <template v-slot:dataTable>
                <div v-if="!departmentBase.errorFetch">
                    <department-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="departmentBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
        <department-form />
    </div>
</template>

<script>
import { useDepartmentStore } from "../store/DepartmentStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import DepartmentTable from "../components/DepartmentTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import DepartmentForm from "../components/DepartmentForm.vue"

export default {
    components: {
        DepartmentTable,
        NoInternet,
        DepartmentForm,
        HrLayout,
    },
    data: () => ({
    }),
    computed: {
        ...mapState(useDepartmentStore, ['departmentBase']),
        ...mapWritableState(useDepartmentStore, ['show'])
    },
    methods: {
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        onRetry() {
            this.fetchAllDepartment()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
