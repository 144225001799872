<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    outlined
                    v-model="projectBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" style="padding: 0px 16px">
                <v-select outlined class="filter" v-model="filter" :reverse="getDirection" :items="filterList" 
                item-text="title" item-value="value"
                hide-details append-icon="mdi-filter" label="Filter"></v-select>
            </v-col>
            <v-col cols="12" sm="4" style="padding: 0px 16px">
                <month-input :date.sync="date" label="Date" @ok="filterByDate"/>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="filteredData"
            :search="projectBase.search"
            class="my-table"
            :loading="projectBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Price:</span>
                        {{ item.project_min_price }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Time:</span>
                        {{ item.time }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Total Minutes:</span>
                        {{ item.minutes }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Description:</span>
                        {{ item.description }}
                    </div>
                    <!-- <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Manager:</span>
                        {{ item.manager[0].username }}
                    </div> -->
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="projectBase.dialogDeleteModel"
                    :loading="projectBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template
                v-slot:[`item.created_at`]="{ item }"
            >{{item.created_at.slice(0, 10)}}</template>
            <template v-slot:[`item.end_date`]="{ item }">{{item.end_date.slice(0, 10)}}</template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="displayItem(item)" text icon color="indigo">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" text icon color="error" v-can="'project_delete'">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green" v-can="'project_edit'">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            
            <template v-slot:[`item.status`]="{ item }">
                <div class="btn-status">
                    <v-chip rounded color="warning" outlined v-if="item.status == 'in_progress'">
                        In Progress
                    </v-chip>
                    <v-chip rounded color="success" outlined v-if="item.status == 'completed'">
                        Completed 
                    </v-chip>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useProjectStore } from "../store/ProjectStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import MonthInput from "@/components/inputs/MonthInput.vue"
export default {
    components: { ConfirmDeleteDialog, MonthInput },

    data() {
        return {
            headers: [
                {
                    text: "Project",
                    value: "title",
                    align: "center"
                },
                {
                    text: "Client",
                    value: "client.name",
                    align: "center"
                },
                {
                    text: "Channel",
                    value: "channel",
                    align: "center"
                },
                {
                    text: "Start Date",
                    value: "created_at",
                    align: "center"
                },
                {
                    text: "Finish Date",
                    value: "end_date",
                    align: "center"
                },
                {
                    text: "Episodes Number",
                    value: "nummber_of_episodes",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            expanded: [],
            filter: '',
            date: '',
            filterList: [
                {
                    title: "All", value: "all"
                },
                {
                    title: "Completed", value: "completed"
                },
                {
                    title: "In Progress", value: "in_progress"
                },
            ]
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useProjectStore, ['projectBase', 'projectList']),
        ...mapWritableState(useProjectStore, ['show']),
        filteredData() {
            if (this.filter == 'all')
                return this.projectList
            else if (this.filter == 'completed')
                return this.projectList.filter(item => item.status == 'completed')
            else if (this.filter == 'in_progress')
                return this.projectList.filter(item => item.status == 'in_progress')
            else
                return this.projectList
        },
    },

    methods: {
        ...mapActions(useProjectStore, ['fetchAllProject', 'deleteProject', 'setProjectModel']),

        deleteItem(item) {
            this.projectBase.editedIndex = this.projectList.indexOf(item)
            this.projectBase.delete = item
            this.projectBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('project_id', this.projectBase.delete.id)
            this.projectBase.loading = true
            this.deleteProject(formData)
        },

        editItem(item) {
            this.projectBase.ifEdit = true
            this.setProjectModel(item)
            this.show = true
        },

        displayItem(item){
            console.log(item.id)
            this.$router.push(`/translation/project/${item.id}`)
        },
        filterByDate(){
            this.fetchAllProject(this.date.split("-").reverse().join("-"))
        }

    },
    mounted() {
        // this.fetchAllProject()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.btn-status .v-btn{
    padding: 0px !important;
}
.v-data-table ul{
    list-style: disc !important;
}
</style>