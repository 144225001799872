import { useAuthStore } from "@/modules/auth/store/AuthStore";
const RoleDirective = {
    bind: function (el, binding, vnode) {
        var authStore = useAuthStore();
        if (authStore.user) {
            var role = authStore.user.role;
            let roleName;
            switch (role) {
                case 1:
                    roleName = 'admin';
                    break;
                case 2:
                    roleName = 'employee';
                    break;
                case 5:
                    roleName = 'manager';
                    break;
            }

            // console.log(roleName)
            // console.log(binding.value)
            if ((binding.value && roleName != binding.value)) {
                vnode.elm.style.display = "none";

                // replace HTMLElement with comment node
                const comment = document.createComment(" ");
                Object.defineProperty(comment, "setAttribute", {
                    value: () => undefined,
                });
                vnode.elm = comment;
                vnode.text = " ";
                vnode.isComment = true;
                vnode.context = undefined;
                vnode.tag = undefined;
                vnode.data.directives = undefined;

                if (vnode.componentInstance) {
                    vnode.componentInstance.$el = comment;
                }

                if (el.parentNode) {
                    el.parentNode.replaceChild(comment, el);
                }
            }
        }
    },
};
export default RoleDirective;
