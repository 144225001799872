import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import RoleService from "../services/RoleService";
import router from "@/router";
export const useRoleStore = defineStore("roleStore", {
    state: () => ({
        roleBase: new BaseState(),
        roleModel: {
            id: '',
            role_name: '',
            permissions: [],
        },
        roleList: [],
        permissionList: [],
    }),
    actions: {
        clearRoleModel() {
            this.roleModel.id = ''
            this.roleModel.role_name = ''
            this.roleModel.permissions = []
        },
        setRoleModel(item) {
            this.roleModel.id = item.id
            this.roleModel.role_name = item.role_name
            this.roleModel.permissions = item.permissions
        },
        fetchAllRole() {
            this.roleBase.loadingFetch = true
            RoleService.getAll().then((res) => {
                this.roleList = res.data.list.reverse()
                this.roleBase.loadingFetch = false
                this.roleBase.errorFetch = false
            })
                .catch((err) => {
                    this.roleBase.loadingFetch = false
                    this.roleBase.errorFetch = true
                });
        },
        async getRoleById(id) {
            this.roleModel.permissions = [];
            this.roleBase.loadingFetch = true;
            try {
                const res = await RoleService.getById(id);
                this.roleModel.role_name = res.data.list.name;
                res.data.list.permissions.forEach((item) => {
                    this.roleModel.permissions.push(item.name);
                });
                // console.log(this.roleModel.permissions);
            } catch (err) {
                this.roleBase.errorFetch = true;
                // console.log(err);
            } finally {
                this.roleBase.loadingFetch = false;
            }
        },
        addRole(params) {
            this.roleBase.loading = true
            RoleService.add(params).then((res) => {
                this.roleBase.loading = false
                this.fetchAllRole()
                this.clearRoleModel()
                router.push('/roles')
            })
                .catch((err) => {
                    this.roleBase.loading = false
                    this.roleBase.error = true
                });
        },
        editRole(params) {
            this.roleBase.loading = true
            RoleService.edit(params).then((res) => {
                this.roleBase.loading = false
                this.roleBase.ifEdit = false
                this.fetchAllRole()
                this.clearRoleModel()
                router.push('/roles')
            })
                .catch((err) => {
                    this.roleBase.loading = false
                    this.roleBase.error = true
                });
        },
        deleteRole(formData) {
            RoleService.delete(formData).then((res) => {
                this.roleBase.loading = false
                this.roleList.splice(this.roleBase.editedIndex, 1)
                this.roleBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.roleBase.loading = false
                    this.roleBase.error = true
                })
        },
        fetchAllPer() {
            this.roleBase.loadingFetch = true
            RoleService.getPer().then((res) => {
                this.permissionList = res.data.list
                this.roleBase.loadingFetch = false
                this.roleBase.errorFetch = false
            })
                .catch((err) => {
                    this.roleBase.loadingFetch = false
                    this.roleBase.errorFetch = true
                });
        }
    },
});
