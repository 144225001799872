import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_leave", formData);
    },

    getAll() {
        return Client.get("/leaves");
    },

    getById(id) {
        return Client.get(`/leave?leave_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_leave`, formData);
    },

    delete(formData) {
        return Client.post(`/delete_leave`, formData);
    },

    changeStatus(id, status) {
        return Client.post(`/change_leave_status?id=${id}&status=${status}`);
    }
};