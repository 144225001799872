<template>
    <div>
        <hr-layout :show.sync="show" title="Client">
            <template v-slot:dataTable>
                <div v-if="!clientBase.errorFetch">
                    <client-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="clientBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
        <client-form />
    </div>
</template>

<script>
import { useClientStore } from "../store/ClientStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import ClientTable from "../components/ClientTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import ClientForm from "../components/ClientForm.vue"

export default {
    components: {
        ClientTable,
        NoInternet,
        ClientForm,
        HrLayout,
    },
    data: () => ({
        cardTitle: "Client List",
        btnTitle: "Add Client",
        breadEn: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Client',
                disabled: false,
                href: '/client',
            }
        ],
        breadAr: [
            {
                text: 'الرئيسية',
                disabled: false,
                href: '/',
            },
            {
                text: ' تواصل معنا',
                disabled: false,
                href: '/client',
            }
        ],
    }),
    computed: {
        ...mapState(useClientStore, ['clientBase']),
        ...mapWritableState(useClientStore, ['show'])
    },
    methods: {
        ...mapActions(useClientStore, ['fetchAllClient']),
        onRetry() {
            this.fetchAllClient()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
