<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    v-model="taskBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col v-can="'task_add'">
                <a class="btn add-btn" @click="showAdd = true" v-if="projectDetails.nummber_of_episodes > projectDetails.tasks.length">
                    <i class="fa fa-plus"></i> Add Task
                </a>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="projectDetails.tasks"
            :search="taskBase.search"
            class="my-table"
            :loading="projectBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="taskBase.dialogDeleteModel"
                    :loading="taskBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <task-details-dialog />
                <files-task-dialog />
                <edit-task :projectDetails="projectDetails"/>
            </template>
            <template v-slot:[`item.end_date`]="{ item }">{{item.end_date.slice(0, 10)}}</template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error" v-can="'task_delete'">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green" v-can="'task_edit'">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="displayItem(item)" text icon color="indigo" v-can="'task_details'">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn @click="displayFile(item)" text icon color="deep-purple lighten-1">
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.task_status`]="{ item }">
                <v-progress-linear
                    color="light-green darken-3"
                    height="15"
                    :value="item.task_status"
                    striped
                >
                    <template v-slot:default="{ value }">
                        {{ Math.ceil(value) }}%
                    </template>
                </v-progress-linear>
            </template>
            <template v-slot:[`item.title`]="{ item }">
                <a style="color: var(--v-primary-base)" @click="taskDetailsShow = true">
                    {{item.title}}
                </a>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useTaskStore } from "../store/TaskStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import { useProjectStore } from "@/modules/translation/project/store/ProjectStore"
import TaskDetailsDialog from './TaskDetailsDialog.vue';
import EditTask from "./EditTask.vue"
import FilesTaskDialog from './FilesTaskDialog.vue';
export default {
    components: { ConfirmDeleteDialog, TaskDetailsDialog, EditTask, FilesTaskDialog },
    props:{
        projectDetails:{
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    text: "Task",
                    value: "title",
                    align: "center"
                },
                {
                    text: "Finish Date",
                    value: "end_date",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "task_status",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],  
            display: null          
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useTaskStore, ['taskBase', 'taskList', 'taskModel']),
        ...mapState(useProjectStore, ['projectBase']),
        ...mapWritableState(useTaskStore, ['showAdd', 'showEdit', 'taskDetailsShow', 'filesTaskShow'])
    },

    methods: {
        ...mapActions(useTaskStore, [ 'deleteTask', 'setTaskModel', 'filesTask']),

        deleteItem(item) {
            this.taskBase.editedIndex = this.taskList.indexOf(item)
            this.taskBase.delete = item
            this.taskBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.taskModel.project_id = parseInt(this.$route.params.project_id)
            const formData = new FormData()
            formData.append('task_id', this.taskBase.delete.id)
            this.taskBase.loading = true
            this.deleteTask(formData)
        },

        editItem(item) {
            this.taskModel.project_id = parseInt(this.$route.params.project_id)
            this.taskBase.ifEdit = true
            this.setTaskModel(item)
            this.showEdit = true
        },

        displayItem(item){
            console.log(item.id)
            this.$router.push(`/translation/tasks/${item.id}`)
        },

        displayFile(item){
            this.filesTaskShow = true
            this.filesTask(item.id)
        }
    },
    mounted() {
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.btn-status .v-btn{
    padding: 0px !important;
}
.v-data-table ul{
    list-style: disc !important;
}
</style>