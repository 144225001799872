import axios from "axios";
import router from "@/router";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
// import { useLangStore } from "@/store/LangStore";
import pinia from "@/store";
import Vue from 'vue';

const baseDomain = process.env.VUE_APP_API_DOMAIN;
const baseURL = `${baseDomain}api`;
// console.log(baseURL);

const httpClient = axios.create({
  baseURL,
  responseType: "json",
  headers: {},
  // timeout: 20000,
});

httpClient.interceptors.request.use((config) => {
  const authStore = useAuthStore(pinia);
  // const langStore = useLangStore(pinia);
  config.headers.Authorization = `Bearer ${authStore.getToken}`;
  config.headers.Accept = "application/json";
  // config.headers.lang = langStore.getLang;
  return config;
});

httpClient.interceptors.response.use(
  response => {
    // console.log(response.data)
    const message = response.data.message
    switch(message){
      case "Successfully Deleted": {
        Vue.$toast.success(message, {
          duration: 4000,
          position: "top-right",
          queue: true
        });
        break;
      }
      // case "Success": {
      //   Vue.$toast.success(message, {
      //     duration: 4000,
      //     position: "top-right",
      //     queue: true
      //   });
      //   break;
      // }
      case "Successfully Created": {
        Vue.$toast.success(message, {
          duration: 4000,
          position: "top-right",
          queue: true
        });
        break;
      }
      case "translate.SuccessfullyUpdated": {
        Vue.$toast.success('Successfully Updated', {
          duration: 4000,
          position: "top-right",
          queue: true
        });
        break;
      }
    }
    return response
  },
  (error) => {
    const authStore = useAuthStore(pinia);
    console.log(error)
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 400: {
          const errorMessage = error.response.data.message;
          console.error(errorMessage);
          Vue.$toast.error(errorMessage, {
            duration: 4000,
            position: "top-right",
            queue: true
          });
          break;
        }
        case 401: {
          const errorMessage = error.response.data.message;
          console.error(errorMessage);
          Vue.$toast.error(errorMessage, {
            duration: 4000,
            position: "top-right",
            queue: true
          });
          break;
        }
        case 403: {
          const sessionExpiredMessage = "Your session has expired, please log in again.";
          const requiresAuthMessage = "Please log in to complete your request.";
          if (authStore.isAuthenticated) {
            Vue.$toast.warning(sessionExpiredMessage, {
              duration: 4000,
              position: "top-right",
              queue: true
            });
            authStore.logoutLocally();
          } else {
            //case no token is stored
            Vue.$toast.error(requiresAuthMessage, {
              duration: 4000,
              position: "top-right",
              queue: true
            });
          }
          router.push("/login");
          break;
        }
        case 404: {
          const errorMessage = 'Resource not found';
          console.error(errorMessage);
          Vue.$toast.error(errorMessage, {
            duration: 4000,
            position: "top-right",
            queue: true
          });
          break;
        }
        case 500: {
          const errorMessage = error.response.data.message;
          console.error(errorMessage);
          Vue.$toast.error(errorMessage, {
            duration: 4000,
            position: "top-right",
            queue: true
          });
          break;
        }
        default: {
          console.error('An error occurred: ', error);
          Vue.$toast.error("An error occurred", {
            duration: 4000,
            position: "top-right",
            queue: true
          });
        }
      }
    }
    else {
      // const errorCode = error.code;
      // console.log(errorCode)
      Vue.$toast.error("Unexpected error, please check your internet connection!", {
        duration: 4000,
        position: "top-right",
        queue: true
      });
    }
    return Promise.reject(error);
  }
);


export default httpClient;
