<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="employeeDashboardBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="employeeDashboardList"
            :search="employeeDashboardBase.search"
            class="my-table"
            :loading="employeeDashboardBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:[`item.manager_file`]="{ item }">
                <ul>
                    <li v-for="(file, index) in item.manager_file" :key="index">
                        <a
                        :href="file"
                        target="_blank"
                        :download="file"
                    >{{ getFileName(file) }}</a>
                    </li>
                </ul>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { useEmployeeDashboardStore } from "../store/EmployeeDashboardStore";
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {

    data() {
        return {
            headers: [
                {
                    text: "Task",
                    value: "task",
                    align: "center"
                },
                {
                    text: "Media Number",
                    value: "media_number",
                    align: "center"
                },
                {
                    text: "Project",
                    value: "project",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "task_type",
                    align: "center"
                },
                {
                    text: "Finish Date",
                    value: "finish_date",
                    align: "center"
                },
                {
                    text: "Price",
                    value: "price",
                    align: "center"
                },
                {
                    text: "Percent %",
                    value: "percent",
                    align: "center"
                },
                {
                    text: "Price",
                    value: "new_price",
                    align: "center"
                },
                {
                    text: "Attachment",
                    value: "manager_file",
                    align: "center"
                },
            ],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useEmployeeDashboardStore, ['employeeDashboardBase', 'employeeDashboardList']),
    },

    methods: {
        ...mapActions(useEmployeeDashboardStore, ['fetchEmployeeDashboard']),
        getFileName(url) {
            const parts = url.split("/");
            return parts[parts.length - 1];
        }
    },
    mounted() {
        this.fetchEmployeeDashboard()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>