<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="managerDashboardBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="managerDashboardList"
            :search="managerDashboardBase.search"
            class="my-table"
            :loading="managerDashboardBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:[`item.attachment`]="{ item }">
                <div> <a :href="item.attachment"
                        target="_blank"
                        :download="item.attachment"
                    >{{ getFileName(item.attachment) }}
                    </a>
                </div>
            </template>
            <template v-slot:[`item.file`]="{ item }">
                <v-row v-if="item.percent < 100" style="flex-wrap: nowrap;margin-top: 10px">
                    <v-btn :disabled="!files[item.employee_task_id]" color="primary" @click="showDialogFile(item)" outlined icon><v-icon>mdi-upload</v-icon></v-btn>
                    <v-file-input style="margin:0 0 0 10px;padding: 0px;" 
                        chips
                        v-model="files[item.employee_task_id]"
                        label="Attachment"
                        append-icon=""
                        prepend-icon
                        :rules="[fileRule]"
                    ></v-file-input>
                </v-row>
            </template>
            <template v-slot:[`item.percent`]="{ item }">
                <v-text-field v-if="item.percent_status == 1"
                    :reverse="getDirection"
                    v-model="selected_percent[item.employee_task_id]"
                    :rules="[numberRule, percentRule]"
                    label="%"
                    @blur="showDialog(item)"
                    @keyup.enter="showDialog(item)"
                ></v-text-field>
                <div v-else>
                    {{ item.percent }}
                </div>
            </template>
        </v-data-table>
        <v-dialog v-model="show" max-width="500px">
            <v-card>
                <v-card-title style=" justify-content: center;margin-bottom: 0px">
                    <span class="text-h5 color-primary">Confirm</span>
                    <v-btn class="btn-close" @click="cancelPercent">
                        <v-icon style="font-size: 18px;">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <h5 style="padding: 20px;text-align: center;">Are you sure you want to update the price percent?</h5>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-content-center" style="padding-bottom: 20px;">
                    <v-btn
                        @click="percent"
                        color="primary"
                        large
                        light
                        :loading="managerDashboardBase.loading"
                        rounded
                    >OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showUploadFile" max-width="500px">
            <v-card>
                <v-card-title style=" justify-content: center;margin-bottom: 0px">
                    <span class="text-h5 color-primary">Confirm</span>
                    <v-btn class="btn-close" @click="cancelUpload">
                        <v-icon style="font-size: 18px;">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <h5 style="padding: 20px;text-align: center;">Are you sure you want to upload this file?</h5>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-content-center" style="padding-bottom: 20px;">
                    <v-btn
                        @click="uploadFile"
                        color="primary"
                        large
                        light
                        :loading="managerDashboardBase.loading"
                        rounded
                    >OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { useManagerDashboardStore } from "../store/ManagerDashboardStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
export default {
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            headers: [
                {
                    text: "Employee",
                    value: "employee_name",
                    align: "center",
                    width: "150"
                },
                {
                    text: "Task",
                    value: "task_title",
                    align: "center",
                    width: "180"
                },
                {
                    text: "Media Number",
                    value: "media_number",
                    align: "center"
                },
                {
                    text: "Project",
                    value: "project",
                    align: "center",
                    width: "180"
                },
                {
                    text: "Type",
                    value: "type",
                    align: "center"
                },
                {
                    text: "Finish Date",
                    value: "finish_date",
                    align: "center",
                    width: "120"
                },
                {
                    text: "Price",
                    value: "price",
                    align: "center"
                },
                {
                    text: "Percent %",
                    value: "percent",
                    align: "center",
                    width: "120"
                },
                {
                    text: "Price",
                    value: "new_price",
                    align: "center",
                },
                {
                    text: "Attachment",
                    value: "attachment",
                    align: "center",
                    width: "180"
                },
                {
                    text: "Upload Attachment",
                    value: "file",
                    align: "center",
                    width: "180"
                },
            ],
            selected_percent: [],
            percentItem: null,
            index: null,
            files: [],
            uploadItem: null,
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useManagerDashboardStore, ['managerDashboardBase', 'managerDashboardList']),
        ...mapWritableState(useManagerDashboardStore, ['show', 'showUploadFile']),
    },

    methods: {
        ...mapActions(useManagerDashboardStore, ['fetchManagerDashboard', 'editPercent', 'addFile']),
        getFileName(url) {
            const parts = url.split("/");
            return parts[parts.length - 1];
        },
        percent(){
            const formData = new FormData()
            formData.append('employee_task_id', this.percentItem.employee_task_id)
            formData.append('percent', this.selected_percent[this.percentItem.employee_task_id])
            this.editPercent(formData)
        },
        showDialog(item,){
            this.show = true
            this.percentItem = item
        },
        cancelPercent(){
            this.show = false
        },
        showDialogFile(item){
            this.showUploadFile = true
            this.uploadItem = item
            // console.log(this.files[item.employee_task_id])
        },
        cancelUpload(){
            this.showUploadFile = false
        },
        uploadFile(){
            const formData = new FormData()
            formData.append('task_id', this.uploadItem.employee_task_id)
            formData.append('file', this.files[this.uploadItem.employee_task_id])
            formData.append('manager_id', JSON.parse(localStorage.getItem('user')).id)
            this.addFile(formData)
            this.files[this.uploadItem.employee_task_id] = null
        },
    },
    mounted() {
        this.fetchManagerDashboard()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>