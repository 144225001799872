<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="overtimeBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="overtimeList"
            :search="overtimeBase.search"
            class="my-table"
            :loading="overtimeBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { useOvertimeStore } from "../store/OvertimeStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {

    data() {
        return {
            headers: [
                {
                    text: "Hour",
                    value: "hour",
                    align: "center"
                },
                {
                    text: "Price",
                    value: "price",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useOvertimeStore, ['overtimeBase', 'overtimeList']),
        ...mapWritableState(useOvertimeStore, ['show'])
    },

    methods: {
        ...mapActions(useOvertimeStore, ['fetchOvertime', 'setOvertimeModel']),

        editItem(item) {
            this.overtimeBase.ifEdit = true
            this.setOvertimeModel(item)
            this.show = true
        },


    },
    mounted() {
        this.fetchOvertime()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>