import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css' 

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'light',
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#880317',
                secondary: '#9a9995',
                accent: '#d10221',
            },
        },
    },
});


