import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":_vm.width,"persistent":_vm.persistent},model:{value:(_vm.showUpdated),callback:function ($$v) {_vm.showUpdated=$$v},expression:"showUpdated"}},[_c(VCard,{staticStyle:{"padding":"10px 0px 20px"}},[_c(VCardTitle,{staticStyle:{"justify-content":"center","margin-bottom":"20px"}},[_c('span',{staticClass:"text-h5 color-primary"},[_vm._v(_vm._s(_vm.title))]),_c(VBtn,{staticClass:"btn-close",on:{"click":_vm.cancel}},[_c(VIcon,{staticStyle:{"font-size":"18px"}},[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,[_c(VContainer,[_vm._t("inputs")],2)],1),_c(VDivider),_c(VCardActions,{staticStyle:{"column-gap":"10px","justify-content":"center"}},[_vm._t("actions")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }