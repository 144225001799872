<template>
    <div>
        <hr-layout :show.sync="show" title="Designation">
            <template v-slot:dataTable>
                <div v-if="!designationBase.errorFetch">
                    <designation-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="designationBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
        <designation-form />
    </div>
</template>

<script>
import { useDesignationStore } from "../store/DesignationStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import DesignationTable from "../components/DesignationTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import DesignationForm from "../components/DesignationForm.vue"

export default {
    components: {
        DesignationTable,
        NoInternet,
        DesignationForm,
        HrLayout,
    },
    data: () => ({
        cardTitle: "Designation List",
        btnTitle: "Add Designation",
    }),
    computed: {
        ...mapState(useDesignationStore, ['designationBase']),
        ...mapWritableState(useDesignationStore, ['show'])
    },
    methods: {
        ...mapActions(useDesignationStore, ['fetchAllDesignation']),
        onRetry() {
            this.fetchAllDesignation()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
