<template>
    <div class="departments">
        <div class="main-wrapper">
            <all-sidebar v-role="'employee'"/>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Task Details</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/employee-task">Tasks</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" v-if="finishLoad()">{{task_id}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <employee-task-details-table />
                        </div>
                        <div v-if="error()">
                            <no-internet @retry="onRetry" :loading="projectBase.loadingFetch"></no-internet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useTaskStore } from "@/modules/translation/task/store/TaskStore"
import { mapActions, mapState } from "pinia"
import EmployeeTaskDetailsTable from "../components/EmployeeTaskDetailsTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import AllSidebar from "@/components/main/AllSidebar.vue"

export default {
    components: {
        EmployeeTaskDetailsTable,
        NoInternet,
        AllSidebar,
    },
    data: () => ({
        task_id: '',
    }),
    computed: {
        ...mapState(useTaskStore, ['taskBase', 'employeeTaskDetails']),
    },
    methods: {
        ...mapActions(useTaskStore, ['getEmployeeTaskDetails']),
        onRetry() {
            this.getEmployeeTaskDetails(this.task_id)
        },
        finishLoad() {
            return (!this.taskBase.loadingFetch) ? true : false
        },
        error() {
            return (this.taskBase.errorFetch && !this.taskBase.loadingFetch) ? true : false
        },
    },
    mounted() {
        this.task_id = this.$route.params.id
        this.getEmployeeTaskDetails(this.task_id)
    }
};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
