import Client from "@/clients/AxiosClient";

export default {
    add(params) {
        return Client.post('/create_role', params);
    },

    getAll() {
        return Client.get("/roles");
    },

    getById(id) {
        return Client.get(`/role?role_id=${id}`);
    },

    edit(params) {
        return Client.post(`/edit_role`, params);
    },

    delete(formData){
        return Client.post(`/delete_role`, formData);
    },

    getPer() {
        return Client.get("/permissions");
    },
};