import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import SalariesService from "../services/SalariesService";

export const useSalariesStore = defineStore("salariesStore", {
    state: () => ({
        salariesBase: new BaseState(),
        salariesModel: null,
        salariesList: [],
        show: false,
        showPaycheckConfirm: false,
    }),
    actions: {
        fetchAllSalaries(date) {
            this.salariesBase.loadingFetch = true
            SalariesService.getAll(date).then((res) => {
                this.salariesList = res.data.list.data.reverse()
                console.log(this.salariesList)
                this.salariesBase.loadingFetch = false
                this.salariesBase.errorFetch = false
            })
                .catch((err) => {
                    this.salariesBase.loadingFetch = false
                    this.salariesBase.errorFetch = true
                });
        },
        paySalaries(formData) {
            this.salariesBase.loading = true
            SalariesService.pay(formData).then((res) => {
                this.salariesBase.loading = false
                this.salariesBase.error = false
                this.show = false
                this.fetchAllSalaries()
            })
                .catch((err) => {
                    this.salariesBase.loading = false
                    this.salariesBase.error = true
                });
        },
        paycheckAmount(formData){
            this.salariesBase.loading = true
            SalariesService.paycheckAmount(formData).then((res) => {
                this.salariesBase.loading = false
                this.salariesBase.error = false
                this.showPaycheckConfirm = false
                this.fetchAllSalaries()
            })
                .catch((err) => {
                    this.salariesBase.loading = false
                    this.salariesBase.error = true
                });
        }
    },
});
