<template>
    <v-card v-if="(roleBase.ifEdit && !roleBase.loadingFetch) || !roleBase.ifEdit">
        <v-card-title style="padding: 20px 20px 0px" v-if="!roleBase.ifEdit">{{titleAdd}}</v-card-title>
        <v-card-title style="padding: 20px 20px 0px" v-if="roleBase.ifEdit">{{titleEdit}}</v-card-title>
        <v-divider />
        <v-card-text>
            <v-form ref="form" class="role-form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="roleModel.role_name" label="Role Name" />
                    </v-col>
                </v-row>
                <v-row class="permission-cards">
                    <v-col
                        cols="12"
                        sm="4"
                        v-for="(permission, index) in myPermissions"
                        :key="index"
                    >
                        <v-card>
                            <v-card-title class="permission-title">{{permission.title}}</v-card-title>
                            <v-card-text style="padding-top: 10px !important;">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        v-for="(option , i) in permission.options"
                                        :key="i"
                                    >
                                        <v-checkbox
                                            v-model="roleModel.permissions"
                                            :label="option.label"
                                            :value="option.value"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions style="padding: 0px 20px 20px">
            <submit-btn @click="submit" :loading="roleBase.loading" />
        </v-card-actions>
    </v-card>
    <loader v-else />
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useRoleStore } from "../store/RoleStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        Loader
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Role",
            titleEdit: "Edit Role",
            myPermissions: [
                {
                    title: "Employee",
                    options: [
                        {
                            value: 'employee_view',
                            label: 'View'
                        },
                        {
                            value: 'employee_add',
                            label: 'Add'
                        },
                        {
                            value: 'employee_edit',
                            label: 'Edit'
                        },
                        {
                            value: 'employee_delete',
                            label: 'Delete'
                        },
                        {
                            value: 'employee_details',
                            label: 'Details'
                        },
                    ]
                },
                {
                    title: "Department",
                    options: [
                        {
                            value: 'company_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Designation",
                    options: [
                        {
                            value: 'designation_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Holiday",
                    options: [
                        {
                            value: 'holiday_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Leave",
                    options: [
                        {
                            value: 'leave_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Attendance",
                    options: [
                        {
                            value: 'attendance_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Client",
                    options: [
                        {
                            value: 'client_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Fund",
                    options: [
                        {
                            value: 'fund',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Account",
                    options: [
                        {
                            value: 'account_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Expense",
                    options: [
                        {
                            value: 'expense_add',
                            label: 'Add'
                        }
                    ]
                },
                {
                    title: "Wallets",
                    options: [
                        {
                            value: 'wallets',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Salaries",
                    options: [
                        {
                            value: 'salary_view',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Over Time",
                    options: [
                        {
                            value: 'overtime',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Paychecks",
                    options: [
                        {
                            value: 'paychecks_view',
                            label: 'View'
                        },
                        {
                            value: 'paycheck_stauts',
                            label: 'Update Status'
                        },
                    ]
                },
                {
                    title: "Project",
                    options: [
                        {
                            value: 'project_view',
                            label: 'View'
                        },
                        {
                            value: 'project_add',
                            label: 'Add'
                        },
                        {
                            value: 'project_edit',
                            label: 'Edit'
                        },
                        {
                            value: 'project_delete',
                            label: 'Delete'
                        },
                    ]
                },
                {
                    title: "Task",
                    options: [
                        {
                            value: 'task_view',
                            label: 'View'
                        },
                        {
                            value: 'task_add',
                            label: 'Add'
                        },
                        {
                            value: 'task_edit',
                            label: 'Edit'
                        },
                        {
                            value: 'task_delete',
                            label: 'Delete'
                        },
                        {
                            value: 'task_details',
                            label: 'Details'
                        }
                    ]
                },
                {
                    title: "Episode",
                    options: [
                        {
                            value: 'project_details',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Settings",
                    options: [
                        {
                            value: 'settings',
                            label: 'All'
                        }
                    ]
                },
                {
                    title: "Role & Permission",
                    options: [
                        {
                            value: 'group_permission',
                            label: 'All'
                        }
                    ]
                },
                
            ],
            route: '',
            role_id: ''
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useRoleStore, ["roleBase", "roleModel"]),
        ...mapWritableState(useRoleStore, ["show"]),
    },
    methods: {
        ...mapActions(useRoleStore, ['addRole', 'editRole', 'clearRoleModel', 'getRoleById']),
        submit() {
            if (this.$refs.form.validate()) {
                this.roleBase.ifEdit
                    ? this.editRole(this.createParamEdit())
                    : this.addRole(this.createParamAdd())
            }
        },
        createParamAdd() {
            const permissionSend = []
            this.roleModel.permissions.forEach(item => {
                permissionSend.push({name:item})
            })
            const params = {
                role_name: this.roleModel.role_name,
                permissions: permissionSend
            }
            return params
        },
        createParamEdit() {
            const permissionSend = []
            this.roleModel.permissions.forEach(item => {
                permissionSend.push({name:item})
            })
            const params = {
                id: this.role_id,
                role_name: this.roleModel.role_name,
                permissions: permissionSend
            }
            return params
        },
    },
    mounted() {
        this.route = this.$route.name
        if (this.route == 'roles-add')
            this.roleBase.ifEdit = false
        else{
            this.roleBase.ifEdit = true
            this.role_id = this.$route.params.id
            this.getRoleById(this.role_id)
        }
    }
};
</script>
<style>
/* .role-form .col-12 { */
/* padding: 0px 15px !important;
} */
.permission-title {
    padding: 20px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #cfcfcf;
}
.v-input--checkbox {
    margin: 0px !important;
}
.v-input--checkbox label {
    margin: 0px !important;
}
.v-input--selection-controls {
    margin: 10px 0px;
}
.permission-cards .col-sm-6 {
    padding-bottom: 0px;
}
</style>
