import Client from "@/clients/AxiosClient";

export default {
    get() {
        return Client.get("/overtime");
    },

    edit(formData) {
        return Client.post(`/update_overtime`, formData);
    },
};