<template>
    <div class="departments">
        <div class="main-wrapper">
            <translation-sidebar v-role="'admin'" />
            <all-sidebar v-role="'employee'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Task Details</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/translation">Translation</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/translation/projects">Projects</router-link>
                                    </li>
                                    <li class="breadcrumb-item" v-if="finishLoad()">
                                        <a @click="pushRoute()">Tasks</a>
                                    </li>
                                    <li class="breadcrumb-item active" v-if="finishLoad()">{{taskDetails.title}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <task-details-table />
                        </div>
                        <div v-if="error()">
                            <no-internet @retry="onRetry" :loading="projectBase.loadingFetch"></no-internet>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useTaskStore } from "../store/TaskStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import TranslationLayout from "@/modules/translation/core/layouts/TranslationLayout.vue"
import TaskDetailsTable from "../components/TaskDetailsTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import TranslationSidebar from '../../core/components/TranslationSidebar.vue'
import AllSidebar from "@/components/main/AllSidebar.vue"

export default {
    components: {
        TaskDetailsTable,
        NoInternet,
        TranslationLayout,
        TranslationSidebar,
        AllSidebar,
    },
    data: () => ({
        cardTitle: "Task List",
        btnTitle: "Add Task",
        task_id: '',
    }),
    computed: {
        ...mapState(useTaskStore, ['taskBase', 'taskDetails']),
    },
    methods: {
        ...mapActions(useTaskStore, ['getTaskById']),
        onRetry() {
            this.getTaskById(this.task_id)
        },
        finishLoad() {
            return (!this.taskBase.loadingFetch) ? true : false
        },
        inLoad() {
            return (this.taskBase.loadingFetch) ? true : false
        },
        error() {
            return (this.taskBase.errorFetch && !this.taskBase.loadingFetch) ? true : false
        },
        pushRoute(){
            this.$router.push(`/translation/project/${this.taskDetails.project_id}`)
        }
    },
    mounted() {
        this.task_id = this.$route.params.id
        this.getTaskById(this.task_id)
    }
};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
