<template>
    <form-dialog
        :title="!overtimeBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="750px"
    >
        <template v-slot:inputs>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="overtimeModel.hour" label="hour" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="overtimeModel.price" label="price" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="overtimeBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useOvertimeStore } from "../store/OvertimeStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        CancelBtn,
        NumberInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Overtime",
            titleEdit: "Edit Overtime",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useOvertimeStore, ["overtimeBase", "overtimeModel"]),
        ...mapWritableState(useOvertimeStore, ["show"]),
    },
    methods: {
        ...mapActions(useOvertimeStore, ['editOvertime']),
        submit() {
            if (this.$refs.form.validate()) {
                this.editOvertime(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('id', 1)
            formData.append('hour', this.overtimeModel.hour)
            formData.append('price', this.overtimeModel.price)
            return formData
        },
        onCancel() {
            this.show = false
            this.overtimeBase.ifEdit = false
            this.$refs.form.resetValidation()
        }

    },
    mounted(){
    }
};
</script>
