<template>
    <form-dialog
        :title="!designationBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
    >
        <template v-slot:inputs>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12">
                        <text-input :text.sync="designationModel.name" label="Designation Name"/>
                    </v-col>
                    <v-col cols="12">
                        <select-input :model.sync="designationModel.department_id" :items="departmentList" label="Department" item_text="name" item_value="id" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="designationBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useDesignationStore } from "../store/DesignationStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import { useDepartmentStore } from "@/modules/hr/department/store/DepartmentStore"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Designation",
            titleEdit: "Edit Designation",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useDesignationStore, ["designationBase", "designationModel"]),
        ...mapWritableState(useDesignationStore, ["show"]),
        ...mapState(useDepartmentStore, ['departmentList'])
    },
    methods: {
        ...mapActions(useDesignationStore, ['addDesignation', 'editDesignation', 'clearDesignationModel']),
        ...mapActions(useDepartmentStore, ['fetchAllDepartment']),
        submit() {
            if (this.$refs.form.validate()) {
                this.designationBase.ifEdit
                    ? this.editDesignation(this.designationModel.id, this.createFormData())
                    : this.addDesignation(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('designation', this.designationModel.name)
            formData.append('company_id', this.designationModel.department_id)
            return formData
        },
        onCancel() {
            this.show = false
            this.designationBase.ifEdit = false
            this.clearDesignationModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted(){
        this.fetchAllDepartment()
    }
};
</script>
