<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="attendanceBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
                <month-input :date.sync="date" label="Filter by date" @ok="filterByDate" />
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="attendanceList"
            :search="attendanceBase.search"
            class="my-table"
            :loading="attendanceBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="attendanceBase.dialogDeleteModel"
                    :loading="attendanceBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue'
import { useAttendanceStore } from "../store/AttendanceStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import { useLangStore } from "@/store/LangStore"
import MonthInput from "@/components/inputs/MonthInput.vue"

export default {
    components: { ConfirmDeleteDialog, MonthInput },
    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Department",
                    value: "company_id",
                    align: "center"
                },
                {
                    text: "Date",
                    value: "date",
                    align: "center"
                },
                {
                    text: "Time In",
                    value: "clock_in",
                    align: "center"
                },
                {
                    text: "Time Out",
                    value: "clock_out",
                    align: "center"
                },
                {
                    text: "Work Duration",
                    value: "total_work",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "type",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useAttendanceStore, ['attendanceBase', 'attendanceList']),
    },

    methods: {
        ...mapActions(useAttendanceStore, ['fetchAllAttendance', 'deleteAttendance']),

        deleteItem(item) {
            this.attendanceBase.editedIndex = this.attendanceList.indexOf(item)
            this.attendanceBase.delete = item
            this.attendanceBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('id', this.attendanceBase.delete.id)
            this.attendanceBase.loading = true
            this.deleteAttendance(formData)
        },

        filterByDate(){
            this.fetchAllAttendance(this.date.split("-").reverse().join("-"))
        }
    },
    mounted() {
        const date = `${new Date().getMonth() + 1}-${new Date().getFullYear()}`
        this.fetchAllAttendance(date)
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>