import Project from "@/modules/translation/project/pages/ProjectListView.vue"
import ProjectDetails from "@/modules/translation/project/pages/ProjectDetailsView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/translation/projects",
    component: BaseFormPageWrapper,
    children: [
        {
            path: '/translation/projects',
            name: "projects",
            component: Project,
            meta: { title: "Project", permission: "project_view" }
        },
        {
            path: '/translation/project/:project_id',
            name: "project-details",
            component: ProjectDetails,
            meta: { title: "Project Details", permission: "project_view" }
        },
    ]
};
