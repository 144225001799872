<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    v-model="taskBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="employeeTaskDetails"
            :search="taskBase.search"
            class="my-table"
            :loading="taskBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <add-file-dialog :item="addFileItem"/>
            </template>
            <template v-slot:[`item.file`]="{ item }">
                <v-btn @click="displayAddFile(item)" outlined style="color: var(--v-primary-base)" v-if="item.status == 0">
                    New <v-icon>mdi-plus</v-icon>
                </v-btn>
                <div v-else>
                    <a :href="item.attachment1" target="_blank" :download="item.attachment1">
                        <!-- <b-icon icon="link45deg"></b-icon> -->
                        {{ getFileName(item.attachment1) }}
                    </a>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-icon color="green" v-if="item.status == 1">mdi-check</v-icon>
                <v-icon color="red" v-if="item.status == 0">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.end_date`]="{ item }">
                <div> {{item.end_date.slice(0, 10)}} </div>
            </template>
            <template v-slot:[`item.task_type`]="{ item }">
                <div v-if="item.task_type == 'trans'">
                    Translation
                </div>
                <div v-if="item.task_type == 'syn'">
                    Synchronizer
                </div>
                <div v-if="item.task_type == 'audit'">
                    Audit
                </div>
                <div v-if="item.task_type == 'poly'">
                    Poly
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { useTaskStore } from "@/modules/translation/task/store/TaskStore"
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import AddFileDialog from '@/modules/translation/task/components/AddFileDialog.vue';
export default {
    components:{AddFileDialog},
    data() {
        return {
            headers: [
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Minutes From",
                    value: "minutes_from",
                    align: "center"
                },
                {
                    text: "Minutes To",
                    value: "minutes_to",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "task_type",
                    align: "center"
                },
                {
                    text: "Price",
                    value: "price",
                    align: "center"
                },
                {
                    text: "Finish Date",
                    value: "end_date",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center"
                },
                {
                    text: "File",
                    value: "file",
                    align: "center",
                    sortable: false
                }
            ],  
            display: null,
            addFileItem: {}    
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useTaskStore, ['taskBase', 'employeeTaskDetails']),
        ...mapWritableState(useTaskStore, ['show'])
    },

    methods: {
        ...mapActions(useTaskStore, ['addFile']),
        displayAddFile(item){
            this.show = true
            this.addFileItem = item
        },
        getFileName(url) {
            const parts = url.split("/");
            return parts[parts.length - 1];
        }

    },
    mounted() {
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.btn-status .v-btn{
    padding: 0px !important;
}
.v-data-table ul{
    list-style: disc !important;
}
</style>