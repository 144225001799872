<template>
    <div class="departments">
        <div class="main-wrapper">
            <hr-sidebar v-role="'admin'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Roles</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/roles">Roles</router-link>
                                    </li>
                                    <li class="breadcrumb-item" v-if="!roleBase.ifEdit">
                                        <router-link to="/roles/add">Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" v-else>Edit</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <role-form />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoleStore } from "../store/RoleStore"
import { mapActions, mapState } from "pinia"
import RoleForm from "../components/RoleForm.vue"
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
import AllSidebar from '@/components/main/AllSidebar.vue'

export default {
    components: {
        RoleForm,
        HrSidebar,
        AllSidebar,
    },
    data: () => ({

    }),
    computed: {
        ...mapState(useRoleStore, ['roleBase']),
    },
    methods: {
        // ...mapActions(useRoleStore, ['fetchAllRole']),
    },
    mounted() {
        const role = this.$route.name
        if (role == 'roles-add')
            this.roleBase.ifEdit = false
        else
            this.roleBase.ifEdit = true
    }
};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
