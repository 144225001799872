<template>
    <v-dialog v-model="filesTaskShow" max-width="1000px">
        <v-card style="padding:10px 0px 20px">
            <v-card-title style=" justify-content: center;margin-bottom: 20px">
                <span class="text-h5 color-primary">Files Task</span>
                <v-btn class="btn-close" @click="filesTaskShow = false">
                    <v-icon style="font-size: 18px;">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table 
                    :headers="headers"
                    :items="filesList"
                    class="my-table"
                    :loading="taskBase.loading"
                    loading-text="Loading... Please wait"
                    style="margin: 20px 0px"
                >
                    <template v-slot:[`item.attachment1`]="{ item }">
                        <div>
                            <a
                                :href="item.attachment1"
                                target="_blank"
                                :download="item.attachment1"
                            >{{ getFileName(item.attachment1) }}</a>
                        </div>
                    </template>
                    <template v-slot:[`item.task_type`]="{ item }">
                        <div v-if="item.task_type == 'trans'">
                            Translation
                        </div>
                        <div v-if="item.task_type == 'syn'">
                            Synchronizer
                        </div>
                        <div v-if="item.task_type == 'audit'">
                            Audit
                        </div>
                        <div v-if="item.task_type == 'poly'">
                            Poly
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { useTaskStore } from "../store/TaskStore"
import { mapActions, mapState, mapWritableState } from "pinia"
// import { useProjectStore } from "@/modules/translation/project/store/ProjectStore"
import Loader from "@/components/main/Loader.vue"
export default {
    components: {
        Loader
    },
    data() {
        return {
            headers: [
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "task_type",
                    align: "center"
                },
                {
                    text: "File",
                    value: "attachment1",
                    align: "center",
                    sortable: false
                }
            ],  
        };
    },
    computed: {
        ...mapState(useTaskStore, ["taskBase", 'filesList']),
        ...mapWritableState(useTaskStore, ['filesTaskShow']),
        // ...mapState(useProjectStore, ['projectDetails'])
    },
    methods: {
        ...mapActions(useTaskStore, ['filesTask']),
        getFileName(url) {
            const parts = url.split("/");
            return parts[parts.length - 1];
        }
    },
    mounted() {
        // this.filesTask(this.projectDetails.tasks[0].id)
    }
};
</script>
