import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import LeaveService from "../services/LeaveService";

export const useLeaveStore = defineStore("leaveStore", {
    state: () => ({
        leaveBase: new BaseState(),
        leaveModel: {
            id: '',
            employee_id: '',
            department_id: '',
            start_date: '',
            end_date: '',
            reason: ''
        },
        leaveList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        clearLeaveModel() {
            this.leaveModel.id = ''
            this.leaveModel.employee_id = ''
            this.leaveModel.department_id = ''
            this.leaveModel.start_date = ''
            this.leaveModel.end_date = ''
            this.leaveModel.reason = ''
        },
        setLeaveModel(item) {
            this.leaveModel.id = item.id
            this.leaveModel.employee_id = item.employee_id
            this.leaveModel.department_id = item.company_id
            this.leaveModel.start_date = item.start_date
            this.leaveModel.end_date = item.end_date
            this.leaveModel.reason = item.reason
        },
        addLeave(formData) {
            this.leaveBase.loading = true;
            LeaveService.add(formData).then((res) => {
                this.leaveBase.loading = false
                this.show = false
                this.fetchAllLeave()
                this.clearLeaveModel()
            })
                .catch((err) => {
                    this.leaveBase.loading = false
                    this.leaveBase.error = true
                });
        },
        fetchAllLeave() {
            this.leaveBase.loadingFetch = true
            LeaveService.getAll().then((res) => {
                this.leaveList = res.data.list.reverse()
                this.leaveBase.loadingFetch = false
                this.leaveBase.errorFetch = false
            })
                .catch((err) => {
                    this.leaveBase.loadingFetch = false
                    this.leaveBase.errorFetch = true
                });
        },
        deleteLeave(formData) {
            LeaveService.delete(formData).then((res) => {
                this.leaveBase.loading = false
                this.leaveList.splice(this.leaveBase.editedIndex, 1)
                this.leaveBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.leaveBase.loading = false
                    this.leaveBase.error = true
                })
        },
        getLeaveById(id) {
            this.leaveBase.loading = true
            LeaveService.getById(id).then((res) => {
                this.leaveBase.loading = false
                this.leaveModel.id = res.data.list.id
                this.leaveModel.employee_id = res.data.list.employee_id
            })
                .catch((err) => {
                    this.leaveBase.loading = false
                    this.leaveBase.error = true
                })
        },
        editLeave(id, formData) {
            formData.append('id', id)
            this.leaveBase.loading = true
            LeaveService.edit(formData).then((res) => {
                this.leaveBase.loading = false
                this.leaveBase.ifEdit = false
                this.show = false
                this.fetchAllLeave()
                this.clearLeaveModel()
            })
                .catch((err) => {
                    this.leaveBase.loading = false
                    this.leaveBase.error = true
                });
        },
        changeStatusLeave(id, status) {
            LeaveService.changeStatus(id, status).then((res) => {
                // this.leaveBase.loading = false
                this.fetchAllLeave()
            })
        }
    },
});
