import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import FundService from "../services/FundService";

export const useFundStore = defineStore("fundStore", {
    state: () => ({
        fundBase: new BaseState(),
        fundModel: null,
        fundList: [],

// Expense
        expenseBase: new BaseState(),
        expenseModel: {
            type: '',
            amount: '',
            date: '',
            description: '',
            department: '',
        },
        expenseList: [],
        expenseFormShow: false,
        expenseTableShow: false,

// Income
        incomeBase: new BaseState(),
        incomeModel: {
            type: '',
            income_amount: '',
            date: '',
            description: '',
            client: '',
        },
        incomeList: [],
        incomeFormShow: false,
        incomeTableShow: false,

// Total
        totalTableShow: false,
    }),
    actions: {
        // Expense
        clearExpenseModel() {
            this.expenseModel.type = ''
            this.expenseModel.amount = ''
            this.expenseModel.date = ''
            this.expenseModel.description = ''
            this.expenseModel.department = ''
        },
        addExpense(formData) {
            this.expenseBase.loading = true;
            FundService.addExpense(formData).then((res) => {
                this.expenseBase.loading = false
                this.expenseFormShow = false
                this.fetchFund()
                this.clearExpenseModel()
            })
                .catch((err) => {
                    this.expenseBase.loading = false
                    this.expenseBase.error = true
                });
        },
        deleteExpense(formData) {
            FundService.deleteExpense(formData).then((res) => {
                this.fundBase.loading = false
                this.fetchFund()
                this.fundBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.fundBase.loading = false
                    this.fundBase.error = true
                })
        },
        filterExpense(start_date, end_date){
            this.expenseBase.loadingFetch = true
            FundService.filterExpense(start_date, end_date).then((res) => {
                this.expenseList = res.data.list
                this.expenseBase.loadingFetch = false
                this.expenseBase.errorFetch = false
            })
                .catch((err) => {
                    this.expenseBase.loadingFetch = false
                    this.expenseBase.errorFetch = true
                });
        },

        // Income
        clearIncomeModel() {
            this.incomeModel.type = ''
            this.incomeModel.income_amount = ''
            this.incomeModel.date = ''
            this.incomeModel.description = ''
            this.incomeModel.client = ''
        },
        addIncome(formData) {
            this.incomeBase.loading = true;
            FundService.addIncome(formData).then((res) => {
                this.incomeBase.loading = false
                this.incomeFormShow = false
                this.fetchFund()
                this.clearIncomeModel()
            })
                .catch((err) => {
                    this.incomeBase.loading = false
                    this.incomeBase.error = true
                });
        },
        deleteIncome(formData) {
            FundService.deleteIncome(formData).then((res) => {
                this.fundBase.loading = false
                this.fetchFund()
                this.fundBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.fundBase.loading = false
                    this.fundBase.error = true
                })
        },
        filterIncome(start_date, end_date){
            this.incomeBase.loadingFetch = true
            FundService.filterIncome(start_date, end_date).then((res) => {
                this.incomeList = res.data.list
                this.incomeBase.loadingFetch = false
                this.incomeBase.errorFetch = false
            })
                .catch((err) => {
                    this.incomeBase.loadingFetch = false
                    this.incomeBase.errorFetch = true
                });
        },

        fetchFund(start_date, end_date) {
            this.fundBase.loadingFetch = true
            FundService.getFund(start_date, end_date).then((res) => {
                this.fundList = []
                this.fundModel = res.data.list
                this.expenseList = this.fundModel.expenses
                this.incomeList = this.fundModel.incomes
                this.fundModel.expenses.forEach(item =>{
                    this.fundList.push(item)
                })
                this.fundModel.incomes.forEach(item =>{
                    this.fundList.push(item)
                })
                // this.fundList = this.fundList.sort(
                //     (objA, objB) => (objB.id) - (objA.id),
                // );
                this.fundBase.loadingFetch = false
                this.fundBase.errorFetch = false
            })
                .catch((err) => {
                    this.fundBase.loadingFetch = false
                    this.fundBase.errorFetch = true
                });
        },

    },
});
