<template>
    <v-row>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fa fa-cubes"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].num_projects}}</h4>
                        <h6 class="client-card-title">Projects</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-film"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].num_episodes}}</h4>
                        <h6 class="client-card-title">Episodes</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-hourglass-start"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].num_minutes}}</h4>
                        <h6 class="client-card-title">Total Minutes</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-hourglass-half"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].completed_minutes}}</h4>
                        <h6 class="client-card-title">Minutes</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-donate"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].total_price}}</h4>
                        <h6 class="client-card-title">Total Price</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-money-bill-alt"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].current_price}}</h4>
                        <h6 class="client-card-title">Current Price</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="fas fa-hand-holding-usd"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].paid_price}}</h4>
                        <h6 class="client-card-title">Paid Price</h6>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" sm="3">
            <div class="card dash-widget">
                <div class="card-body">
                    <span class="dash-widget-icon">
                        <i class="far fa-money-bill-alt"></i>
                    </span>
                    <div class="dash-widget-info" style="justify-content: center">
                        <h4>{{clientDetailsModel.statics[0].current_price - clientDetailsModel.statics[0].paid_price}}</h4>
                        <h6 class="client-card-title">Unpaid Price</h6>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        clientDetailsModel: {
            required: true
        }
    }

}
</script>

<style>
</style>