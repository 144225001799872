import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_client", formData);
    },

    getAll() {
        return Client.get("/clients");
    },

    getById(id) {
        return Client.get(`/client?client_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_client`, formData);
    },

    delete(formData) {
        return Client.post(`/delete_client`, formData);
    },

    clientDetails(id, date) {
        if (date)
            return Client.get(`/client_finincial?client_id=${id}&date=${date}`)
        else
            return Client.get(`/client_finincial?client_id=${id}`);
    }
};