<template>
    <v-text-field
        outlined
        :reverse="getDirection"
        v-model="newNumber"
        :rules="[ifNotReq ? noRule : requiredRule, numberRule]"
        :label="label"
        validate-on-blur
        :readonly="readonly"
        :disabled="disabled"
    ></v-text-field>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "NumberInput",
    props:{
        number: {
            required: true
        },
        label: {
            type: String, 
            required: true
        },
        ifNotReq: {
            type: Boolean
        },
        readonly: {
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean,
        }
    },
    mixins: [FormValidationRulesMixin],
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newNumber: {
            get() {
                return this.number
            },
            set(value) {
                this.$emit('update:number', value)
            }
        },
    },
}
</script>

<style>
</style>