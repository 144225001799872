<template>
    <v-snackbar left top :color="color" text v-model="snackbar" timeout="4000">
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn :color="color" text v-bind="attrs" @click="snackbar = false">{{ $t('close') }}</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "CustomToast",
    props: {
        message: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            snackbar: true,
        };
    },
    
};
</script>
