<template>
    <div>
        <v-row>
            <v-col cols="12" lg="3" style="padding: 0px 16px">
                <v-text-field
                    v-model="clientBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" style="padding: 0px 16px">
                <month-input :date.sync="date" label="Date" @ok="filterByDate"/>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="clientDetailsModel.client_finincial"
            :search="clientBase.search"
            class="my-table"
            loading-text="Loading... Please wait"
            :loading="clientBase.loadingFetch"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Total Minutes:</span>
                        {{ item.total_minutes }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Completed Minutes:</span>
                        {{ item.completed_minutes }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Total Price:</span>
                        {{ item.price }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Current Price:</span>
                        {{ item.current_price }}
                    </div>
                </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="btn-status">
                    <v-chip rounded color="warning" outlined v-if="item.status == 'in_progress'">
                        In Progress
                    </v-chip>
                    <v-chip rounded color="success" outlined v-if="item.status == 'completed'">
                        Completed 
                    </v-chip>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { useLangStore } from "@/store/LangStore"
import { useClientStore } from "../store/ClientStore";
import MonthInput from "@/components/inputs/MonthInput.vue"

export default {
    components: { MonthInput },

    data() {
        return {
            headers: [
                {
                    text: "Project",
                    value: "project",
                    align: "center"
                },
                {
                    text: "Channel",
                    value: "channel",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center"
                },
                {
                    text: "Episodes",
                    value: "episodes",
                    align: "center"
                },
                {
                    text: "Completed Episodes",
                    value: "completed_episodes",
                    align: "center"
                },
                {
                    text: "Incompleted Episodes",
                    value: "in_completed_episodes",
                    align: "center"
                },
            ],
            expanded: [],
            date: '',
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useClientStore, ['clientBase', 'clientDetailsModel'])
    },

    methods:{
        ...mapActions(useClientStore, ['fetchClientDetails']),
        filterByDate(){
            const id = this.$route.params.id
            this.fetchClientDetails(id, this.date)
        }
    }

};
</script>
<style>
@import url(@/assets/css/main.css);
</style>