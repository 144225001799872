export class User {
  id;
  name;
  token;
  email;
  role;

  constructor(id, email, name, role, token) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.role = role;
    this.token = token;
  }
}
