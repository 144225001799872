import Client from "@/clients/AxiosClient";

export default {
    getAll(date) {
        if (date)
            return Client.get(`/salaries?date=${date}`);
        else
            return Client.get("/salaries");
    },

    pay(formData) {
        return Client.post(`/pay_salary`, formData);
    },

    paycheckAmount(formData){
        return Client.post('/set_paychecks_amount', formData)
    }

};