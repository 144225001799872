<template>
    <div class="main-wrapper">
        <all-sidebar v-role="'manager'" />
        <div class="page-wrapper">
            <div class="content container-fluid">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">Dashboard</h3>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/manager-dashboard">Latest Tasks</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="!managerDashboardBase.errorFetch">
                        <managerDashboard-table />
                    </div>
                    <div v-else>
                        <no-internet @retry="onRetry" :loading="managerDashboardBase.loadingFetch"></no-internet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useManagerDashboardStore } from "../store/ManagerDashboardStore"
import { mapActions, mapState } from "pinia"
import ManagerDashboardTable from "../components/ManagerDashboardTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import AllSidebar from "@/components/main/AllSidebar.vue"
export default {
    components: {
        ManagerDashboardTable,
        NoInternet,
        AllSidebar
    },
    data: () => ({
    }),
    computed: {
        ...mapState(useManagerDashboardStore, ['managerDashboardBase']),
    },
    methods: {
        ...mapActions(useManagerDashboardStore, ['fetchManagerDashboard']),
        onRetry() {
            this.fetchManagerDashboard()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
