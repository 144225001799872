import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import AttendanceService from "../services/AttendanceService";

export const useAttendanceStore = defineStore("attendanceStore", {
    state: () => ({
        attendanceBase: new BaseState(),
        attendanceList: [],
        monthly: [],
    }),
    actions: {
        fetchAllAttendance(date) {
            this.attendanceBase.loadingFetch = true
            AttendanceService.getAll(date).then((res) => {
                this.attendanceList = res.data.list.reverse()
                this.attendanceBase.loadingFetch = false
                this.attendanceBase.errorFetch = false
            })
                .catch((err) => {
                    this.attendanceBase.loadingFetch = false
                    this.attendanceBase.errorFetch = true
                });
        },
        fetchMonthlyAttendance(date) {
            this.attendanceBase.loadingFetch = true
            AttendanceService.getMonthly(date).then((res) => {
                this.monthly = res.data.list
                this.attendanceBase.loadingFetch = false
                this.attendanceBase.errorFetch = false
                this.monthly = this.monthly.reduce((acc, item) => {
                    const existingEmployee = acc.find(emp => emp.employee_id === item.employee_id);
                    if (existingEmployee) {
                        existingEmployee.dates.push(item.date);
                    } else {
                        const newEmployee = {
                            employee_id: item.employee_id,
                            employee_name: item.employee.username,
                            dates: [item.date]
                        };
                        acc.push(newEmployee);
                    }
                    return acc;
                }, []);
                // console.log(this.monthly);
            })
                .catch((err) => {
                    this.attendanceBase.loadingFetch = false
                    this.attendanceBase.errorFetch = true
                });
        }
    },
});
