<template>
    <v-text-field
        outlined
        prepend-inner-icon="mdi-key"
        v-model="newPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        :rules="[requiredRule, passwordRule]"
        :label="label"
        validate-on-blur
    ></v-text-field>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "PasswordInput",
    data(){
        return {
            showPassword: false,
        };
    },
    props: {
        password: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
    },
    mixins: [FormValidationRulesMixin],
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newPassword: {
            get() {
                return this.password
            },
            set(value) {
                this.$emit('update:password', value)
            }
        },
    },
}
</script>

<style>
</style>