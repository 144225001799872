<template>
    <form-dialog
        :title="!episodeBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="850px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="episode-form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="episodeModel.title" label="Episode Name" />
                    </v-col>
                    <!-- <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="episodeModel.project_id"
                            :items="projectList"
                            label="Project"
                            item_text="title"
                            item_value="id"
                            :readonly="true" 
                        />
                    </v-col> -->
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="episodeModel.duration" label="Episode Duration" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="episodeModel.syn"
                            :items="selectList"
                            label="Episode Sync"
                            item_text="text"
                            item_value="value"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="episodeModel.script"
                            :items="selectList"
                            label="Episode Script"
                            item_text="text"
                            item_value="value"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <url-input :url.sync="episodeModel.text" label="Episode Text" :ifNotReq="true" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-file-input
                            outlined
                            chips
                            :reverse="getDirection"
                            v-model="episodeModel.episode_file"
                            label="Episode File"
                            append-icon="mdi-paperclip"
                            prepend-icon
                            show-size
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <url-input :url.sync="episodeModel.url" label="Url" :ifNotReq="true" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <video-input :video.sync="episodeModel.video"  label="Episode video" :ifReq="false" />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="episodeModel.description" label="Description" :ifReq="false" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="episodeBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useEpisodeStore } from "../store/EpisodeStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import { useProjectStore } from "@/modules/translation/project/store/ProjectStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
import NumberInput from "@/components/inputs/NumberInput.vue"
import VideoInput from "@/components/inputs/VideoInput.vue"
import UrlInput from "@/components/inputs/UrlInput.vue"
export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        TextareaInput,
        NumberInput,
        VideoInput,
        UrlInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Episode",
            titleEdit: "Edit Episode",
            project_id: '',
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useEpisodeStore, ["episodeBase", "episodeModel", 'selectList']),
        ...mapWritableState(useEpisodeStore, ["show"]),
        ...mapState(useProjectStore, ['projectList']),
    },
    methods: {
        ...mapActions(useEpisodeStore, ['addEpisode', 'editEpisode', 'clearEpisodeModel']),
        ...mapActions(useProjectStore, ['fetchAllProject']),
        allowedDatesEnd: val => val > new Date().toJSON().slice(0, 10),
        submit() {
            if (this.$refs.form.validate()) {
                this.episodeBase.ifEdit
                    ? this.editEpisode(this.episodeModel.id, this.createFormData())
                    : this.addEpisode(this.createFormData())
            }
        },
        createFormData() {
            this.episodeModel.project_id = parseInt(this.$route.params.project_id)
            const formData = new FormData()
            formData.append('project_id', this.episodeModel.project_id)
            formData.append('title', this.episodeModel.title)
            formData.append('duration', this.episodeModel.duration)
            formData.append('syn', this.episodeModel.syn)
            formData.append('script', this.episodeModel.script)
            formData.append('text', this.episodeModel.text)
            formData.append('episode_file', this.episodeModel.episode_file)
            formData.append('url', this.episodeModel.url)
            formData.append('video', this.episodeModel.video)
            formData.append('description', this.episodeModel.description)
            return formData
        },
        onCancel() {
            this.show = false
            this.episodeBase.ifEdit = false
            this.clearEpisodeModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        // this.fetchAllProject()
        this.episodeModel.project_id = parseInt(this.$route.params.project_id)

    }
};
</script>

<style>
.episode-form .col-12 {
    padding: 0px 15px !important;
}

</style>