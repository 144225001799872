import Client from "@/clients/AxiosClient";

export default {
    getAll(date) {
        return Client.get(`/attendences?filter_date=${date}`);
    },

    getMonthly(date) {
        return Client.get(`/monthly_attendances?date=${date}`);
    },

};