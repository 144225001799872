<template>
    <v-dialog v-model="expenseTableShow" max-width="1000px">
        <v-card style="padding:10px 0px 20px">
            <v-card-title style=" justify-content: center;margin-bottom: 20px">
                <span class="text-h5 color-primary">Expense: {{fundModel.expenses_amount}}</span>
                <v-btn class="btn-close" @click="expenseTableShow = false">
                    <v-icon style="font-size: 18px;">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row style="margin-top: 30px">
                    <v-col cols="12" sm="4" style="padding: 0px 16px;">
                        <v-text-field
                            v-model="expenseBase.search"
                            append-icon="mdi-magnify"
                            label="Search"
                            :reverse="getDirection"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" style="padding: 0px 16px">
                        <month-input type="date" :date.sync="start_date" label="Start Date" />
                    </v-col>
                    <v-col cols="12" sm="4" style="padding: 0px 16px">
                        <month-input
                            type="date"
                            :date.sync="end_date"
                            label="End Date"
                            @ok="filterByDate"
                        />
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :search="expenseBase.search"
                    :items="expenseList"
                    class="my-table"
                    style="margin-bottom: 50px"
                    :loading="expenseBase.loadingFetch"
                ></v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { useFundStore } from "../../store/FundStore"
import { useLangStore } from "@/store/LangStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import MonthInput from "@/components/inputs/MonthInput.vue"
export default {
    components: {
        MonthInput,
    },
    data() {
        return {
            search: '',
            headers: [
                {
                    text: "Amount",
                    value: "amount",
                    align: "center"
                },
                {
                    text: "Date",
                    value: "date",
                    align: "center"
                },
                {
                    text: "Description",
                    value: "description",
                    align: "center"
                },
                {
                    text: "Type",
                    value: "type",
                    align: "center"
                },
            ],
            start_date: '',
            end_date: '',
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useFundStore, ["expenseBase", 'expenseList', 'fundModel']),
        ...mapWritableState(useFundStore, ['expenseTableShow'])

    },
    methods: {
        ...mapActions(useFundStore, ['filterExpense']),

        filterByDate(){
            console.log(this.start_date)
            console.log(this.end_date)
            this.filterExpense(this.start_date, this.end_date)
        }

    },
    mounted() {
    }
};
</script>
