<template>
    <form-dialog
        :title="!expenseBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="750px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="expense-form">
                <v-row>
                    <v-col cols="12">
                        <number-input :number.sync="expenseModel.amount" label="Amount" />
                    </v-col>
                    <v-col cols="12">
                        <date-input :date.sync="expenseModel.date" label="Date" :allowedDates="allowedDates"/>
                    </v-col>
                    <v-col cols="12">
                        <textarea-input :text.sync="expenseModel.description" label="Please provide any details" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="expenseBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useAccountStore } from "../store/AccountStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import NumberInput from "@/components/inputs/NumberInput.vue"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
export default {
    components: {
        FormDialog,
        SubmitBtn,
        CancelBtn,
        DateInput,
        NumberInput,
        TextareaInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Expense",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useAccountStore, ["expenseBase", "expenseModel"]),
        ...mapWritableState(useAccountStore, ["show"]),
    },
    methods: {
        ...mapActions(useAccountStore, ['addExpense', 'clearExpenseModel']),
        allowedDates: val => val <= new Date().toJSON().slice(0, 10),
        submit() {
            if (this.$refs.form.validate()) {
                this.addExpense(this.$route.params.id, this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('account_id', this.$route.params.id)
            formData.append('amount', this.expenseModel.amount)
            formData.append('date', this.expenseModel.date)
            formData.append('description', this.expenseModel.description)
            formData.append('expense_ref', "expense")
            return formData
        },
        onCancel() {
            this.show = false
            this.expenseBase.ifEdit = false
            this.clearExpenseModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted(){
    }
};
</script>
<style>
.expense-form .col-12{
    padding: 0px 15px !important;
}</style>