import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{staticStyle:{"padding":"0px 16px"},attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","reverse":_vm.getDirection,"outlined":"","hide-details":""},model:{value:(_vm.taskBase.search),callback:function ($$v) {_vm.$set(_vm.taskBase, "search", $$v)},expression:"taskBase.search"}})],1)],1),_c(VDataTable,{staticClass:"my-table",attrs:{"headers":_vm.headers,"items":_vm.taskDetails.employee_task,"search":_vm.taskBase.search,"loading":_vm.taskBase.loadingFetch,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('add-file-dialog',{attrs:{"item":_vm.addFileItem}})]},proxy:true},{key:`item.file`,fn:function({ item }){return [(item.status == 0)?_c(VBtn,{staticStyle:{"color":"var(--v-primary-base)"},attrs:{"outlined":""},on:{"click":function($event){return _vm.displayAddFile(item)}}},[_vm._v(" New "),_c(VIcon,[_vm._v("mdi-plus")])],1):_c('div',[_c('a',{attrs:{"href":item.attachment1,"target":"_blank","download":item.attachment1}},[_vm._v(" "+_vm._s(_vm.getFileName(item.attachment1))+" ")])])]}},{key:`item.status`,fn:function({ item }){return [(item.status == 1)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(item.status == 0)?_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:`item.task_type`,fn:function({ item }){return [(item.task_type == 'trans')?_c('div',[_vm._v(" Translation ")]):_vm._e(),(item.task_type == 'syn')?_c('div',[_vm._v(" Synchronizer ")]):_vm._e(),(item.task_type == 'audit')?_c('div',[_vm._v(" Audit ")]):_vm._e(),(item.task_type == 'poly')?_c('div',[_vm._v(" Poly ")]):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }