import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import EmployeeDashboardService from "../services/EmployeeDashboardService";

export const useEmployeeDashboardStore = defineStore("employeeDashboardStore", {
    state: () => ({
        employeeDashboardBase: new BaseState(),
        employeeDashboardList: [],
        total: '',
        employee_id: JSON.parse(localStorage.getItem('user')).id,
        clock_out: '',
    }),
    getters: {
    },
    actions: {
        fetchEmployeeDashboard() {
            this.employeeDashboardBase.loadingFetch = true
            EmployeeDashboardService.get().then((res) => {
                this.employeeDashboardList = res.data.list.data
                this.total = res.data.list.total_price
                this.employeeDashboardBase.loadingFetch = false
                this.employeeDashboardBase.errorFetch = false
            })
                .catch((err) => {
                    this.employeeDashboardBase.loadingFetch = false
                    this.employeeDashboardBase.errorFetch = true
                });
        },
        checkOut(formData){
            this.employeeDashboardBase.loading = true
            EmployeeDashboardService.checkOut(formData).then((res) => {
                this.employeeDashboardBase.loading = false
                this.employeeDashboardBase.error = false
            })
                .catch((err) => {
                    this.employeeDashboardBase.loading = false
                    this.employeeDashboardBase.error = true
                });
        }
    },
});
