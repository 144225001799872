<template>
    <div>
        <div class="main-wrapper">
            <hr-sidebar v-role="'admin'" />
            <all-sidebar v-role="'employee'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Fund</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item active">HR</li>
                                    <li class="breadcrumb-item active">Fund</li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto" v-can="'expense_add'">
                                <a class="btn add-btn" @click="expenseFormShow = true">
                                    <i class="fa fa-plus"></i>
                                    Add Expense
                                </a>
                            </div>
                            <div class="col-auto float-end ms-auto">
                                <a class="btn add-btn" @click="incomeFormShow = true">
                                    <i class="fa fa-plus"></i>
                                    Add Income
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="fund-cards" v-if="finishLoad()">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <div class="stats-info" @click="expenseTableShow = true" style="background: seashell">
                                    <h6>Expense Balance</h6>
                                    <h4>{{fundModel.expenses_amount}}</h4>
                                </div>
                                <expense-table />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div class="stats-info" @click="totalTableShow = true">
                                    <h6>Total Balance</h6>
                                    <h4>{{fundModel.incomes_amount - fundModel.expenses_amount}}</h4>
                                </div>
                                <total-table />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div class="stats-info" @click="incomeTableShow = true" style="background: rgb(240, 243, 255);">
                                    <h6>Income Balance</h6>
                                    <h4>{{fundModel.incomes_amount}}</h4>
                                    <income-table />
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="finishLoad()">
                        <fund-table />
                    </div>
                    <div v-if="inLoad()">
                        <loader />
                    </div>
                    <div v-if="error()">
                        <no-internet @retry="onRetry" :loading="fundBase.loadingFetch"></no-internet>
                    </div>
                </div>
            </div>
        </div>
        <expense-form />
        <income-form />
    </div>
</template>

<script>
import { useFundStore } from "../store/FundStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
import NoInternet from "@/components/main/NoInternet.vue"
import Loader from "@/components/main/Loader.vue"
import FundTable from "../components/FundTable.vue"
import ExpenseForm from "../components/expense/ExpenseForm.vue"
import IncomeForm from "../components/income/IncomeForm.vue"
import ExpenseTable from "../components/expense/ExpenseTable.vue"
import IncomeTable from "../components/income/IncomeTable.vue"
import TotalTable from "../components/TotalTable.vue"
import AllSidebar from "@/components/main/AllSidebar.vue"
export default {
    components: {
        FundTable,
        NoInternet,
        ExpenseForm,
        HrSidebar,
        IncomeForm,
        Loader,
        ExpenseTable,
        IncomeTable,
        TotalTable,
        AllSidebar
    },
    data: () => ({
        cardTitle: "Fund List",
        btnTitle: "Add Fund",
    }),
    computed: {
        ...mapState(useFundStore, ['fundBase', 'fundModel']),
        ...mapWritableState(useFundStore, ['totalTableShow', 'expenseFormShow', 'incomeFormShow', 'expenseTableShow', 'incomeTableShow'])
    },
    methods: {
        ...mapActions(useFundStore, ['fetchFund']),
        onRetry() {
            this.fetchFund()
        },
        finishLoad() {
            return (!this.fundBase.loadingFetch && this.fundModel != null) ? true : false
        },
        inLoad() {
            return (this.fundBase.loadingFetch) ? true : false
        },
        error() {
            return (this.fundBase.errorFetch && !this.fundBase.loadingFetch) ? true : false
        }
    },
    mounted() {
        this.fetchFund()
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
.fund-cards .stats-info:hover{
    cursor: pointer;
    transition: all .2s;
    box-shadow: 0 0 10px rgba(189, 189, 189, 0.651);
}
.fund-cards .stats-info{
    transition: all .2s;
}
</style>
