import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.roleBase.ifEdit && !_vm.roleBase.loadingFetch) || !_vm.roleBase.ifEdit)?_c(VCard,[(!_vm.roleBase.ifEdit)?_c(VCardTitle,{staticStyle:{"padding":"20px 20px 0px"}},[_vm._v(_vm._s(_vm.titleAdd))]):_vm._e(),(_vm.roleBase.ifEdit)?_c(VCardTitle,{staticStyle:{"padding":"20px 20px 0px"}},[_vm._v(_vm._s(_vm.titleEdit))]):_vm._e(),_c(VDivider),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"role-form"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('text-input',{attrs:{"text":_vm.roleModel.role_name,"label":"Role Name"},on:{"update:text":function($event){return _vm.$set(_vm.roleModel, "role_name", $event)}}})],1)],1),_c(VRow,{staticClass:"permission-cards"},_vm._l((_vm.myPermissions),function(permission,index){return _c(VCol,{key:index,attrs:{"cols":"12","sm":"4"}},[_c(VCard,[_c(VCardTitle,{staticClass:"permission-title"},[_vm._v(_vm._s(permission.title))]),_c(VCardText,{staticStyle:{"padding-top":"10px !important"}},[_c(VRow,_vm._l((permission.options),function(option,i){return _c(VCol,{key:i,attrs:{"cols":"12","sm":"6"}},[_c(VCheckbox,{attrs:{"label":option.label,"value":option.value},model:{value:(_vm.roleModel.permissions),callback:function ($$v) {_vm.$set(_vm.roleModel, "permissions", $$v)},expression:"roleModel.permissions"}})],1)}),1)],1)],1)],1)}),1)],1)],1),_c(VDivider),_c(VCardActions,{staticStyle:{"padding":"0px 20px 20px"}},[_c('submit-btn',{attrs:{"loading":_vm.roleBase.loading},on:{"click":_vm.submit}})],1)],1):_c('loader')
}
var staticRenderFns = []

export { render, staticRenderFns }