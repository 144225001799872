import { defineStore } from "pinia";

export const useLangStore = defineStore("langStore", {
    state: () => ({
        lang: localStorage.getItem("lang") == null ? "en" : localStorage.getItem("lang"),
        direction: false,
        htmlElement: document.documentElement
    }),
    getters: {
        getLang() {
            return this.lang
        },
        getDirection() {
            return (this.lang == 'ar') 
        },
        isAr(){
            if(this.lang == 'ar'){
                this.htmlElement.setAttribute('lang', "ar")
                return true
            }
            else{
                this.htmlElement.setAttribute('lang', "en")
                return false
            }
        }
    },
    actions: {
        changeLang(new_lang) {
            localStorage.setItem("lang", new_lang)
            this.htmlElement.setAttribute('lang', new_lang)
            this.lang = new_lang
            this.$i18n.locale = new_lang
            window.location.reload()
        }
    },
});
