import Task from "@/modules/translation/task/pages/TaskListView.vue"
import TaskEpisode from "@/modules/translation/task/pages/TaskEpisodeListView.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"
import DetailsView from "@/modules/translation/task/pages/DetailsView.vue"
export default {
    path: "/translation",
    component: BaseFormPageWrapper,
    children: [
        {
            path: '/translation/tasks',
            name: "tasks",
            component: Task,
            meta: { title: "Task" }
        },
        {
            path: '/translation/projects/:project_id/episode/:episode_id/tasks',
            name: "tasks-episode",
            component: TaskEpisode,
            meta: { title: "Task Episode" }
        },
        {
            path: '/translation/tasks/:id',
            name: "task-details",
            component: DetailsView,
            meta: { title: "Task Details", permission: "task_details" }
        },
    ]
    
};
