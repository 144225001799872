import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import DesignationService from "../services/DesignationService";

export const useDesignationStore = defineStore("designationStore", {
    state: () => ({
        designationBase: new BaseState(),
        designationModel: {
            id: '',
            name: '',
            department_id: '',
        },
        designationList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        clearDesignationModel() {
            this.designationModel.id = ''
            this.designationModel.name = ''
            this.designationModel.department_id = ''
        },
        setDesignationModel(item) {
            this.designationModel.id = item.id
            this.designationModel.name = item.designation
            this.designationModel.department_id = item.company_id
        },
        addDesignation(formData) {
            this.designationBase.loading = true;
            DesignationService.add(formData).then((res) => {
                this.designationBase.loading = false
                this.show = false
                this.fetchAllDesignation()
                this.clearDesignationModel()
            })
                .catch((err) => {
                    this.designationBase.loading = false
                    this.designationBase.error = true
                });
        },
        fetchAllDesignation() {
            this.designationBase.loadingFetch = true
            DesignationService.getAll().then((res) => {
                this.designationList = res.data.list.reverse()
                this.designationBase.loadingFetch = false
                this.designationBase.errorFetch = false
            })
                .catch((err) => {
                    this.designationBase.loadingFetch = false
                    this.designationBase.errorFetch = true
                });
        },
        deleteDesignation(formData) {
            DesignationService.delete(formData).then((res) => {
                this.designationBase.loading = false
                this.designationList.splice(this.designationBase.editedIndex, 1)
                this.designationBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.designationBase.loading = false
                    this.designationBase.error = true
                })
        },
        getDesignationById(id) {
            this.designationBase.loading = true
            DesignationService.getById(id).then((res) => {
                this.designationBase.loading = false
                this.designationModel.id = res.data.list.id
                this.designationModel.name = res.data.list.name
            })
                .catch((err) => {
                    this.designationBase.loading = false
                    this.designationBase.error = true
                })
        },
        editDesignation(id, formData) {
            formData.append('id', id)
            this.designationBase.loading = true
            DesignationService.edit(formData).then((res) => {
                this.designationBase.loading = false
                this.designationBase.ifEdit = false
                this.show = false
                this.fetchAllDesignation()
                this.clearDesignationModel()
            })
                .catch((err) => {
                    this.designationBase.loading = false
                    this.designationBase.error = true
                });
        },
    },
});
