import Vue from 'vue'
import App from './App.vue'
import router from './router'


import { PiniaVuePlugin } from 'pinia'
import pinia from './store'
Vue.use(PiniaVuePlugin)


// import vuetify
import vuetify from './plugins/vuetify'

import 'material-design-icons-iconfont/dist/material-design-icons.css'

import './assets/css/style.css'
// import './assets/css/material.css'
// import './assets/css/line-awesome.min.css'
// import './assets/css/font-awesome.min.css'
// import './assets/js/bootstrap.bundle.min.js'
// import './assets/css/bootstrap.min.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


import 'bootstrap/dist/css/bootstrap.min.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import jquery from 'jquery'; 
window.$ = jquery

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)


//creating event bus
export const EventBus = new Vue();

import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);


import GlobalDirectives from "@/directives";
Vue.use(GlobalDirectives);



Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  pinia,
  render: function (h) { return h(App) }
}).$mount('#app')
