import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import SettingService from "../services/SettingService";

export const useSettingStore = defineStore("settingStore", {
    state: () => ({
        settingBase: new BaseState(),
        settingModel: {
            email: '',
            phone: '',
            address: ''
        },
        settingObject: {},
        show: false,
    }),
    getters: {
    },
    actions: {
        setSettingModel(item) {
            this.settingModel.email = item.email
            this.settingModel.phone = item.CompanyPhone
            this.settingModel.address = item.CompanyAdress
        },
        fetchSetting() {
            this.settingBase.loadingFetch = true
            SettingService.get().then((res) => {
                this.setSettingModel(res.data.list)
                this.settingBase.loadingFetch = false
                this.settingBase.errorFetch = false
            })
                .catch((err) => {
                    this.settingBase.loadingFetch = false
                    this.settingBase.errorFetch = true
                });
        },
        editSetting(formData) {
            this.settingBase.loading = true
            SettingService.edit(formData).then((res) => {
                this.settingBase.loading = false
                this.settingBase.ifEdit = false
                this.show = false
                this.fetchSetting()
            })
                .catch((err) => {
                    this.settingBase.loading = false
                    this.settingBase.error = true
                });
        },
    },
});
