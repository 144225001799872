import EmployeeTask from "@/modules/employee_dashboard/task/pages/EmployeeTaskView.vue"
import EmployeeTaskDetailsView from "@/modules/employee_dashboard/task/pages/EmployeeTaskDetailsView"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/employee-task",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "/employee-task",
            name: "employee-task",
            component: EmployeeTask,
            meta: { title: "Employee Task", permission: "employee_dashboard" }
        },
        {
            path: '/employee-task/task/:id',
            name: "employee-task-details",
            component: EmployeeTaskDetailsView,
            meta: { title: "Employee Task", permission: "employee_dashboard" }
        },
    ]
};
