<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="walletBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <month-input type="date" :date.sync="date" label="Date"  @ok="filterByDate" />
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="walletList"
            :search="walletBase.search"
            class="my-table"
            :loading="walletBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <wallet-details />
            </template>
            <!-- <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="displayItem(item)" text icon color="indigo">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </div>
            </template> -->
        </v-data-table>
    </div>
</template>
<script>
import { useWalletStore } from "../store/WalletStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import WalletDetails from "./WalletDetails.vue"
import MonthInput from "@/components/inputs/MonthInput.vue"

export default {
    components: { WalletDetails, MonthInput },
    data() {
        return {
            headers: [
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Salary",
                    value: "salary",
                    align: "center"
                },
                {
                    text: "Overprice",
                    value: "overPrice",
                    align: "center"
                },
                {
                    text: "Extra Hour",
                    value: "extraHour",
                    align: "center"
                },
                {
                    text: "Total Salary",
                    value: "total",
                    align: "center"
                },
                {
                    text: "Paychecks",
                    value: "paychecks",
                    align: "center"
                },
                {
                    text: "Approved Salary",
                    value: "approved_salary",
                    align: "center"
                },
                {
                    text: "Remaining",
                    value: "remaining",
                    align: "center"
                },
                // {
                //     text: "Action",
                //     value: "mangement",
                //     align: "center",
                //     sortable: false
                // }
            ],
            date: ''
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useWalletStore, ['walletBase', 'walletList' ]),
        ...mapWritableState(useWalletStore, ['show', 'walletModel'])
    },

    methods: {
        ...mapActions(useWalletStore, ['fetchWallet']),
        // displayItem(item) {
        //     this.walletModel = [item]
        //     this.show = true
        // },
        filterByDate(){
            // this.walletModel = null
            const parts = this.date.split("-")
            this.date = `${parts[2]}-${parts[1]}-${parts[0]}`
            this.fetchWallet(this.date)
        }
    },
    mounted() {
        this.fetchWallet()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>