import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"timeDialog",attrs:{"return-value":_vm.newTime,"persistent":"","width":"290px","color":"primary"},on:{"update:returnValue":function($event){_vm.newTime=$event},"update:return-value":function($event){_vm.newTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"outlined":"","reverse":_vm.getDirection,"label":_vm.label,"readonly":"","append-icon":"mdi-clock-time-four-outline","rules":[_vm.requiredRule]},model:{value:(_vm.newTime),callback:function ($$v) {_vm.newTime=$$v},expression:"newTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeModal),callback:function ($$v) {_vm.timeModal=$$v},expression:"timeModal"}},[_c(VTimePicker,{attrs:{"readonly":_vm.readonly,"format":_vm.format,"scrollable":"","allowed-times":_vm.allowedTimes,"full-width":""},model:{value:(_vm.newTime),callback:function ($$v) {_vm.newTime=$$v},expression:"newTime"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeModal = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.timeBtn()}}},[_vm._v("OK")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }