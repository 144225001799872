<template>
    <form-dialog
        :title="!projectBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="850px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="project-form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="projectModel.title" label="Title" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="projectModel.client_id"
                            :items="clientList"
                            label="Client Name"
                            item_text="shortcut"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="projectModel.channel" label="Channel" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <date-input
                            :date.sync="projectModel.end_date"
                            label="Finish Date"
                            :allowedDates="allowedDatesEnd"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <time-input :time.sync="projectModel.time" label="Time" format="ampm" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="projectModel.nummber_of_episodes" label="Episodes Number" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <select-input
                            :model.sync="projectModel.assigned_to"
                            :items="managerList"
                            label="Assigned Managers"
                            item_text="username"
                            item_value="id"
                        />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <textarea-input :text.sync="projectModel.description" label="Description" :ifReq="false" />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="projectBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useProjectStore } from "../store/ProjectStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from "@/components/inputs/SelectInput.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import { useClientStore } from "@/modules/hr/client_/store/ClientStore"
import TextareaInput from '@/components/inputs/TextareaInput.vue'
import NumberInput from "@/components/inputs/NumberInput.vue"
import { useEmployeeStore } from "@/modules/hr/employee/store/EmployeeStore"
import TimeInput from "@/components/inputs/TimeInput.vue"

export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        SelectInput,
        DateInput,
        TextareaInput,
        NumberInput,
        TimeInput
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Project",
            titleEdit: "Edit Project",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useProjectStore, ["projectBase", "projectModel"]),
        ...mapWritableState(useProjectStore, ["show"]),
        ...mapState(useClientStore, ['clientList']),
        ...mapState(useEmployeeStore, ['managerList']),
    },
    methods: {
        ...mapActions(useProjectStore, ['addProject', 'editProject', 'clearProjectModel']),
        ...mapActions(useClientStore, ['fetchAllClient']),
        ...mapActions(useEmployeeStore, ['fetchAllManager']),
        allowedDatesEnd: val => val > new Date().toJSON().slice(0, 10),
        submit() {
            if (this.$refs.form.validate()) {
                this.projectBase.ifEdit
                    ? this.editProject(this.projectModel.id, this.createFormData())
                    : this.addProject(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('client_id', this.projectModel.client_id)
            formData.append('title', this.projectModel.title)
            formData.append('channel', this.projectModel.channel)
            if (this.projectBase.ifEdit)
                formData.append('start_date', this.projectModel.start_date)
            else
                formData.append('start_date', new Date().toJSON().slice(0, 10))
            formData.append('end_date', this.projectModel.end_date)
            formData.append('description', this.projectModel.description)
            formData.append('nummber_of_episodes', this.projectModel.nummber_of_episodes)
            formData.append('time', this.projectModel.time)
            formData.append('assigned_to', this.projectModel.assigned_to)
            return formData
        },
        onCancel() {
            this.show = false
            this.projectBase.ifEdit = false
            this.clearProjectModel()
            this.$refs.form.resetValidation()
        }
    },
    mounted() {
        this.fetchAllClient()
        this.fetchAllManager(1)
    }
};
</script>

<style>
.project-form .col-12 {
    padding: 0px 15px !important;
}

</style>