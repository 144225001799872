import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import WalletService from "../services/WalletService";

export const useWalletStore = defineStore("walletStore", {
    state: () => ({
        walletBase: new BaseState(),
        walletModel: null,
        walletList: [],
        show: false,
    }),
    actions: {
        fetchWallet(date) {
            // this.walletModel = {}
            this.walletBase.loadingFetch = true
            WalletService.get(date).then((res) => {
                this.walletList = res.data.list
                this.walletBase.loadingFetch = false
                this.walletBase.errorFetch = false
            })
                .catch((err) => {
                    this.walletBase.loadingFetch = false
                    this.walletBase.errorFetch = true
                });
        },
    },
});
