<template>
    <form-dialog
        :title="!clientBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
        @cancel="onCancel"
        width="750px"
    >
        <template v-slot:inputs>
            <v-form ref="form" class="client-form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="clientModel.firstname" label="First Name"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="clientModel.lastname" label="Last Name"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="clientModel.shortcut" label="Shortcut"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <text-input :text.sync="clientModel.address" label="Address"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="clientModel.phone" label="Phone"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <number-input :number.sync="clientModel.price_minute" label="Minute Price"/>
                    </v-col>
                </v-row>

            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="submit" :loading="clientBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useClientStore } from "../store/ClientStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'
import NumberInput from '@/components/inputs/NumberInput.vue'

export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
        NumberInput,
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Client",
            titleEdit: "Edit Client",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useClientStore, ["clientBase", "clientModel"]),
        ...mapWritableState(useClientStore, ["show"]),
    },
    methods: {
        ...mapActions(useClientStore, ['addClient', 'editClient', 'clearClientModel']),
        submit() {
            if (this.$refs.form.validate()) {
                this.clientBase.ifEdit
                    ? this.editClient(this.clientModel.id, this.createFormData())
                    : this.addClient(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('firstname', this.clientModel.firstname)
            formData.append('lastname', this.clientModel.lastname)
            formData.append('phone', this.clientModel.phone)
            formData.append('address', this.clientModel.address)
            formData.append('country', this.clientModel.shortcut)
            formData.append('price_minute', this.clientModel.price_minute)
            formData.append('role_users_id', this.clientModel.role_users_id)
            return formData
        },
        onCancel() {
            this.show = false
            this.clientBase.ifEdit = false
            this.clearClientModel()
            this.$refs.form.resetValidation()
        }
    }
};
</script>
<style>
.client-form .col-12 {
    padding: 0px 15px !important;
}
</style>
