import { useAuthStore } from "@/modules/auth/store/AuthStore";
/**
 * this directive checks if the user has at least one of a given permission list
 * if the user has no permission of the given permission list it hides the component/tag
 * directive arg : array of string permissions
 */
const PermissionCanAnyDirective = {
  bind: function (el, binding, vnode) {
    var authStore = useAuthStore();
    var permissions = authStore.permissions;
    var intersectedPermissions=binding.arg.filter(permission => permissions?.includes(permission.replace(/'/g, "").replace(/"/g, "")));
    if (
      !permissions ||
      (binding.arg &&
        intersectedPermissions.length==0)
    ) {
      // vnode.elm.style.display = "none";

      // replace HTMLElement with comment node
      const comment = document.createComment(" ");
      Object.defineProperty(comment, "setAttribute", {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = " ";
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;

      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }
  },
};
export default PermissionCanAnyDirective;
