<template>
    <v-menu
        ref="dateDialog"
        v-model="dateModal"
        :close-on-content-click="false"
        :return-value.sync="newDate"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                :reverse="getDirection"
                v-model="newDate"
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
                append-icon="mdi-calendar"
                :disabled="disabled"
                hide-details
            ></v-text-field>
        </template>
        <v-date-picker v-model="newDate" :type="type" :allowed-dates="allowedDates" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateModal = false">Close</v-btn>
            <v-btn text color="primary" @click="dateBtn()">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "MonthInput",
    mixins: [FormValidationRulesMixin],
    props: {
        date: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        allowedDates: {},
        disabled: {
            default: false,
            type: Boolean
        },
        type: {
            default: "month"
        }
    },
    data() {
        return {
            dateModal: false,
        }
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newDate: {
            get() {
                return this.date
            },
            set(value) {
                this.$emit('update:date', value)
            }
        },
    },
    methods: {
        dateBtn() {
            this.$refs.dateDialog.save(this.date)
            this.$emit('ok')
        },
    }

}
</script>

<style>
</style>