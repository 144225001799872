<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="designationBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="designationList"
            :search="designationBase.search"
            class="my-table"
            :loading="designationBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="designationBase.dialogDeleteModel"
                    :loading="designationBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useDesignationStore } from "../store/DesignationStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Designation Name",
                    value: "designation",
                    align: "center"
                },
                {
                    text: "Department",
                    value: "company_id",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ]
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useDesignationStore, ['designationBase', 'designationList']),
        ...mapWritableState(useDesignationStore, ['show'])
    },

    methods: {
        ...mapActions(useDesignationStore, ['fetchAllDesignation', 'deleteDesignation', 'setDesignationModel']),

        deleteItem(item) {
            this.designationBase.editedIndex = this.designationList.indexOf(item)
            this.designationBase.delete = item
            this.designationBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('designation_id', this.designationBase.delete.id)
            this.designationBase.loading = true
            this.deleteDesignation(formData)
        },

        editItem(item) {
            this.designationBase.ifEdit = true
            this.setDesignationModel(item)
            this.show = true
        },
    },
    mounted() {
        this.fetchAllDesignation()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>