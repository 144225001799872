import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_employee", formData);
    },

    getAll() {
        return Client.get("/employees");
    },

    getById(id) {
        return Client.get(`/employee?employee_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_employee`, formData);
    },

    delete(formData) {
        return Client.post(`/delete_employee`, formData);
    },

    getManager(company_id) {
        return Client.get(`/employees?company_id=${company_id}&manager`);
    },

    getTranslation() {
        return Client.get(`/employees?designation_id=4`);
    },

    getSync() {
        return Client.get(`/employees?designation_id=2`);
    },

    getAudit() {
        return Client.get(`/employees?designation_id=1`);
    },

    getPoli() {
        return Client.get(`/employees?designation_id=9`);
    }
};