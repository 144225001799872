<template>
    <v-text-field
        outlined
        :reverse="getDirection"
        v-model="newText"
        :rules="[ifNotReq ? noRule : requiredRule]"
        :label="label"
        :readonly="readonly"
        validate-on-blur
    ></v-text-field>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "TextInput",
    props:{
        text: {
            required: true
        },
        label: {
            type: String, 
            required: true
        },
        ifNotReq: {
            type: Boolean
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    mixins: [FormValidationRulesMixin],
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newText: {
            get() {
                return this.text
            },
            set(value) {
                this.$emit('update:text', value)
            }
        },
    },
}
</script>

<style>
</style>