<template>
    <div>
        <hr-layout title="Monthly Attendance" :hideAdd="true">
            <template v-slot:dataTable>
                <div v-if="!attendanceBase.errorFetch">
                    <monthly-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="attendanceBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
    </div>
</template>

<script>
import { useAttendanceStore } from "../store/AttendanceStore"
import { mapActions, mapState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import MonthlyTable from "../components/MonthlyTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"

export default {
    components: {
        MonthlyTable,
        NoInternet,
        HrLayout,
    },
    data: () => ({

    }),
    computed: {
        ...mapState(useAttendanceStore, ['attendanceBase']),
    },
    methods: {
        ...mapActions(useAttendanceStore, ['fetchMonthlyAttendance']),
        onRetry() {
            const date = `${new Date().getMonth() + 1}-${new Date().getFullYear()}`
            this.fetchMonthlyAttendance(date)
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
