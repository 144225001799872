import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_designation", formData);
    },

    getAll() {
        return Client.get("/designations");
    },

    getById(id) {
        return Client.get(`/designation?designation_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_designation`, formData);
    },

    delete(formData) {
        return Client.post(`/delete_designation`, formData);
    },
};