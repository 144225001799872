<template>
    <v-dialog
        ref="timeDialog"
        v-model="timeModal"
        :return-value.sync="newTime"
        persistent
        width="290px"
        color="primary"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                :reverse="getDirection"
                v-model="newTime"
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
                append-icon="mdi-clock-time-four-outline"
                :rules="[requiredRule]"
            ></v-text-field>
        </template>
        <v-time-picker :readonly="readonly" :format="format" v-model="newTime" scrollable :allowed-times="allowedTimes"  full-width>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="timeModal = false">Close</v-btn>
            <v-btn text color="primary" @click="timeBtn()">OK</v-btn>
        </v-time-picker>
    </v-dialog>

</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "TimeInput",
    mixins: [FormValidationRulesMixin],
    props: {
        time:{
            required: true
        },
        label: {
            type: String, 
            required: true
        },
        format: {
            required: true,
        },
        allowedTimes: {
            
        },
        readonly:{
            type: Boolean
        }
    },
    data(){
        return {
            timeModal: false,
        }
    },
    computed:{
        ...mapState(useLangStore, ["getDirection"]),
        newTime: {
            get() {
                return this.time
            },
            set(value) {
                this.$emit('update:time', value)
            }
        },
    },
    methods:{
        timeBtn() {
            this.$refs.timeDialog.save(this.time)
        },
    }

}
</script>

<style>
</style>