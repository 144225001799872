<template>
    <div>
        <v-row>
            <v-col cols="12" sm="4" style="padding: 0px 16px;">
                <v-text-field
                    v-model="episodeBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col>
                <a class="btn add-btn" @click="show = true" v-if="projectDetails.add_new_episode">
                    <i class="fa fa-plus"></i> Add Episode
                </a>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="projectDetails.episodes"
            :search="episodeBase.search"
            class="my-table"
            :loading="projectBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Description:</span>
                        {{ item.description }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Url Of Text:</span>
                        <a :href="item.text" target="_blank">
                            <b-icon icon="link45deg"></b-icon>
                        </a>
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Url Of Video:</span>
                        <a :href="item.url" target="_blank">
                            <b-icon icon="link45deg"></b-icon>
                        </a>
                    </div>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="episodeBase.dialogDeleteModel"
                    :loading="episodeBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <task-details-dialog />
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.syn`]="{ item }">
                <div v-if="item.syn == 0">
                    No
                </div>
                <div v-else>
                    Yes
                </div>
            </template>
            <template v-slot:[`item.script`]="{ item }">
                <div v-if="item.script == 0">
                    No
                </div>
                <div v-else>
                    Yes
                </div>
            </template>
            <template v-slot:[`item.title`]="{ item }">
                <a style="color: var(--v-primary-base)" @click="taskDetailsShow = true">
                    {{item.title}}
                </a>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useEpisodeStore } from "../store/EpisodeStore";
import { useTaskStore } from "@/modules/translation/task/store/TaskStore"
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import { useProjectStore } from "@/modules/translation/project/store/ProjectStore"
import TaskDetailsDialog from '@/modules/translation/task/components/TaskDetailsDialog.vue';

export default {
    components: { ConfirmDeleteDialog, TaskDetailsDialog },

    props:{
        projectDetails:{
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    text: "Episode",
                    value: "title",
                    align: "center"
                },
                {
                    text: "Duration",
                    value: "duration",
                    align: "center"
                },
                {
                    text: "Episode Sync",
                    value: "syn",
                    align: "center"
                },
                {
                    text: "Episode Script",
                    value: "script",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            expanded: [],
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useEpisodeStore, ['episodeBase', 'episodeList', 'episodeModel']),
        ...mapState(useProjectStore, ['projectBase']),
        ...mapWritableState(useEpisodeStore, ['show']),
        ...mapWritableState(useTaskStore, ['taskDetailsShow'])

    },

    methods: {
        ...mapActions(useEpisodeStore, [ 'deleteEpisode', 'setEpisodeModel']),

        deleteItem(item) {
            this.episodeBase.editedIndex = this.episodeList.indexOf(item)
            this.episodeBase.delete = item
            this.episodeBase.dialogDeleteModel = true
        },

        onConfirmed() {
            this.episodeModel.project_id = parseInt(this.$route.params.project_id)
            const formData = new FormData()
            formData.append('episode_id', this.episodeBase.delete.id)
            this.episodeBase.loading = true
            this.deleteEpisode(formData)
        },

        editItem(item) {
            this.episodeModel.project_id = parseInt(this.$route.params.project_id)
            this.episodeBase.ifEdit = true
            this.setEpisodeModel(item)
            this.show = true
        },
    },
    mounted() {
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.btn-status .v-btn{
    padding: 0px !important;
}
.v-data-table ul{
    list-style: disc !important;
}
</style>