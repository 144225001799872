<template>
    <div class="progress-container">
        <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </div>
</template>

<script>
export default {
    name: "Loader"

}
</script>

<style>
</style>