import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import HolidayService from "../services/HolidayService";

export const useHolidayStore = defineStore("holidayStore", {
    state: () => ({
        holidayBase: new BaseState(),
        holidayModel: {
            id: '',
            title: '',
            department_id: '',
            start_date: '',
            end_date: '',
            description: ''
        },
        holidayList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        clearHolidayModel() {
            this.holidayModel.id = ''
            this.holidayModel.title = ''
            this.holidayModel.department_id = ''
            this.holidayModel.start_date = ''
            this.holidayModel.end_date = ''
            this.holidayModel.description = ''
        },
        setHolidayModel(item) {
            this.holidayModel.id = item.id
            this.holidayModel.title = item.title
            this.holidayModel.department_id = item.company_id
            this.holidayModel.start_date = item.start_date.slice(0, 10)
            this.holidayModel.end_date = item.end_date.slice(0, 10)
            this.holidayModel.description = item.description
        },
        addHoliday(formData) {
            this.holidayBase.loading = true;
            HolidayService.add(formData).then((res) => {
                this.holidayBase.loading = false
                this.show = false
                this.fetchAllHoliday()
                this.clearHolidayModel()
            })
                .catch((err) => {
                    this.holidayBase.loading = false
                    this.holidayBase.error = true
                });
        },
        fetchAllHoliday() {
            this.holidayBase.loadingFetch = true
            HolidayService.getAll().then((res) => {
                this.holidayList = res.data.list.reverse()
                this.holidayBase.loadingFetch = false
                this.holidayBase.errorFetch = false
            })
                .catch((err) => {
                    this.holidayBase.loadingFetch = false
                    this.holidayBase.errorFetch = true
                });
        },
        deleteHoliday(formData) {
            HolidayService.delete(formData).then((res) => {
                this.holidayBase.loading = false
                this.holidayList.splice(this.holidayBase.editedIndex, 1)
                this.holidayBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.holidayBase.loading = false
                    this.holidayBase.error = true
                })
        },
        getHolidayById(id) {
            this.holidayBase.loading = true
            HolidayService.getById(id).then((res) => {
                this.holidayBase.loading = false
                this.holidayModel.id = res.data.list.id
                this.holidayModel.title = res.data.list.title
            })
                .catch((err) => {
                    this.holidayBase.loading = false
                    this.holidayBase.error = true
                })
        },
        editHoliday(id, formData) {
            formData.append('id', id)
            this.holidayBase.loading = true
            HolidayService.edit(formData).then((res) => {
                this.holidayBase.loading = false
                this.holidayBase.ifEdit = false
                this.show = false
                this.fetchAllHoliday()
                this.clearHolidayModel()
            })
                .catch((err) => {
                    this.holidayBase.loading = false
                    this.holidayBase.error = true
                });
        },
    },
});
