import Account from "@/modules/accounting/account/pages/AccountListView.vue"
import AccountDetails from "@/modules/accounting/account/pages/AccountDetails.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: "/accounting",
    component: BaseFormPageWrapper,
    children: [
        {
            path: "/accounting/account",
            name: "account",
            component: Account,
            meta: { title: "Account", permission: "account_view" }
        },
        {
            path: "/accounting/account/:id",
            name: "account-details",
            component: AccountDetails,
            meta: { title: "Account", permission: "account_view" }
        },
    ]
};
