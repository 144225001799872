 <template>
    <li class="nav-item dropdown has-arrow main-drop">
        <v-menu offset-y style="z-index: 99999;">
            <template v-slot:activator="{ on, attrs }">
                    <span class="user-img" v-bind="attrs" v-on="on">
                      <img src="@/assets/img/user-image.png" />
                      <span class="status online"></span>
                    </span>
            </template>
            <v-list>
                <!-- <router-link class="dropdown-item" to="/profile">My Profile</router-link> -->
                <div class="dropdown-item hover" @click="show = true" v-can="'settings'">Settings</div>
                <div class="dropdown-item hover" @click="showRole()" v-can="'group_permission'">Permissions</div>
                <div class="dropdown-item hover" @click="authStore.logout()">Logout</div>
            </v-list>
        </v-menu>
        <setting-form />
    </li>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store/AuthStore"
import { useSettingStore } from "@/modules/general/setting_/store/SettingStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import SettingForm from "@/modules/general/setting_/components/SettingForm.vue"
export default {
  components:{
    SettingForm
  },
  setup() {
        const authStore = useAuthStore()
        return { authStore }
    },
  computed:{
    ...mapWritableState(useSettingStore, ['show'])
  },
  methods:{
    showRole(){
      this.$router.push('/roles')
    }
  }
}
</script>

<style>

.user-img{
  margin-top: 15px;
  margin-right: 30px;
}
.adminclass{
  color: #fff;
  margin-left: 10px;
}
.header .user-img .status {
    height: 12px !important;
    width: 12px !important;
    bottom: -2px !important;
}
.dropdown-item.hover:hover{
  cursor: pointer;
}
</style>