<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="departmentBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="departmentList"
            :search="departmentBase.search"
            class="my-table"
            :loading="departmentBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="departmentBase.dialogDeleteModel"
                    :loading="departmentBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useDepartmentStore } from "../store/DepartmentStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Department Name",
                    value: "name",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ]
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useDepartmentStore, ['departmentBase', 'departmentList']),
        ...mapWritableState(useDepartmentStore, ['show'])
    },

    methods: {
        ...mapActions(useDepartmentStore, ['fetchAllDepartment', 'deleteDepartment', 'setDepartmentModel']),

        deleteItem(item) {
            this.departmentBase.editedIndex = this.departmentList.indexOf(item)
            this.departmentBase.delete = item
            this.departmentBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('company_id', this.departmentBase.delete.id)
            this.departmentBase.loading = true
            this.deleteDepartment(formData)
        },

        editItem(item) {
            this.departmentBase.ifEdit = true
            this.setDepartmentModel(item)
            this.show = true
        },
    },
    mounted() {
        this.fetchAllDepartment()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>