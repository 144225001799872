<template>
    <form-dialog title="Upload File" :persistent="true" :show.sync="show" @cancel="onCancel">
        <template v-slot:inputs>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12">
                        <v-file-input
                            outlined
                            chips
                            :reverse="getDirection"
                            v-model="fileModel.attachment"
                            label="Attachment"
                            append-icon="mdi-paperclip"
                            prepend-icon
                            show-size
                            :rules="[fileRule, requiredRule]"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12">
                        <textarea-input
                            :text.sync="fileModel.description"
                            label="Notes"
                            :ifReq="false"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <submit-btn @click="change" :loading="taskBase.loading" />
        </template>
    </form-dialog>
</template>


<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import SelectInput from '@/components/inputs/SelectInput.vue'
import { useTaskStore } from "../store/TaskStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import TextareaInput from '@/components/inputs/TextareaInput.vue'
import { useLangStore } from "@/store/LangStore"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "DetailsDialog",
    components: {
        SelectInput,
        SubmitBtn,
        TextareaInput,
        FormDialog
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {

        };
    },
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    computed: {
        ...mapState(useTaskStore, ["taskBase", 'fileModel']),
        ...mapState(useLangStore, ['getDirection']),
        ...mapWritableState(useTaskStore, ["show"]),
    },
    methods: {
        ...mapActions(useTaskStore, ['clearTaskFileModel', 'addFile']),
        change() {
            if (this.$refs.form.validate()) {
                const formData = new FormData()
                formData.append("id", this.item.emp_task_id)
                formData.append("attachment", this.fileModel.attachment)
                formData.append("description", this.fileModel.description)
                this.addFile(this.item.task_id, formData)
            }
        },
        onCancel() {
            this.show = false
            this.clearTaskFileModel()
            this.$refs.form.resetValidation()
        },
        validateFile(file) {
            if (file) {
                console.log(file)
                const allowedExtensions = [".srt"];
                const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
                console.log(allowedExtensions.includes(fileExtension))
                return allowedExtensions.includes(fileExtension);
            }
        },
    },
    mounted() {

    }
}
</script>

<style>
</style>