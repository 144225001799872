import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import ProjectService from "../services/ProjectService";
import router from "@/router"
export const useProjectStore = defineStore("projectStore", {
    state: () => ({
        projectBase: new BaseState(),
        projectModel: {
            id: '',
            client_id: '',
            title: '',
            start_date: '',
            end_date: '',
            description: '',
            channel: '',
            nummber_of_episodes: '',
            time: '',
            assigned_to: '',
        },
        projectList: [],
        show: false,
        projectDetails: null,
        completed: null,
        in_progress: null,
    }),
    getters: {
    },
    actions: {
        clearProjectModel() {
            this.projectModel.id = ''
            this.projectModel.client_id = ''
            this.projectModel.title = ''
            this.projectModel.start_date = ''
            this.projectModel.end_date = ''
            this.projectModel.description = ''
            this.projectModel.channel = ''
            this.projectModel.nummber_of_episodes = ''
            this.projectModel.time = ''
            this.projectModel.assigned_to = ''
        },
        setProjectModel(item) {
            this.projectModel.id = item.id
            this.projectModel.client_id = item.client_id
            this.projectModel.title = item.title
            this.projectModel.start_date = item.created_at.slice(0, 10)
            this.projectModel.end_date = item.end_date.slice(0, 10)
            this.projectModel.description = item.description
            this.projectModel.channel = item.channel
            this.projectModel.nummber_of_episodes = item.nummber_of_episodes
            this.projectModel.time = item.time
            this.projectModel.assigned_to = item.manager[0].id
        },
        addProject(formData) {
            this.projectBase.loading = true;
            ProjectService.add(formData).then((res) => {
                this.projectBase.loading = false
                this.show = false
                router.push(`/translation/project/${res.data.list.id}`)
                // this.fetchAllProject()
                this.clearProjectModel()
            })
                .catch((err) => {
                    this.projectBase.loading = false
                    this.projectBase.error = true
                });
        },
        fetchAllProject(date) {
            this.projectBase.loadingFetch = true
            ProjectService.getAll(date).then((res) => {
                this.projectList = res.data.list.projects
                this.completed = res.data.list.completed
                this.in_progress = res.data.list.in_progress
                this.projectBase.loadingFetch = false
                this.projectBase.errorFetch = false
            })
                .catch((err) => {
                    this.projectBase.loadingFetch = false
                    this.projectBase.errorFetch = true
                });
        },
        deleteProject(formData) {
            ProjectService.delete(formData).then((res) => {
                this.projectBase.loading = false
                this.projectList.splice(this.projectBase.editedIndex, 1)
                this.projectBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.projectBase.loading = false
                    this.projectBase.error = true
                })
        },
        getProjectById(id) {
            this.projectBase.loadingFetch = true
            ProjectService.getById(id).then((res) => {
                this.projectDetails = res.data.list
                this.projectBase.loadingFetch = false
                this.projectBase.errorFetch = false
            })
                .catch((err) => {
                    this.projectBase.loadingFetch = false
                    this.projectBase.errorFetch = true
                })
        },
        editProject(id, formData) {
            formData.append('project_id', id)
            this.projectBase.loading = true
            ProjectService.edit(formData).then((res) => {
                this.projectBase.loading = false
                this.projectBase.ifEdit = false
                this.show = false
                this.fetchAllProject()
                this.clearProjectModel()
            })
                .catch((err) => {
                    this.projectBase.loading = false
                    this.projectBase.error = true
                });
        },
    },
});
