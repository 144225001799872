/**
 * this file indexes all custom directives that will be registered globally in main.js
 */
import PermissionCanDirective from "./PermissionCanDirective";
import PermissionCanAnyDirective from "./PermissionCanAnyDirective";
import RoleDirective from "./RoleDirective";

export default {
    install (Vue) {
      Vue.directive('can', PermissionCanDirective);
      Vue.directive('canAny', PermissionCanAnyDirective);
      Vue.directive('role', RoleDirective);
    }
  }