import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import NotificationService from "../services/NotificationService";

export const useNotificationStore = defineStore("notificationStore", {
    state: () => ({
        notificationBase: new BaseState(),
        notificationList: [],
    }),
    getters: {
    },
    actions: {
        fetchNotification() {
            this.notificationList = []
            this.notificationBase.loadingFetch = true
            NotificationService.get().then((res) => {
                this.notificationList = res.data.list.data
                this.notificationList.forEach(item => {
                    const currentDate = new Date();
                    const diffInMilliseconds = currentDate - new Date(item.created_at);
                    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
                    const diffInMinutes = Math.floor(diffInSeconds / 60);
                    const diffInHours = Math.floor(diffInMinutes / 60);
                    const diffInDays = Math.floor(diffInHours / 24);
                    // const duration = `${diffInDays} days, ${diffInHours % 24} hours, ${diffInMinutes % 60} minutes`;
                    item.days = diffInDays;
                    item.hours = diffInHours % 24;
                    item.min = diffInMinutes % 60;
                })
                this.notificationBase.loadingFetch = false
                this.notificationBase.errorFetch = false
            })
                .catch((err) => {
                    this.notificationBase.loadingFetch = false
                    this.notificationBase.errorFetch = true
                });
        },
    },
});
