<template>
    <div class="departments">
        <div class="main-wrapper">
            <hr-sidebar v-role="'admin'"/>
            <all-sidebar v-role="'manager'"/>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Roles</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/roles">Roles</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                                <a class="btn add-btn" @click="addRole()">
                                    <i class="fa fa-plus"></i> Add Role
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="!roleBase.errorFetch">
                            <role-table />
                        </div>
                        <div v-else>
                            <no-internet @retry="onRetry" :loading="roleBase.loadingFetch"></no-internet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoleStore } from "../store/RoleStore"
import { mapActions, mapState } from "pinia"
import RoleTable from "../components/RoleTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
import AllSidebar from '@/components/main/AllSidebar.vue'
export default {
    components: {
        RoleTable,
        NoInternet,
        HrSidebar,
        AllSidebar
    },
    data: () => ({
        
    }),
    computed: {
        ...mapState(useRoleStore, ['roleBase']),
    },
    methods: {
        ...mapActions(useRoleStore, ['fetchAllRole']),
        addRole(){
            this.$router.push('/roles/add')
        },
        onRetry() {
            this.fetchAllRole()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
