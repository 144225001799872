<template>
    <v-dialog
        ref="dateDialog"
        v-model="dateModal"
        :return-value.sync="newDate"
        persistent
        width="290px"
        color="primary"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                :reverse="getDirection"
                v-model="newDate"
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
                append-icon="mdi-calendar"
                :rules="[requiredRule]"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker v-model="newDate" scrollable :allowed-dates="allowedDates">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateModal = false">Close</v-btn>
            <v-btn text color="primary" @click="dateBtn()">OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "DateInput",
    mixins: [FormValidationRulesMixin],
    props: {
        date:{
            type: String,
            required: true
        },
        label: {
            type: String, 
            required: true
        },
        allowedDates:{},
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data(){
        return {
            dateModal: false,
        }
    },
    computed:{
        ...mapState(useLangStore, ["getDirection"]),
        newDate: {
            get() {
                return this.date
            },
            set(value) {
                this.$emit('update:date', value)
            }
        },
    },
    methods:{
        dateBtn() {
            this.$refs.dateDialog.save(this.date)
        },
    }

}
</script>

<style>
</style>