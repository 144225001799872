<template>
    <div>
        <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-can="'project_details'">Episodes</v-tab>
            <v-tab v-can="'task_view'">Tasks</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-can="'project_details'">
                <episode-table :projectDetails="projectDetails" />
                <episode-form />
            </v-tab-item>
            <v-tab-item v-can="'task_view'">
                <task-table :projectDetails="projectDetails" />
                <task-form :projectDetails="projectDetails" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import EpisodeTable from '@/modules/translation/episode/components/EpisodeTable.vue'
import EpisodeForm from "@/modules/translation/episode/components/EpisodeForm.vue"
import TaskForm from "@/modules/translation/task/components/TaskForm.vue"
import TaskTable from '../../task/components/TaskTable.vue'

export default {
    components: {
        EpisodeTable,
        EpisodeForm,
        TaskForm,
        TaskTable
    },
    props: {
        projectDetails: {
            required: true,
        }
    },
    data() {
        return {
            tab: 0,
            permission: [],
        };
    },
    computed: {
    },
    mounted() {
        this.permission = JSON.parse(localStorage.getItem('permissions'))
        if (this.permission.includes('project_details'))
            this.tab = 0
        else if (this.permission.includes('task_view'))
            this.tab = 1
    }

}
</script>

<style>
.v-tabs-items {
    padding: 25px 50px;
}
</style>