<template>
    <v-btn color="grey" outlined @click="$emit('click')" rounded large>Cancel</v-btn>
</template>

<script>
export default {
    name: "CancelBtn",

}
</script>

<style>

</style>