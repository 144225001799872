import Client from "@/clients/AxiosClient";

export default {
    addExpense(formData) {
        return Client.post("/add_expense", formData);
    },
    deleteExpense(formData) {
        return Client.post(`/delete_expense`, formData);
    },
    filterExpense(start_date, end_date) {
        if (start_date && end_date)
            return Client.get(`/expenses_filter?start_date=${start_date}&end_date=${end_date}`);
        else
            return Client.get(`/expenses_filter`);
    },

    addIncome(formData) {
        return Client.post("/add_income", formData);
    },
    deleteIncome(formData) {
        return Client.post(`/delete_income`, formData);
    },
    filterIncome(start_date, end_date) {
        if (start_date && end_date)
            return Client.get(`/incomes_filter?start_date=${start_date}&end_date=${end_date}`);
        else
            return Client.get(`/incomes_filter`);
    },

    getFund(start_date, end_date) {
        if (start_date && end_date)
            return Client.get(`/fund?start_date=${start_date}&end_date=${end_date}`);
        else
            return Client.get("/fund");
    },
};