<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="leaveBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="leaveList"
            :search="leaveBase.search"
            class="my-table"
            :loading="leaveBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Reason:</span>
                        {{ item.reason }}
                    </div>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="leaveBase.dialogDeleteModel"
                    :loading="leaveBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <change-status-dialog :change-status.sync="changeStatusModal" :item="changeStatusItem"/>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="btn-status" v-if="role != 2">
                    <v-btn rounded color="warning" outlined v-if="item.status == 'pending'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        Pending
                    </v-btn>
                    <v-btn rounded color="success" outlined v-if="item.status == 'approved'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        Approved
                    </v-btn>
                    <v-btn rounded color="error" outlined v-if="item.status == 'rejected'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        Rejected
                    </v-btn>
                </div>
                <div class="btn-status" v-else>
                    <v-btn rounded color="warning" outlined v-if="item.status == 'pending'">
                        Pending
                    </v-btn>
                    <v-btn rounded color="success" outlined v-if="item.status == 'approved'">
                        Approved
                    </v-btn>
                    <v-btn rounded color="error" outlined v-if="item.status == 'rejected'">
                        Rejected
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.mangement`]="{ item }" v-if="role != 2">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useLeaveStore } from "../store/LeaveStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import ChangeStatusDialog from "./ChangeStatusDialog.vue"

export default {
    components: { ConfirmDeleteDialog, ChangeStatusDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Department",
                    value: "company_id",
                    align: "center"
                },
                {
                    text: "Start Date",
                    value: "start_date",
                    align: "center"
                },
                {
                    text: "End Date",
                    value: "end_date",
                    align: "center"
                },
                {
                    text: "Days",
                    value: "days",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            expanded: [],
            changeStatusModal: false,
            changeStatusItem: {},
            role: ''
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useLeaveStore, ['leaveBase', 'leaveList']),
        ...mapWritableState(useLeaveStore, ['show'])
    },

    methods: {
        ...mapActions(useLeaveStore, ['fetchAllLeave', 'deleteLeave', 'setLeaveModel']),

        deleteItem(item) {
            this.leaveBase.editedIndex = this.leaveList.indexOf(item)
            this.leaveBase.delete = item
            this.leaveBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('id', this.leaveBase.delete.id)
            this.leaveBase.loading = true
            this.deleteLeave(formData)
        },

        editItem(item) {
            this.leaveBase.ifEdit = true
            this.setLeaveModel(item)
            this.show = true
        },

        changeStatus(item) {
            this.changeStatusModal = true
            this.changeStatusItem = item
        },

    },
    mounted() {
        this.fetchAllLeave()
        this.role = JSON.parse(localStorage.getItem('user')).role
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
.btn-status .v-btn{
    padding: 0px !important;
}
</style>