import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import EpisodeService from "../services/EpisodeService";
// import { useStore } from 'pinia';
import { useProjectStore } from "../../project/store/ProjectStore";

export const useEpisodeStore = defineStore("episodeStore", {
    state: () => ({
        episodeBase: new BaseState(),
        episodeModel: {
            id: '',
            project_id: '',
            title: '',
            episode_file: null,
            description: '',
            text: '',
            duration: '',
            syn: null,
            script: null,
            url: '',
            video: null,
        },
        episodeList: [],
        episodeProjectList: [],
        show: false,
        selectList: [
            {
                text: "Yes",
                value: 1
            },
            {
                text: "No",
                value: 0
            },
        ],
        addNewEpisode: '',
        // projectStore: useStore(useProjectStore)
    }),
    getters: {
    },
    actions: {
        clearEpisodeModel() {
            this.episodeModel.id = ''
            this.episodeModel.project_id = ''
            this.episodeModel.title = ''
            this.episodeModel.episode_file = null
            this.episodeModel.script = null
            this.episodeModel.description = ''
            this.episodeModel.text = ''
            this.episodeModel.duration = ''
            this.episodeModel.syn = null
            this.episodeModel.url = ''
            this.episodeModel.video = null
        },
        setEpisodeModel(item) {
            console.log("___________________________________________")
            console.log(item)
            this.episodeModel.id = item.id
            this.episodeModel.project_id = item.project_id
            this.episodeModel.title = item.title
            this.episodeModel.episode_file = item.episode_file
            this.episodeModel.script = item.script
            this.episodeModel.description = item.description
            this.episodeModel.text = item.text
            this.episodeModel.duration = item.duration
            this.episodeModel.syn = item.syn
            this.episodeModel.url = item.url
            this.episodeModel.video = item.video
            console.log("___________________________________________")
        },
        addEpisode(formData) {
            this.episodeBase.loading = true;
            EpisodeService.add(formData).then((res) => {
                this.episodeBase.loading = false
                this.show = false
                const projectStore = useProjectStore();
                projectStore.getProjectById(this.episodeModel.project_id);
                this.clearEpisodeModel()
            })
                .catch((err) => {
                    this.episodeBase.loading = false
                    this.episodeBase.error = true
                });
        },
        fetchAllEpisode() {
            this.episodeBase.loadingFetch = true
            EpisodeService.getAll().then((res) => {
                this.episodeList = res.data.list
                this.episodeBase.loadingFetch = false
                this.episodeBase.errorFetch = false
            })
                .catch((err) => {
                    this.episodeBase.loadingFetch = false
                    this.episodeBase.errorFetch = true
                });
        },
        fetchEpisodeProject(project_id) {
            this.episodeBase.loadingFetch = true
            EpisodeService.getEpisodeProject(project_id).then((res) => {
                this.episodeProjectList = res.data.list.episodes
                this.addNewEpisode = res.data.list.add_new_episode
                this.episodeBase.loadingFetch = false
                this.episodeBase.errorFetch = false
            })
                .catch((err) => {
                    this.episodeBase.loadingFetch = false
                    this.episodeBase.errorFetch = true
                });
        },
        deleteEpisode(formData) {
            EpisodeService.delete(formData).then((res) => {
                this.episodeBase.loading = false
                this.episodeProjectList.splice(this.episodeBase.editedIndex, 1)
                this.episodeBase.dialogDeleteModel = false
                const projectStore = useProjectStore();
                projectStore.getProjectById(this.episodeModel.project_id);
            })
                .catch((err) => {
                    this.episodeBase.loading = false
                    this.episodeBase.error = true
                })
        },
        getEpisodeById(id) {
            this.episodeBase.loading = true
            EpisodeService.getById(id).then((res) => {
                this.episodeBase.loading = false
                this.episodeModel.id = res.data.list.id
                this.episodeModel.project_id = res.data.list.project_id
            })
                .catch((err) => {
                    this.episodeBase.loading = false
                    this.episodeBase.error = true
                })
        },
        editEpisode(id, formData) {
            formData.append('episode_id', id)
            this.episodeBase.loading = true
            EpisodeService.edit(formData).then((res) => {
                this.episodeBase.loading = false
                this.episodeBase.ifEdit = false
                this.show = false
                const projectStore = useProjectStore();
                projectStore.getProjectById(this.episodeModel.project_id);
            })
                .catch((err) => {
                    this.episodeBase.loading = false
                    this.episodeBase.error = true
                });
        },
    },
});
