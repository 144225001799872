<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="holidayBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="holidayList"
            :search="holidayBase.search"
            class="my-table"
            :loading="holidayBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Description:</span>
                        {{ item.description }}
                    </div>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="holidayBase.dialogDeleteModel"
                    :loading="holidayBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template
                v-slot:[`item.start_date`]="{ item }"
            >{{item.start_date.slice(0, 10)}}</template>
            <template v-slot:[`item.end_date`]="{ item }">{{item.end_date.slice(0, 10)}}</template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useHolidayStore } from "../store/HolidayStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Title",
                    value: "title",
                    align: "center"
                },
                {
                    text: "Department",
                    value: "company_id",
                    align: "center"
                },
                {
                    text: "Start Date",
                    value: "start_date",
                    align: "center"
                },
                {
                    text: "End Date",
                    value: "end_date",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            expanded: []
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useHolidayStore, ['holidayBase', 'holidayList']),
        ...mapWritableState(useHolidayStore, ['show'])
    },

    methods: {
        ...mapActions(useHolidayStore, ['fetchAllHoliday', 'deleteHoliday', 'setHolidayModel']),

        deleteItem(item) {
            this.holidayBase.editedIndex = this.holidayList.indexOf(item)
            this.holidayBase.delete = item
            this.holidayBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('id', this.holidayBase.delete.id)
            this.holidayBase.loading = true
            this.deleteHoliday(formData)
        },

        editItem(item) {
            this.holidayBase.ifEdit = true
            this.setHolidayModel(item)
            this.show = true
        },
    },
    mounted() {
        this.fetchAllHoliday()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>