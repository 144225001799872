<template>
    <!-- Notifications -->
    <b-nav-item-dropdown class="notification">
        <template #button-content>
            <v-badge
                v-if="count != 0"
                :content="count"
                style="top: -22px;right: -27px;"
                overlap
                color="blue"
            ></v-badge>
            <i class="far fa-bell" style="font-size: 25px; margin-top: 18px;"></i>
            <!-- <b-icon icon="bell" @click="see()"></b-icon> -->
        </template>
        <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <!-- <a href="javascript:void(0)" class="clear-noti">Clear All</a> -->
        </div>
        <div class="noti-content">
            <ul class="notification-list" v-if="!notificationBase.loadingFetch && notificationList.length != 0">
                <li
                    class="notification-message"
                    v-for="(item, index) in notificationList.slice(0, 3)"
                    :key="index"
                >
                    <div class="media d-flex">
                        <div class="media-body flex-grow-1">
                            <p class="noti-title">{{item.title}}</p>
                            <p class="text-muted">{{item.message}}</p>
                            <p class="noti-time">
                                <span v-if="item.days != 0">{{item.days}} days ago</span>
                                <span v-else-if="item.days == 0 && item.hours != 0">{{item.hours}} hours ago</span>
                                <span v-else-if="item.days == 0 && item.hours == 0 && item.min != 0">{{item.min}} minutes ago</span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
            <loader v-if="notificationBase.loadingFetch" />
        </div>
        <div class="topnav-dropdown-footer">
            <router-link to="/notifications">View all Notifications</router-link>
        </div>
    </b-nav-item-dropdown>
</template>

<script>
import { useNotificationStore } from "@/modules/general/notification/store/NotificationStore"
import { mapState, mapActions } from "pinia"
import Loader from "@/components/main/Loader.vue"
export default {
    name: 'Navbar',
    components:{Loader},
    data: () => ({
    }),
    computed: {
        ...mapState(useNotificationStore, ['notificationBase', 'notificationList']),
        count(){
            // console.log(this.notificationList.length)
            return this.notificationList.length
        }
    },
    methods: {
        ...mapActions(useNotificationStore, ['fetchNotification'])
    },
    mounted() {
        this.fetchNotification()
        
    }
};
</script>

<style lang="scss">
.notification-message {
    margin: 5px;
    font-size: small;
}
.notification-list {
    padding-left: 0px !important;
}
.notification-list li {
    border-bottom: 1px solid #f5f5f5;
    padding: 10px;
}
.notification-message p {
    margin: 0px !important;
}
.noti-time {
    font-size: 12px;
    color: #a8a8a8;
}
.bi-exclamation-circle {
    color: var(--v-secondary-base);
    font-size: 45px;
    margin-top: 20px;
}
.notification .dropdown-item {
    background-color: #eee;
    padding: 10px 25px;
}

.notification .dropdown-menu {
    width: 330px !important;
    padding-left: 0px !important;
    // height: 336px;
    overflow-y: auto;
}
.navbar .dropdown-menu {
    text-align: justify;
}
.navbar li {
    display: unset;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
    background-color: var(--v-primary-base);
}

.navbar {
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: 0 0 10px #e4e4e4;
    padding: 15px 40px !important;
}

.navbar .bi-person-fill {
    font-size: 25px !important;
}

.navbar .bi-bell-fill {
    font-size: 25px !important;
}

.navbar .nav-link {
    padding: 6px 8px !important;
}

.navbar .bi-globe {
    font-size: 25px !important;
    // padding-top: 1px;
}

.navbar-nav .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
}

.navbar .nav-item {
    margin: 0px 10px;
}

html:lang(ar) .navbar-nav .nav-link {
    direction: rtl;
}

.navbar .dropdown-menu {
    padding: 10px 0px;
}

.navbar .dropdown-toggle::after {
    display: none !important;
}

.navbar .nav-link {
    background-color: #e9e9e9;
    border-radius: 20px;
}
</style>