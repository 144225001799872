<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="salariesBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
                <month-input :date.sync="date" label="Filter" />
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="salariesList"
            :search="salariesBase.search"
            class="my-table"
            :loading="salariesBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:[`item.paid`]="{ item }">
                <v-switch
                    v-model="item.paid"
                    inset
                    :label="item.paid == 1 ? 'Paid' : 'Unpaid'"
                    color="success"
                    @change="confirmChangeStatus(item, $event)"
                    :disabled="Boolean(item.paid)"
                ></v-switch>
            </template>
            <template v-slot:[`item.employee.salary`]="{ item }">
                <div v-if="item.employee">
                    <div v-if="item.employee.salary == 'minutes'">
                        Minutes
                    </div>
                    <div v-else>
                        Monthly
                    </div>
                </div>
            </template>
            <template v-slot:[`item.selected_paychecks_amount`]="{ item }">
                <v-text-field v-if="item.total_paychecks_amount != 0 && item.selected_paychecks_amount == 0 && item.paid == 0"
                    :reverse="getDirection"
                    v-model="selected_paychecks_amount[item.employee_id]"
                    :rules="[numberRule, minThanRule(item.total_paychecks_amount)]"
                    label="Amount"
                    @blur="showDialog(item)"
                    @keyup.enter="showDialog(item)"
                ></v-text-field>
                <div v-else>
                    {{ item.selected_paychecks_amount }}
                </div>
            </template>
        </v-data-table>
        <v-dialog v-model="show" max-width="500px">
            <v-card>
                <v-card-title style=" justify-content: center;margin-bottom: 0px">
                    <span class="text-h5 color-primary">Pay Salary</span>
                    <v-btn class="btn-close" @click="cancel">
                        <v-icon style="font-size: 18px;">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <h5 style="padding: 20px;text-align: center;">Are you sure you want to paid this salary?</h5>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-content-center" style="padding-bottom: 20px;">
                    <v-btn
                        @click="pay"
                        color="primary"
                        large
                        light
                        :loading="salariesBase.loading"
                        rounded
                    >OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showPaycheckConfirm" max-width="500px">
            <v-card>
                <v-card-title style=" justify-content: center;margin-bottom: 0px">
                    <span class="text-h5 color-primary">Paychecks</span>
                    <v-btn class="btn-close" @click="cancelPaycheck">
                        <v-icon style="font-size: 18px;">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <h5 style="padding: 20px;text-align: center;">Are you sure you want to paid this paycheck from your paycheks?</h5>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-content-center" style="padding-bottom: 20px;">
                    <v-btn
                        @click="paycheck"
                        color="primary"
                        large
                        light
                        :loading="salariesBase.loading"
                        rounded
                    >OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { useSalariesStore } from "../store/SalariesStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import MonthInput from '@/components/inputs/MonthInput.vue'
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
export default {
    components: { MonthInput },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            payItem: null,
            paycheckItem: null,
            headers: [
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center",
                    width: "150"
                },
                {
                    text: "Type",
                    value: "employee.salary",
                    align: "center",
                    width: "120"
                },
                {
                    text: "Salary",
                    value: "salary",
                    align: "center",
                    width: "120"
                },
                {
                    text: "Over Price",
                    value: "employee.over_price",
                    align: "center",
                    width: "120"
                },
                {
                    text: "Extra Hour",
                    value: "extraHour",
                    align: "center",
                    width: "130"
                },
                {
                    text: "Paychecks",
                    value: "total_paychecks_amount",
                    align: "center",
                    width: "130"
                },
                {
                    text: "Total With Paychecks",
                    value: "total_salary",
                    align: "center",
                    width: "130"
                },
                {
                    text: "Total Without Paychecks",
                    value: "total_salary_without_paychecks",
                    align: "center",
                    width: "130"
                },
                {
                    text: "Selected Paycheck To Paid",
                    value: "selected_paychecks_amount",
                    align: "center",
                    width: "160"
                },
                {
                    text: "Final Salary",
                    value: "final_salary",
                    align: "center",
                    width: "150"
                },
                {
                    text: "Status",
                    value: "paid",
                    align: "center",
                    sortable: false
                }
            ],
            selected_paychecks_amount: [],
            date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useSalariesStore, ['salariesBase', 'salariesList']),
        ...mapWritableState(useSalariesStore, ['show', 'showPaycheckConfirm']),
    },

    methods: {
        ...mapActions(useSalariesStore, ['fetchAllSalaries', 'paySalaries', 'paycheckAmount']),
        pay() {
            const formData = new FormData()
            formData.append("employee_id", this.payItem.employee_id)
            const parts = this.date.split("-");
            formData.append("date", `${parts[1]}-${parts[0]}`)
            this.paySalaries(formData)
        },
        confirmChangeStatus(item) {
            item.paid = false
            this.show = true
            this.payItem = item
        },
        cancel() {
            this.payItem.paid = 0
            this.show = false
        },
        paycheck(){
            const formData = new FormData()
            formData.append('employee_id', this.paycheckItem.employee_id)
            formData.append('amount', this.selected_paychecks_amount[this.paycheckItem.employee_id])
            this.paycheckAmount(formData)
        },
        showDialog(item){
            this.showPaycheckConfirm = true
            this.paycheckItem = item
        },
        cancelPaycheck(){
            this.showPaycheckConfirm = false
        }

    },
    mounted() {
        this.fetchAllSalaries()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>