<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="employeeBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="employeeList"
            :search="employeeBase.search"
            class="my-table"
            :loading="employeeBase.loadingFetch"
            loading-text="Loading... Please wait"
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Email:</span>
                        {{ item.email }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Gender:</span>
                        {{ item.gender }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Birth Date:</span>
                        {{ item.birth_date }}
                    </div>
                    <div>
                        <span style="color: var(--v-primary-base); font-weight: bold;">Joining Date:</span>
                        {{ item.joining_date }}
                    </div>
                </td>
            </template>
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="employeeBase.dialogDeleteModel"
                    :loading="employeeBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="displayItem(item)" text icon color="indigo" v-can="'employee_details'">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" text icon color="error" v-can="'employee_delete'">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green" v-can="'employee_edit'">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { useEmployeeStore } from "../store/EmployeeStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"

export default {
    components: { ConfirmDeleteDialog },

    data() {
        return {
            expanded: [],
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Employee Name",
                    value: "username",
                    align: "center"
                },
                {
                    text: "Phone",
                    value: "phone",
                    align: "center"
                },
                {
                    text: "Salary Type",
                    value: "salary",
                    align: "center"
                },
                {
                    text: "Department",
                    value: "company.name",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ]
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(useEmployeeStore, ['employeeBase', 'employeeList']),
        ...mapWritableState(useEmployeeStore, ['show'])
    },

    methods: {
        ...mapActions(useEmployeeStore, ['fetchAllEmployee', 'deleteEmployee', 'setEmployeeModel']),
        deleteItem(item) {
            this.employeeBase.editedIndex = this.employeeList.indexOf(item)
            this.employeeBase.delete = item
            this.employeeBase.dialogDeleteModel = true
        },
        onConfirmed() {
            const formData = new FormData()
            formData.append('employee_id', this.employeeBase.delete.id)
            this.employeeBase.loading = true
            this.deleteEmployee(formData)
        },
        editItem(item) {
            console.log(item)
            this.employeeBase.ifEdit = true
            this.setEmployeeModel(item)
            this.show = true
        },
        displayItem(item) {
            this.$router.push(`/hr/employees/${item.id}`)
        }
    },
    mounted() {
        this.fetchAllEmployee()
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>