import Client from "@/clients/AxiosClient";

export default {
    getAll() {
        return Client.get("/accounts");
    },
    getById(id, start_date, end_date) {
        if (start_date && end_date)
            return Client.get(`/account?id=${id}&start_date=${start_date}&end_date=${end_date}`);
        else
            return Client.get(`/account?id=${id}`);
    },
    addExpense(formData) {
        return Client.post('create_expense', formData);
    },
    deleteExpense(formData) {
        return Client.post(`/delete_expense`, formData);
    }
};