<template>
    <div>
        <v-row>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="paycheckBase.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    :reverse="getDirection"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="paycheckList"
            :search="paycheckBase.search"
            class="my-table"
            :loading="paycheckBase.loadingFetch"
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <confirm-delete-dialog
                    :dialog-delete.sync="paycheckBase.dialogDeleteModel"
                    :loading="paycheckBase.loading"
                    @confirmed="onConfirmed"
                ></confirm-delete-dialog>
                <change-status-dialog :change-status.sync="changeStatusModal" :item="changeStatusItem"/>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="btn-status" v-if="permissions.includes('paycheck_stauts')">
                    <v-btn rounded color="warning" outlined v-if="item.status == 'pending'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        Pending
                    </v-btn>
                    <v-btn rounded color="success" outlined v-if="item.status == 'approved'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        Approved
                    </v-btn>
                    <v-btn rounded color="error" outlined v-if="item.status == 'rejected'" @click="changeStatus(item)">
                        <v-icon style="margin: 0 5px">
                            mdi-pencil
                        </v-icon>
                        Rejected
                    </v-btn>
                </div>
                <div class="btn-status" v-else>
                    <v-btn rounded color="warning" outlined v-if="item.status == 'pending'">
                        Pending
                    </v-btn>
                    <v-btn rounded color="success" outlined v-if="item.status == 'approved'">
                        Approved
                    </v-btn>
                    <v-btn rounded color="error" outlined v-if="item.status == 'rejected'">
                        Rejected
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.mangement`]="{ item }">
                <div class="row justify-content-center" style="column-gap: 10px">
                    <v-btn @click="deleteItem(item)" text icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="editItem(item)" text icon color="green" v-if="role != 2">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/dialogs/ConfirmDeleteDialog.vue';
import { usePaycheckStore } from "../store/PaycheckStore";
import { mapState, mapActions, mapWritableState } from "pinia";
import { useLangStore } from "@/store/LangStore"
import ChangeStatusDialog from "./ChangeStatusDialog.vue"

export default {
    components: { ConfirmDeleteDialog, ChangeStatusDialog },

    data() {
        return {
            headers: [
                {
                    text: "Id",
                    value: "id",
                    align: "center"
                },
                {
                    text: "Employee",
                    value: "employee.username",
                    align: "center"
                },
                {
                    text: "Department",
                    value: "company.name",
                    align: "center"
                },
                {
                    text: "Amount",
                    value: "amount",
                    align: "center"
                },
                {
                    text: "Date",
                    value: "date",
                    align: "center"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center"
                },
                {
                    text: "Action",
                    value: "mangement",
                    align: "center",
                    sortable: false
                }
            ],
            changeStatusModal: false,
            changeStatusItem: {},
            permissions: [],
            role: ''
        };
    },

    computed: {
        ...mapState(useLangStore, ['getDirection']),
        ...mapState(usePaycheckStore, ['paycheckBase', 'paycheckList']),
        ...mapWritableState(usePaycheckStore, ['show'])
    },

    methods: {
        ...mapActions(usePaycheckStore, ['fetchAllPaycheck', 'deletePaycheck', 'setPaycheckModel']),

        deleteItem(item) {
            this.paycheckBase.editedIndex = this.paycheckList.indexOf(item)
            this.paycheckBase.delete = item
            this.paycheckBase.dialogDeleteModel = true
        },

        onConfirmed() {
            const formData = new FormData()
            formData.append('id', this.paycheckBase.delete.id)
            this.paycheckBase.loading = true
            this.deletePaycheck(formData)
        },

        editItem(item) {
            this.paycheckBase.ifEdit = true
            this.setPaycheckModel(item)
            this.show = true
        },

        changeStatus(item) {
            this.changeStatusModal = true
            this.changeStatusItem = item
        },

    },
    mounted() {
        this.fetchAllPaycheck()
        this.permissions = JSON.parse(localStorage.getItem("permissions"))
        this.role = JSON.parse(localStorage.getItem('user')).role
    }
};
</script>
<style>
@import url(@/assets/css/main.css);
</style>