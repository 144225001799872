import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_paycheck", formData);
    },

    getAll() {
        return Client.get("/paychecks");
    },

    getById(id) {
        return Client.get(`/paycheck?id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_paycheck`, formData);
    },

    delete(formData) {
        return Client.post(`/delete_paycheck`, formData);
    },

    changeStatus(formData) {
        return Client.post(`/change_status`, formData);
    }
};