<template>
    <div>
        <hr-layout :show.sync="show" title="Holiday">
            <template v-slot:dataTable>
                <div v-if="!holidayBase.errorFetch">
                    <holiday-table />
                </div>
                <div v-else>
                    <no-internet @retry="onRetry" :loading="holidayBase.loadingFetch"></no-internet>
                </div>
            </template>
        </hr-layout>
        <holiday-form />
    </div>
</template>

<script>
import { useHolidayStore } from "../store/HolidayStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import HrLayout from "@/modules/hr/core/layouts/HrLayout.vue"
import HolidayTable from "../components/HolidayTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import HolidayForm from "../components/HolidayForm.vue"

export default {
    components: {
        HolidayTable,
        NoInternet,
        HolidayForm,
        HrLayout,
    },
    data: () => ({
        cardTitle: "Holiday List",
        btnTitle: "Add Holiday",
    }),
    computed: {
        ...mapState(useHolidayStore, ['holidayBase']),
        ...mapWritableState(useHolidayStore, ['show'])
    },
    methods: {
        ...mapActions(useHolidayStore, ['fetchAllHoliday']),
        onRetry() {
            this.fetchAllHoliday()
        }
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);
</style>
