import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import ClientService from "../services/ClientService";

export const useClientStore = defineStore("clientStore", {
    state: () => ({
        clientBase: new BaseState(),
        clientModel: {
            id: '',
            firstname: '',
            lastname: '',
            shortcut: '',
            address: '',
            phone: '',
            price_minute: 0,
            role_users_id: 3
        },
        clientDetailsModel: null,
        clientList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        clearClientModel() {
            this.clientModel.id = ''
            this.clientModel.firstname = ''
            this.clientModel.lastname = ''
            this.clientModel.shortcut = ''
            this.clientModel.address = ''
            this.clientModel.phone = ''
            this.clientModel.price_minute = 0
        },
        setClientModel(item) {
            this.clientModel.id = item.id
            this.clientModel.firstname = item.firstname
            this.clientModel.lastname = item.lastname
            this.clientModel.shortcut = item.shortcut
            this.clientModel.address = item.address
            this.clientModel.phone = item.phone
            this.clientModel.price_minute = item['price for minute']
        },
        addClient(formData) {
            this.clientBase.loading = true;
            ClientService.add(formData).then((res) => {
                this.clientBase.loading = false
                this.show = false
                this.fetchAllClient()
                this.clearClientModel()
            })
                .catch((err) => {
                    this.clientBase.loading = false
                    this.clientBase.error = true
                });
        },
        fetchAllClient() {
            this.clientBase.loadingFetch = true
            ClientService.getAll().then((res) => {
                this.clientList = res.data.list.reverse()
                this.clientBase.loadingFetch = false
                this.clientBase.errorFetch = false
            })
                .catch((err) => {
                    this.clientBase.loadingFetch = false
                    this.clientBase.errorFetch = true
                });
        },
        deleteClient(formData) {
            ClientService.delete(formData).then((res) => {
                this.clientBase.loading = false
                this.clientList.splice(this.clientBase.editedIndex, 1)
                this.clientBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.clientBase.loading = false
                    this.clientBase.error = true
                })
        },
        getClientById(id) {
            this.clientBase.loading = true
            ClientService.getById(id).then((res) => {
                this.clientBase.loading = false
            })
                .catch((err) => {
                    this.clientBase.loading = false
                    this.clientBase.error = true
                })
        },
        editClient(id, formData) {
            formData.append('id', id)
            this.clientBase.loading = true
            ClientService.edit(formData).then((res) => {
                this.clientBase.loading = false
                this.clientBase.ifEdit = false
                this.show = false
                this.fetchAllClient()
                this.clearClientModel()
            })
                .catch((err) => {
                    this.clientBase.loading = false
                    this.clientBase.error = true
                });
        },
        fetchClientDetails(id, date){
            this.clientBase.loadingFetch = true
            ClientService.clientDetails(id, date).then((res) => {
                this.clientBase.loadingFetch = false
                this.clientDetailsModel = res.data.list
                // console.log(this.clientDetailsModel)
            })
                .catch((err) => {
                    this.clientBase.loadingFetch = false
                    this.clientBase.errorFetch = true
                })
        }
    },
});
