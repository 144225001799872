import Attendance from "@/modules/hr/attendance/pages/AttendanceListView.vue"
import MonthlyAttendance from "@/modules/hr/attendance/pages/MonthlyAttendance.vue"
import BaseFormPageWrapper from "@/wrappers/BaseFormPageWrapper.vue"

export default {
    path: '/hr',
    component: BaseFormPageWrapper,
    children:[
        {
            path: "/hr/attendance",
            name: "attendance",
            component: Attendance,
            meta: { title: "Attendance", permission: "attendance_view" }
        },
        {
            path: "/hr/monthly-attendance",
            name: "monthly-attendance",
            component: MonthlyAttendance,
            meta: { title: "Monthly Attendance" }
        }
    ]
    
};
