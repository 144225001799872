import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","reverse":_vm.getDirection,"outlined":"","hide-details":""},model:{value:(_vm.overtimeBase.search),callback:function ($$v) {_vm.$set(_vm.overtimeBase, "search", $$v)},expression:"overtimeBase.search"}})],1)],1),_c(VDataTable,{staticClass:"my-table",attrs:{"headers":_vm.headers,"items":_vm.overtimeList,"search":_vm.overtimeBase.search,"loading":_vm.overtimeBase.loadingFetch,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:`item.mangement`,fn:function({ item }){return [_c('div',{staticClass:"row justify-content-center",staticStyle:{"column-gap":"10px"}},[_c(VBtn,{attrs:{"text":"","icon":"","color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }