<template>
    <div class="main-wrapper">
        <all-sidebar v-role="'employee'" />
        <div class="page-wrapper">
            <div class="content container-fluid">
                <div class="page-header">
                    <div class="row align-items-center " style="justify-content: space-between;">
                        <div class="col-lg-3">
                            <h3 class="page-title">Dashboard</h3>
                        </div>
                        <div class="col-lg-3" style=" text-align: end;">
                            <v-btn color="primary" :loading="employeeDashboardBase.loading" @click="submitCheckOut">
                                <v-icon style="margin-left: 10px">mdi-door</v-icon>
                                Check Out
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon">
                                        <i class="far fa-money-bill-alt"></i>
                                    </span>
                                    <div class="dash-widget-info" style="justify-content: center">
                                        <h4>{{total}}</h4>
                                        <h6 class="client-card-title">Price</h6>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <div class="card dash-widget">
                                <div class="card-body">
                                    <span class="dash-widget-icon">
                                        <i class="far fa-clock"></i>
                                    </span>
                                    <div class="dash-widget-info" style="justify-content: center">
                                        <h4>{{ clock_out }}</h4>
                                        <h6 class="client-card-title">Clock</h6>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <div v-if="!employeeDashboardBase.errorFetch">
                        <employeeDashboard-table />
                    </div>
                    <div v-else>
                        <no-internet @retry="onRetry" :loading="employeeDashboardBase.loadingFetch"></no-internet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useEmployeeDashboardStore } from "../store/EmployeeDashboardStore"
import { mapActions, mapState, mapWritableState } from "pinia"
import EmployeeDashboardTable from "../components/EmployeeDashboardTable.vue"
import NoInternet from "@/components/main/NoInternet.vue"
import AllSidebar from "@/components/main/AllSidebar.vue"
export default {
    components: {
        EmployeeDashboardTable,
        NoInternet,
        AllSidebar
    },
    data: () => ({
    }),
    computed: {
        ...mapState(useEmployeeDashboardStore, ['employeeDashboardBase', 'total', 'employee_id']),
        ...mapWritableState(useEmployeeDashboardStore, ['clock_out'])
    },
    methods: {
        ...mapActions(useEmployeeDashboardStore, ['fetchEmployeeDashboard', 'checkOut']),
        onRetry() {
            this.fetchEmployeeDashboard()
        },
        submitCheckOut() {
            const formData = new FormData()
            formData.append('employee_id', this.employee_id)
            formData.append('clock_out', this.clock_out)
            this.checkOut(formData)
        },
        updateTime() {
            const now = new Date();
            const hours = this.formatTimeUnit(now.getHours());
            const minutes = this.formatTimeUnit(now.getMinutes());
            const seconds = this.formatTimeUnit(now.getSeconds());
            this.clock_out = `${hours}:${minutes}:${seconds}`;
        },
        formatTimeUnit(unit) {
            return unit < 10 ? `0${unit}` : unit;
        }
    },
    mounted() {
        this.updateTime();
        setInterval(this.updateTime, 1000); // Update the time every second
    }

};
</script>

<style lang="scss">
@import url(@/assets/css/main.css);

</style>
