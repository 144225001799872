<template>
    <v-text-field
        :reverse="getDirection"
        type="email"
        append-icon="mdi-email"
        outlined
        v-model="newEmail"
        :rules="[requiredRule, emailRule]"
        :label="label"
        validate-on-blur
    ></v-text-field>
</template>

<script>
import { useLangStore } from "@/store/LangStore"
import { mapState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"

export default {
    name: "EmailInput",
    props: {
        email: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
    },
    mixins: [FormValidationRulesMixin],
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        newEmail: {
            get() {
                return this.email
            },
            set(value) {
                this.$emit('update:email', value)
            }
        },
    },
}
</script>

<style>
</style>